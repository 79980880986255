import { useRef } from "react";
import { ModalButton } from "../../chatButtons/modal/ModalButton";
import style from "./Container.module.css";

const Container = ({ children, toggleModal, metas, showButton }) => {
  const ref = useRef(null);

  return (
    <div className={style.Container}>
      <div
        ref={ref}
        className={style.child}
      >
        {children}
      </div>
      {metas?.length > 4 && showButton && (
        <div className={style.but}>
          <ModalButton toggleModal={toggleModal} />
        </div>
      )}
    </div>
  );
};

export default Container;
