import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  downloadRequest: "idle",
  downloadAllowanceViewer: false,
  downloadMultipleFileStatus: "idle",
  downloadingModels: [],
  assetLibraryDownloadRequest: "idle",
  assetLibraryDownloadingModel: null,
  assetLibraryDownloadingJob: null,
};

const downloadSlice = createSlice({
  name: "downloads",
  initialState,
  reducers: {
    setDownloadRequest: (state, action) => {
      state.downloadRequest = action.payload.status;
    },
    setDownloadAllowanceViewer: (state, action) => {
      state.downloadAllowanceViewer = action.payload.status;
    },
    addCurrentDownload: (state, action) => {
      state[action.payload.uid] = {
        ...action.payload.download,
      };
    },
    addNewDownload: (state, action) => {
      state[action.payload.uid] = {
        jobId: action.payload.uid,
        numberOfAllFiles: action.payload.numberOfFiles,
        numberOfDownloadedFiles: 0,
        downloadStatus: "downloading",
        jobType: action.payload.jobType,
      };
    },
    increaseDownloadedFileNumber: (state, action) => {
      if (state[action.payload.uid]) {
        state[action.payload.uid].numberOfDownloadedFiles += 1;

        if (
          state[action.payload.uid].numberOfDownloadedFiles ===
          state[action.payload.uid].numberOfAllFiles
        ) {
          state[action.payload.uid].downloadStatus = "complete";
        }
      }
    },
    setDownloadMultipleFileStatus: (state, action) => {
      state.downloadMultipleFileStatus = action.payload.state;
    },
    addDownloadingModels: (state, action) => {
      state.downloadingModels = {
        ...state.downloadingModels,
        [action.payload.state.result_id]: action.payload.state,
      };
    },
    setAssetLibraryDownloadingModel: (state, action) => {
      state.assetLibraryDownloadingModel = action.payload.state;
    },
    setAssetLibraryDownloadingJob: (state, action) => {
      state.assetLibraryDownloadingJob = action.payload.state;
    },
    setAssetLibraryDownloadingRequest: (state, action) => {
      state.assetLibraryDownloadRequest = action.payload.state;
    },
    clearDownloadingModels: (state, action) => {
      state.downloadingModels = {};
    },
    clearDownloads: (state, action) => {
      state = initialState;
    },
  },
});

export const {
  addNewDownload,
  increaseDownloadedFileNumber,
  clearDownloads,
  setDownloadRequest,
  setDownloadAllowanceViewer,
  addCurrentDownload,
  setDownloadMultipleFileStatus,
  addDownloadingModels,
  clearDownloadingModels,
  setAssetLibraryDownloadingModel,
  setAssetLibraryDownloadingRequest,
  setAssetLibraryDownloadingJob,
} = downloadSlice.actions;

export default downloadSlice.reducer;
