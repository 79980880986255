import React, { useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import styles from "./History.module.css";
import HistoryDownloaded from "./HistoryDownloaded";
import HistoryRequests from "./HistoryRequests";

function History() {
  const [downloadButton, setDownloadButton] = useState(false);
  const [generateButton, setGenerateButton] = useState(false);
  const onCLickDownload = () => {
    setGenerateButton(false);
    setDownloadButton(true);
  };
  const onCLickGenerate = () => {
    setGenerateButton(true);
    setDownloadButton(false);
  };
  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.buttonContainer}>
        <button
          className={`${styles.downloadButton} ${
            generateButton ? styles.downloadButtonActive : ""
          }`}
          onClick={onCLickGenerate}
        >
          {" "}
          Generated Requests{" "}
        </button>
        <button
          className={`${styles.downloadButton} ${
            downloadButton ? styles.downloadButtonActive : ""
          }`}
          onClick={onCLickDownload}
        >
          {" "}
          Downloaded Requests{" "}
        </button>
      </div>
      <div>
        {generateButton ? (
          <HistoryRequests />
        ) : downloadButton ? (
          <HistoryDownloaded />
        ) : null}
      </div>
    </div>
  );
}

export default History;
