import React, { useState } from "react";
import Container from "./container/ContainerMeasure";
import ContainerSave from "../save/ContainerSave";
import { useDispatch, useSelector } from "react-redux";
import { setPreferences } from "../../../store/features/userSlice";
import ShowAlert from "../../../alert/showAlert";

const Preferences = ({ modal }) => {
  const userPreferences = useSelector((state) => state.user.preferences);
  const [newPreferences, setNewPreferences] = useState({});
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const handleSave = async (e) => {
    e.preventDefault();
    // if (!user.name && !user.surname && !user.email) {
    //   console.log("name ve email bos birakilamaz");
    // } else {
    //   dispatch(edit(user));
    //   const response = await postEdit(user);
    //   console.log("RESPONSE : ", response);
    //   const result = await response.json();
    //   if (response.ok) {
    //     console.log(result);
    //     // window.location.reload();
    //   } else {
    //     console.error("fail");
    //   }
    // }

    dispatch(
      setPreferences({ preferences: { ...userPreferences, ...newPreferences } })
    );
    saveUserPreferences({ ...userPreferences, ...newPreferences });
  };

  const saveUserPreferences = async (userPreferences) => {
    let preferences = {
      preferred_length_units: userPreferences?.length,
      preferred_file_format: userPreferences?.fileExtension,
      preferred_texture_resolution: userPreferences?.textureResolution,
      preferred_mesh_type: userPreferences?.meshType,
      user_id: user?.id,
    };

    const url = `${process.env.REACT_APP_SERVER}/saveUserPreferences`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${user?.session}`,
      },
      body: JSON.stringify(preferences),
    });
    const result = await response.json();
    if (response.status === 200) {
      ShowAlert(1, "Saved successfully");
      // alert();
    } else {
      // alert("Failed to save");
      ShowAlert(3, "Failed to save");
    }
  };

  const handleCancel = (e) => {
    // setUser(userCredentials);
  };

  const changePreferences = (name, value) => {
    setNewPreferences((curr) => ({ ...curr, [name]: value }));
  };

  return (
    <div>
      <Container
        value={{
          measure: ["cm", "m", "dm"],
        }}
        text={"Set measuring units to"}
        name={"length"}
        changePreferences={changePreferences}
      />
      <Container
        value={{
          measure: ["fbx", "gltf", "usd"],
        }}
        text={"File format to"}
        name={"fileExtension"}
        changePreferences={changePreferences}
      />
      <Container
        value={{
          measure: ["1K", "2K", "4K"],
        }}
        text={"Texture resolution to"}
        name={"textureResolution"}
        changePreferences={changePreferences}
      />
      <Container
        value={{
          measure: ["Quad"],
        }}
        text={"Mesh type to"}
        name={"meshType"}
        changePreferences={changePreferences}
      />
      {modal ? (
        <div style={{ marginTop: "50px" }}>
          <ContainerSave
            onCancelClick={handleCancel}
            onSaveClick={handleSave}
            modal
          />
        </div>
      ) : (
        <div>
          <ContainerSave
            onCancelClick={handleCancel}
            onSaveClick={handleSave}
          />
        </div>
      )}
    </div>
  );
};

export default Preferences;
