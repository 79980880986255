import React, { useEffect } from "react";
import styles from "./BillingPage.module.css";
import PlanBox from "./PlanBox";
import Navbar from "../../components/navbar/Navbar";
import { checkJWT } from "../../utils/session/Session";
import { useNavigate } from "react-router";
import { logout } from "../../store/features/userSlice";
import { useDispatch } from "react-redux";

function BillingPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let isAuth = checkJWT();
    if (isAuth !== "valid") {
      dispatch(logout());
      navigate("/");
      localStorage.removeItem("sessionKey");
    }
    return () => {};
  }, []);

  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.textContainer}>
        <p className={styles.text}>BILLING & PLANS</p>
      </div>
      <div className={styles.planContainer}>
        <PlanBox />
        <PlanBox />
        <PlanBox />
        <PlanBox />
      </div>
    </div>
  );
}

export default BillingPage;
