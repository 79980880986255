import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isVisible: false,
  spinnerText: "",
  generateProgress: "",
};

const spinnerSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSpinnerVisibleState: (state, action) => {
      state.isVisible = action.payload.isVisible;
    },
    setSpinnerText: (state, action) => {
      state.spinnerText = action.payload.spinnerText;
    },
    setGenerateProgress: (state, action) => {
      state.generateProgress = action.payload.generateProgress;
    },
    clearSpinner: (state, action) => {
      state = initialState;
    },
  },
});

export const {
  setSpinnerVisibleState,
  setSpinnerText,
  clearSpinner,
  setGenerateProgress,
} = spinnerSlice.actions;

export default spinnerSlice.reducer;
