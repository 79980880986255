import style from "./picture.module.css";
import { useSelector } from "react-redux";
const UserImage = ({ onClick }) => {
  const userCredentials = useSelector((state) => state.user);
  return (
    <div
      onClick={onClick}
      className={
        userCredentials.image !== "" && userCredentials.image !== null
          ? style.navPic
          : style.navPicSec
      }
    >
      <img
        src={
          userCredentials.image !== "" && userCredentials.image !== null
            ? userCredentials.image
            : "https://img.icons8.com/?size=512&id=ckaioC1qqwCu&format=png"
        }
        id="profilePic"
        alt="Profile"
      />
    </div>
  );
};

export default UserImage;
