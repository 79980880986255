import { Canvas, useFrame, useLoader, useThree } from "@react-three/fiber";
import {
  Bloom,
  EffectComposer,
  N8AO,
  Vignette,
} from "@react-three/postprocessing";
import {
  Environment,
  OrbitControls,
  PerformanceMonitor,
  Stats,
} from "@react-three/drei";
import { Html } from "@react-three/drei";

import styles from "./Scene.module.css";
import { useEffect, useRef, useState } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";

import {
  removeFromCheckBoxedModelsVariation,
  selectModel,
  setCheckBoxedModel,
  setCheckBoxedModelsVariation,
} from "../../store/features/modelSlice";
import { useDispatch, useSelector } from "react-redux";

import { useControls } from "leva";
import { USDZLoader } from "three/examples/jsm/loaders/USDZLoader";
import { setImage } from "../../store/features/imagesSlice";
import {
  setIsCheckBoxesShown,
  setIsProceedButtonShown,
} from "../../store/features/buttonSlice";
import { VARIATION_GENERATION } from "../../utils/statusList/statusList";

const DisplayModes = {
  TEXTURED: "Textured",
  WIREFRAME: "Wireframe",
  UVMAP: "UV Map",
};

function GltfLoaderComp({
  model,
  gltfFile,
  getSizeOfModel,
  displayMode,
  changeIsModelReady,
}) {
  const { scene, gl, invalidate } = useThree();
  const [isSceneReady, setIsSceneReady] = useState(false);
  const [gltfModel, setGLTFModel] = useState(null);
  const [isModelReady, setIsModelReady] = useState(false);

  const colorMap = useLoader(THREE.TextureLoader, "UV.jpeg");
  colorMap.flipY = false;
  const [originalTextures, setOriginalTextures] = useState({});
  const manager = new THREE.LoadingManager();
  const loader = new GLTFLoader(manager);

  useEffect(() => {
    invalidate();
    console.log("Display mode : ", displayMode);
    return () => {};
  }, [displayMode]);

  useEffect(() => {
    console.log("LOADER : ", gltfFile, model);

    return () => {};
  }, [model, gltfFile]);

  useEffect(() => {
    // Initialize loading manager with URL callback.
    const objectURLs = [];
    // console.log("URL : ", model);
    // console.log("GLTF FILEEE : ", gltfFile);
    manager.setURLModifier((url) => {
      // console.log("URL1 : ", url);
      url = url.replace("./", "");
      url = url.replace("textures/", "");
      try {
        url = URL.createObjectURL(model[url]);
      } catch (e) {
        console.log("e");
      }
      objectURLs.push(url);
      return url;
    });

    manager.onLoad = () => {
      console.log("PROGRESS MANAGER : COMPLETE");
      changeIsModelReady(true);
    };

    loader.load(
      gltfFile,
      (gltf) => {
        scene.add(gltf.scene);
        console.log("GLTF SCENE : ", gltf.scene);
        setGLTFModel(gltf.scene);
        gltf.scene.traverse(function (child) {
          if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;
            // child.geometry.computeVertexNormals(); // FIX
          }
        });
        objectURLs.forEach((url) => URL.revokeObjectURL(url));
      },
      (xhr) => {
        // console.log("PROGRESS : ", (xhr.loaded / xhr.total) * 100, " % loaded");
      }
    );
  }, [model]);

  useEffect(() => {
    if (gltfModel != null) {
      let valueOfSize;
      const box = new THREE.Box3().setFromObject(gltfModel);
      valueOfSize = box.getSize(new THREE.Vector3());
      getSizeOfModel(valueOfSize);
    }

    if (scene != null && !isSceneReady) {
      setIsSceneReady(true);
    }

    return () => {};
  }, [gltfModel]);

  //For shadows every mesh's shadow properties must be true
  useEffect(
    () =>
      scene
        ? scene.traverse(function (node) {
            if (node.isMesh) {
              console.log("CAST SHADOW : ");
              node.castShadow = true;
              node.receiveShadow = true;
            }

            if (node.geometry) {
            }
          })
        : null,
    [scene]
  );

  useEffect(() => {
    scene.traverse((node) => {
      if (node.isMesh) {
        const material = node.material;
        if (material.map) {
          // console.log("ORIGINAL MATERIAL : ", material.map);
          setOriginalTextures((prevState) => ({
            ...prevState,
            [node.uuid]: material.map,
          }));
        }
      }
    });
  }, [gltfModel]);

  useEffect(() => {
    scene.traverse((node) => {
      if (node.isMesh) {
        if (displayMode === DisplayModes.UVMAP) {
          node.material.map = colorMap;
          colorMap.wrapS = colorMap.wrapT = THREE.RepeatWrapping;
          colorMap.anisotropy = 16;
          colorMap.colorSpace = THREE.SRGBColorSpace;
        } else {
          const originalTexture = originalTextures[node.uuid];
          if (originalTexture) {
            node.material.map = originalTexture;
          }
        }

        node.material.wireframe = displayMode === DisplayModes.WIREFRAME;
        node.material.wireframeLinecap = "square";
        node.material.wireframeLinejoin = "miter";
      }
    });
  }, [displayMode, colorMap, originalTextures, scene]);

  scene.traverse((node) => {
    if (node.isMesh) {
      node.castShadow = true;
      node.receiveShadow = true;
      // node.material.shading = THREE.SmoothShading;
    }
  });

  return isSceneReady && Object.keys(model) > 0 ? (
    <primitive scale={[20, 20, 20]} object={scene} />
  ) : null;
}

function USDZLoaderComp({
  model,
  usdzFile,
  getSizeOfModel,
  displayMode,
  changeIsModelReady,
}) {
  const { scene, gl } = useThree();
  const [isSceneReady, setIsSceneReady] = useState(false);
  const [gltfModel, setGLTFModel] = useState(null);

  const colorMap = useLoader(THREE.TextureLoader, "UV.jpeg");
  colorMap.flipY = false;
  const [originalTextures, setOriginalTextures] = useState({});

  useEffect(() => {
    console.log("Display mode : ", displayMode);
    return () => {};
  }, [displayMode]);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("LOADER : ", usdzFile, model);

    return () => {};
  }, [model, usdzFile]);

  useEffect(() => {
    const manager = new THREE.LoadingManager();

    // Initialize loading manager with URL callback.
    const objectURLs = [];
    // console.log("URL : ", model);
    // console.log("GLTF FILEEE : ", usdzFile);
    manager.setURLModifier((url) => {
      // console.log("URL1 : ", url);
      url = url.replace("./", "");
      url = url.replace("textures/", "");
      try {
        url = URL.createObjectURL(model[url]);
      } catch (e) {
        console.log("e");
      }
      objectURLs.push(url);
      return url;
    });

    manager.onLoad = () => {
      console.log("PROGRESS MANAGER : COMPLETE");
      changeIsModelReady(true);
    };

    const loader = new USDZLoader(manager);
    loader.load(
      usdzFile,
      (usdz) => {
        scene.add(usdz);
        console.log("USDZ SCENE : ", usdz);
        setGLTFModel(usdz);
        usdz.traverse(function (child) {
          if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;
            // child.geometry.computeVertexNormals(); // FIX
          }
        });
        objectURLs.forEach((url) => URL.revokeObjectURL(url));
      },
      (xhr) => {
        // console.log("PROGRESS : ", (xhr.loaded / xhr.total) * 100, " % loaded");
      }
    );
  }, [model]);

  useEffect(() => {
    if (gltfModel != null) {
      let valueOfSize;
      const box = new THREE.Box3().setFromObject(gltfModel);
      valueOfSize = box.getSize(new THREE.Vector3());
      getSizeOfModel(valueOfSize);
    }

    if (scene != null && !isSceneReady) {
      setIsSceneReady(true);
    }

    return () => {};
  }, [gltfModel]);

  //For shadows every mesh's shadow properties must be true
  useEffect(
    () =>
      scene
        ? scene.traverse(function (node) {
            if (node.isMesh) {
              console.log("CAST SHADOW : ");
              node.castShadow = true;
              node.receiveShadow = true;
            }

            if (node.geometry) {
            }
          })
        : null,
    [scene]
  );

  useEffect(() => {
    scene.traverse((node) => {
      if (node.isMesh) {
        const material = node.material;
        if (material.map) {
          // console.log("ORIGINAL MATERIAL : ", material.map);
          setOriginalTextures((prevState) => ({
            ...prevState,
            [node.uuid]: material.map,
          }));
        }
      }
    });
  }, [gltfModel]);

  useEffect(() => {
    scene.traverse((node) => {
      console.log("DISPLAY MODE: ", displayMode);
      if (node.isMesh) {
        if (displayMode === DisplayModes.UVMAP) {
          node.material.map = colorMap;
        } else {
          const originalTexture = originalTextures[node.uuid];
          if (originalTexture) {
            node.material.map = originalTexture;
          }
        }

        node.material.wireframe = displayMode === DisplayModes.WIREFRAME;
        node.material.wireframeLinecap = "square";
        node.material.wireframeLinejoin = "miter";
      }
    });
  }, [displayMode, colorMap, originalTextures, scene]);

  scene.traverse((node) => {
    if (node.isMesh) {
      node.castShadow = true;
      node.receiveShadow = true;
    }
  });

  return isSceneReady && Object.keys(model) > 0 ? (
    <primitive scale={[20, 20, 20]} object={scene} />
  ) : null;
}

function ControlPanel({ handleSetDisplayMode }) {
  const { DisplayMode } = useControls({
    "Display Mode": {
      value: DisplayModes.TEXTURED,
      options: [
        DisplayModes.TEXTURED,
        DisplayModes.WIREFRAME,
        DisplayModes.UVMAP,
      ],
      onChange: (value) => {
        handleSetDisplayMode(value);
      },
    },
  });

  return null;
}

function LightControls({ changeAngle }) {
  const { Angle } = useControls({
    Angle: { value: 0, min: -Math.PI, max: Math.PI, step: 0.05 },
  });

  useEffect(() => {
    changeAngle(Angle);
    return () => {};
  }, [Angle]);

  return <></>;
}

function Light({ isSceneSelected }) {
  const ref = useRef();
  const lightRef = useRef();

  const [angle, setAngle] = useState(0);

  const changeAngle = (angle) => {
    console.log(angle);
    setAngle(angle);
  };

  const prevRotation = useRef([0, 0]);

  useFrame((state, delta) => {
    const newRotationX =
      prevRotation.current[0] + (angle - prevRotation.current[0]) * 0.04;
    const newRotationZ =
      prevRotation.current[1] + (angle - prevRotation.current[1]) * 0.04;

    const newXRotation = Math.sin(newRotationX) * 10;
    const newZRotation = Math.cos(newRotationZ) * 10;

    lightRef.current.position.x = newXRotation;
    lightRef.current.position.z = newZRotation;
    lightRef.current.lookAt(-2, -4, 0);

    prevRotation.current[0] = newRotationX;
    prevRotation.current[1] = newRotationZ;
  });

  useEffect(() => {
    const lightHelper = new THREE.DirectionalLightHelper(lightRef.current, 1);
    // ref.current.add(lightHelper);

    return () => {
      if (lightHelper) {
        ref.current?.remove(lightHelper);
      }
    };
  }, []);

  return (
    <group ref={ref}>
      {isSceneSelected && (
        <LightControls changeAngle={changeAngle}></LightControls>
      )}
      <directionalLight
        position={[2, 5, -8]}
        castShadow
        intensity={1}
        shadow-mapSize={2048}
        shadow-bias={-0.001}
        shadow-radius={20}
        ref={lightRef}
      >
        <orthographicCamera
          attach="shadow-camera"
          args={[-10, 10, 10, -10, 0.5, 25]}
        />
      </directionalLight>
      <ambientLight intensity={0.1} />
    </group>
  );
}

function ScreenShot({ uid, isModelReady }) {
  const dispatch = useDispatch();
  const { gl } = useThree();

  const image = useSelector((state) => state.images[uid]);

  console.log(
    "SCREENSHOT : ",
    // gl.domElement.toDataURL("image/png"),
    // typeof gl.domElement.toDataURL("image/png"),
    image,
    isModelReady
  );

  useEffect(() => {
    if (isModelReady && !image) {
      console.log(
        "SCREENSHOT1 : "
        // gl.domElement.toDataURL("image/png"),
        // typeof gl.domElement.toDataURL("image/png")
      );

      window.setTimeout(function () {
        dispatch(
          setImage({ uid: uid, image: gl.domElement.toDataURL("image/png") })
        );
      }, 100);
    }
    return () => {};
  }, [isModelReady, image]);

  return <></>;
}

const ResetCamera = ({
  reset,
  handleReset,
  orbitControlsRef,
  initialTarget,
}) => {
  const { invalidate } = useThree();

  useFrame((state, delta) => {
    if (reset) {
      handleReset(false);
      console.log("ORBITREF : ", orbitControlsRef.current, initialTarget);
      if (orbitControlsRef.current) {
        orbitControlsRef.current.reset();
        orbitControlsRef.current.target.set(
          initialTarget.x,
          initialTarget.y,
          initialTarget.z
        );
        orbitControlsRef.current.update();
      }
      state.camera.position.set(7.5, 2, 15);
      state.camera.updateProjectionMatrix();
      invalidate();
    }
  });

  return () => {
    <></>;
  };
};

function Scene({
  models,
  pageNo,
  modelNo,
  holderNo,
  isVariationCompleted,
  variations,
  no,
}) {
  const [isSceneSelected, setIsSceneSelected] = useState(false);
  const [displayMode, setDisplayMode] = useState(DisplayModes.TEXTURED);
  const [size, setSize] = useState(null);
  const [target, setTarget] = useState(null);
  const [isModelReady, setIsModelReady] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const modelVersions = useSelector((state) => state.model.modelVersions);

  const checkBoxedModel = useSelector((state) => state.model.checkBoxedModel);
  const checkBoxedModelsVariation = useSelector(
    (state) => state.model.checkBoxedModelsVariation
  );
  const regenerateStatus = useSelector(
    (state) => state.status.regenerateStatus
  );
  const selectedModel = useSelector((state) => state.model.selectedModel);
  const isVariationsReady = useSelector(
    (state) => state.status.isVariationsReady
  );
  const chatInitialModel = useSelector((state) => state.model.chatInitialModel);
  const chatFinalModel = useSelector((state) => state.model.chatFinalModel);
  const isCheckBoxesShown = useSelector(
    (state) => state.buttons.isCheckBoxesShown
  );
  const isFirstModelSelectedFromChat = useSelector(
    (state) => state.message.isFirstModelSelectedFromChat
  );

  const [dpr, setDpr] = useState(1);
  const [reset, setReset] = useState(false);

  const isFirstModelTriggeredRef = useRef(false);
  const isFinalModelTriggeredRef = useRef(false);
  const orbitControlsRef = useRef(null);

  const handleReset = (val) => {
    setReset(val);
  };

  console.log(
    "MODELVERSIONNO : ",
    pageNo === 3 && isVariationsReady && chatInitialModel
      ? `V${chatInitialModel.split(".")[0].replace("V", "")}.${modelNo}`
      : `V${pageNo * 4 + no}.${"0"}`,
    modelVersions[
      pageNo === 3 && isVariationsReady && chatInitialModel
        ? `V${chatInitialModel.split(".")[0].replace("V", "")}.${modelNo}`
        : `V${pageNo * 4 + no}.${"0"}`
    ],
    models[
      modelVersions[
        pageNo === 3 && isVariationsReady && chatInitialModel
          ? `V${chatInitialModel.split(".")[0].replace("V", "")}.${modelNo}`
          : `V${pageNo * 4 + no}.${"0"}`
      ]
    ]
  );

  const changeIsModelReady = (state) => {
    setIsModelReady(state);
    console.log("PROGRESS MODEL READY : ", state);
  };

  const dispatch = useDispatch();

  const getSize = (size) => {
    setSize(size);
    setTarget(new THREE.Vector3(size.x / 2, size.y / 2, size.z / 2));
  };

  const selectClickedModel = (event) => {
    // setIsSceneSelected(true);
    // console.log("WOW");
    // dispatch(selectModel({ model: { no: modelNo, pageNo: pageNo } }));
    // event.stopPropagation();
  };

  const clearModelSelection = (e) => {
    console.log("CLEAR : ");
    setIsSceneSelected(false);
    dispatch(selectModel({ model: null }));
    // e.stopPropagation();
  };

  const handleSetModelFromChat = (chatInitialModel) => {
    //TODO: change chatinitial name, bc there is also chatfinal name,

    console.log(
      "handleSetModelFromChat1 : ",
      Array.isArray(
        chatInitialModel || regenerateStatus === VARIATION_GENERATION
      ),
      Array.isArray(chatInitialModel),
      regenerateStatus === VARIATION_GENERATION
    );

    if (
      Array.isArray(chatInitialModel) ||
      regenerateStatus === VARIATION_GENERATION
    ) {
      return;
    }

    if (
      chatInitialModel ===
      (pageNo === 3 && isVariationsReady && chatInitialModel
        ? `V${chatInitialModel.split(".")[0].replace("V", "")}.${modelNo}`
        : `V${pageNo * 4 + no}.${"0"}`)
    ) {
      setIsSceneSelected(true);
      console.log("WOW");
      dispatch(selectModel({ model: { no: modelNo, pageNo: pageNo } }));
      dispatch(
        setCheckBoxedModel({
          model: {
            pageNo: pageNo,
            no: modelNo,
            model:
              models[
                modelVersions[
                  pageNo === 3 && isVariationsReady && chatInitialModel
                    ? `V${chatInitialModel
                        .split(".")[0]
                        .replace("V", "")}.${modelNo}`
                    : `V${pageNo * 4 + no}.${"0"}`
                ]
              ],
            version:
              pageNo === 3 && isVariationsReady && chatInitialModel
                ? `V${chatInitialModel
                    .split(".")[0]
                    .replace("V", "")}.${modelNo}`
                : `V${pageNo * 4 + no}.${"0"}`,
          },
        })
      );
    }
  };

  useEffect(() => {
    console.log(
      chatInitialModel,
      chatFinalModel,
      isFirstModelTriggeredRef.current,
      isFinalModelTriggeredRef.current
    );
    if (chatFinalModel && !isFinalModelTriggeredRef.current) {
      handleSetModelFromChat(chatFinalModel);
      dispatch(setIsCheckBoxesShown({ status: false }));
      dispatch(setIsProceedButtonShown({ status: false }));
      isFinalModelTriggeredRef.current = true;
    } else if (chatInitialModel && !isFirstModelTriggeredRef.current) {
      handleSetModelFromChat(chatInitialModel);
      dispatch(setIsCheckBoxesShown({ status: false }));

      dispatch(setIsProceedButtonShown({ status: false }));
      isFirstModelTriggeredRef.current = true;
    }
  }, [isFirstModelSelectedFromChat, chatInitialModel, chatFinalModel]);

  useEffect(() => {
    function handleKeyDown(e) {
      //F KEY
      if (e.keyCode === 70) {
        setReset(true);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleChange = (e) => {
    console.log("The checkbox1 was toggled : ", e.target.checked);
    if (e.target.checked) {
      setIsSceneSelected(true);
      console.log("WOW");
      dispatch(selectModel({ model: { no: modelNo, pageNo: pageNo } }));
      dispatch(
        setCheckBoxedModel({
          model: {
            pageNo: pageNo,
            no: modelNo,
            model:
              models[
                modelVersions[
                  pageNo === 3 && isVariationsReady && chatInitialModel
                    ? `V${chatInitialModel
                        .split(".")[0]
                        .replace("V", "")}.${modelNo}`
                    : `V${pageNo * 4 + no}.${"0"}`
                ]
              ],
            version:
              pageNo === 3 && isVariationsReady && chatInitialModel
                ? `V${chatInitialModel
                    .split(".")[0]
                    .replace("V", "")}.${modelNo}`
                : `V${pageNo * 4 + no}.${"0"}`,
          },
        })
      );
      // e.stopPropagation();
    }
  };

  const handleChangeInVariations = (e) => {
    console.log("The checkbox1 was toggled : ", e.target.checked);
    if (e.target.checked) {
      // setIsSceneSelected(true);
      console.log("handleChangeInVariations");
      setCheckBox(true);
      dispatch(
        setCheckBoxedModelsVariation({
          state: {
            ...checkBoxedModelsVariation,
            [pageNo === 3 && isVariationsReady && chatInitialModel
              ? `V${chatInitialModel.split(".")[0].replace("V", "")}.${modelNo}`
              : `V${pageNo * 4 + no}.${"0"}`]: {
              model: {
                pageNo: pageNo,
                no: modelNo,
                model:
                  models[
                    modelVersions[
                      pageNo === 3 && isVariationsReady && chatInitialModel
                        ? `V${chatInitialModel
                            .split(".")[0]
                            .replace("V", "")}.${modelNo}`
                        : `V${pageNo * 4 + no}.${"0"}`
                    ]
                  ],
                version:
                  pageNo === 3 && isVariationsReady && chatInitialModel
                    ? `V${chatInitialModel
                        .split(".")[0]
                        .replace("V", "")}.${modelNo}`
                    : `V${pageNo * 4 + no}.${"0"}`,
              },
            },
          },
        })
      );
      // e.stopPropagation();
    } else {
    }
  };

  const handleClick = (e) => {
    if (
      JSON.stringify(checkBoxedModel) ===
      JSON.stringify({
        pageNo: pageNo,
        no: modelNo,
        model:
          models[
            pageNo === 3 && isVariationsReady && chatInitialModel
              ? `V${chatInitialModel.split(".")[0].replace("V", "")}.${modelNo}`
              : `V${pageNo * 4 + no}.${"0"}`
          ],
        version:
          pageNo === 3 && isVariationsReady && chatInitialModel
            ? `V${chatInitialModel.split(".")[0].replace("V", "")}.${modelNo}`
            : `V${pageNo * 4 + no}.${"0"}`,
      })
    ) {
      dispatch(setCheckBoxedModel({ model: null }));
      setCheckBox(false);
      clearModelSelection();
    }
  };

  const handleClickVariations = (e) => {
    console.log("handleClickInVariations", checkBoxedModelsVariation);

    if (checkBox) {
      dispatch(
        removeFromCheckBoxedModelsVariation({
          state:
            pageNo === 3 && isVariationsReady && chatInitialModel
              ? `V${chatInitialModel.split(".")[0].replace("V", "")}.${modelNo}`
              : `V${pageNo * 4 + no}.${"0"}`,
        })
      );
      setCheckBox(false);
      clearModelSelection();
    }
  };

  const handleSetDisplayMode = (mode) => {
    setDisplayMode(mode);
  };

  useEffect(() => {
    console.log(
      "SELECTED MODEL : ",
      checkBoxedModel?.model,
      modelVersions[
        pageNo === 3 && isVariationsReady && chatInitialModel
          ? `V${chatInitialModel.split(".")[0].replace("V", "")}.${modelNo}`
          : `V${pageNo * 4 + no}.${"0"}`
      ]
    );
    if (
      checkBoxedModel?.model ===
        modelVersions[
          pageNo === 3 && isVariationsReady && chatInitialModel
            ? `V${chatInitialModel.split(".")[0].replace("V", "")}.${modelNo}`
            : `V${pageNo * 4 + no}.${"0"}`
        ] &&
      checkBoxedModel?.model &&
      modelVersions[
        pageNo === 3 && isVariationsReady && chatInitialModel
          ? `V${chatInitialModel.split(".")[0].replace("V", "")}.${modelNo}`
          : `V${pageNo * 4 + no}.${"0"}`
      ]
    ) {
      setCheckBox(true);
    } else {
      setCheckBox(false);
    }
    return () => {};
  }, [checkBoxedModel]);

  return (
    <div
      onClick={(event) => {
        selectClickedModel(event);
      }}
      style={
        selectedModel?.no === modelNo &&
        selectedModel?.pageNo === pageNo &&
        regenerateStatus !== VARIATION_GENERATION
          ? { width: "100%", height: "100%" }
          : selectedModel && regenerateStatus !== VARIATION_GENERATION
          ? { display: "none" }
          : { width: "45%", height: "50%" }
      }
    >
      <div
        style={{
          position: "relative",
          top: 40,
          left: 10,
          zIndex: 10231231312312,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "97%",
        }}
      >
        <div
          style={{
            width: "10%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {isCheckBoxesShown ? (
            <input
              type={
                regenerateStatus !== VARIATION_GENERATION ? "radio" : "checkbox"
              }
              checked={checkBox}
              onChange={
                regenerateStatus !== VARIATION_GENERATION
                  ? handleChange
                  : handleChangeInVariations
              }
              onClick={
                regenerateStatus !== VARIATION_GENERATION
                  ? handleClick
                  : handleClickVariations
              }
            ></input>
          ) : (
            <></>
          )}
          <p style={{ color: "white", userSelect: "none" }}>
            {pageNo === 3 && isVariationsReady && chatInitialModel
              ? `V${chatInitialModel.split(".")[0].replace("V", "")}.${modelNo}`
              : `V${pageNo * 4 + no}.${"0"}`}
          </p>
        </div>
        {/* <div>
          {selectedModel?.no === modelNo && selectedModel?.pageNo === pageNo ? (
            <button
              onClick={(event) => {
                clearModelSelection(event);
              }}
            >
              X
            </button>
          ) : null}
        </div> */}
      </div>
      <Canvas
        dpr={dpr}
        frameloop={"demand"}
        gl={{ antialias: false, preserveDrawingBuffer: true }}
        // shadows
        pres
        camera={{ position: [7.5, 2, 15], fov: 15 }}
        className={
          selectedModel?.no === modelNo && selectedModel?.pageNo === pageNo
            ? styles.sceneLargeContainer
            : styles.sceneContainer
        }
        // style={{ background: "#B0B0B0" }}
      >
        {selectedModel?.no === modelNo && selectedModel?.pageNo === pageNo && (
          <ControlPanel handleSetDisplayMode={handleSetDisplayMode} />
        )}
        {/* <Stats /> */}
        <ResetCamera
          reset={reset}
          handleReset={handleReset}
          orbitControlsRef={orbitControlsRef}
          initialTarget={target}
        ></ResetCamera>
        <Light
          model={modelNo}
          models={models}
          isSceneSelected={
            selectedModel?.no === modelNo && selectedModel?.pageNo === pageNo
          }
        ></Light>
        <group position={[0, 0, 0]}>
          <mesh position={target ? target : [0, 0, 0]}>
            <boxGeometry args={[0, 0, 0]} />
          </mesh>
          <Model
            models={
              models[
                modelVersions[
                  pageNo === 3 && isVariationsReady && chatInitialModel
                    ? `V${chatInitialModel
                        .split(".")[0]
                        .replace("V", "")}.${modelNo}`
                    : `V${pageNo * 4 + no}.${"0"}`
                ]
              ]
            }
            getSize={getSize}
            modelNo={modelNo}
            pageNo={pageNo}
            holderNo={holderNo}
            isVariationCompleted={isVariationCompleted}
            variations={variations}
            displayMode={displayMode}
            changeIsModelReady={changeIsModelReady}
          />
        </group>
        {isModelReady && (
          <ScreenShot
            uid={
              modelVersions[
                pageNo === 3 && isVariationsReady && chatInitialModel
                  ? `V${chatInitialModel
                      .split(".")[0]
                      .replace("V", "")}.${modelNo}`
                  : `V${pageNo * 4 + no}.${"0"}`
              ]
            }
            isModelReady={isModelReady}
          ></ScreenShot>
        )}

        <EffectComposer disableNormalPass>
          <Bloom mipmapBlur intensity={1} luminanceThreshold={1} />
          <N8AO aoRadius={20} intensity={4} screenSpaceRadius />
          <Vignette offset={0.4} darkness={0.4} />
        </EffectComposer>
        <OrbitControls
          ref={orbitControlsRef}
          rotateSpeed={0.45}
          target={target}
          enablePan={true}
        />
        {/* <mesh rotation-x={Math.PI * -0.5}>
          <planeGeometry attach="geometry" args={[300, 300]} />
          <meshPhongMaterial attach="material" color="white" />
        </mesh> */}
        <Environment
          // files={"./small_cathedral_02_2k.hdr"}
          preset="city"
          // background
          blur={1}
        />
      </Canvas>
    </div>
  );
}

// function Model({ models, getSize, modelNo, displayMode, changeIsModelReady }) {
//   const [gltfFile, setGLTFFile] = useState(null);
//   const [sizeOfModel, setSizeOfModel] = useState({});
//   const sizeCount = useRef(0);

//   useEffect(() => {
//     if (typeof models === "object") {
//       console.log(models);

//       console.log("MODEL123 : ", models);
//       console.log("DENEME123 : ", models[Object.keys(models)[modelNo]]);

//       if (typeof models[Object.keys(models)[modelNo]] === "object") {
//         Object.keys(models[Object.keys(models)[modelNo]]).forEach(
//           (filename) => {
//             console.log("MODEL FILENAME : ", filename);
//             if (filename.includes(".gltf") && !filename.includes("MACOS")) {
//               console.log(
//                 "GLTF FILE : ",
//                 filename,
//                 models[Object.keys(models)[modelNo]][filename]
//               );
//               setGLTFFile(filename);
//             }
//           }
//         );
//       }
//     }
//     return () => {};
//   }, [models]);

//   async function getSizeOfModel(size) {
//     if (sizeCount.current === 0) {
//       setSizeOfModel(size);
//       sizeCount.current += 1;
//       console.log("SIZE : ", size);
//       getSize(size);
//     }
//   }
//   return (
//     <>
//       {typeof models[Object.keys(models)[modelNo]] === "object"
//         ? Object.keys(models[Object.keys(models)[modelNo]]).length > 0 &&
//           gltfFile && (
//             <GltfLoaderComp
//               changeIsModelReady={changeIsModelReady}
//               model={models[Object.keys(models)[modelNo]]}
//               gltfFile={gltfFile}
//               getSizeOfModel={getSizeOfModel}
//               displayMode={displayMode}
//             />
//           )
//         : null}
//     </>
//   );
// }

function Model({ models, getSize, modelNo, displayMode, changeIsModelReady }) {
  // const [usdzFile, setUsdzFile] = useState(null);
  const [gltfFile, setGltfFile] = useState(null);
  const [sizeOfModel, setSizeOfModel] = useState({});
  const sizeCount = useRef(0);

  useEffect(() => {
    if (typeof models === "object") {
      Object.keys(models).forEach((filename) => {
        console.log("MODEL FILENAME : ", filename);
        if (filename.includes(".gltf") && !filename.includes("MACOS")) {
          console.log("USDZ FILE : ", filename, models[filename]);
          setGltfFile(filename);
        }
      });
    }
    return () => {};
  }, [models]);

  async function getSizeOfModel(size) {
    if (sizeCount.current === 0) {
      setSizeOfModel(size);
      sizeCount.current += 1;
      console.log("SIZE : ", size);
      getSize(size);
    }
  }
  return (
    <>
      {typeof models === "object"
        ? Object.keys(models).length > 0 &&
          gltfFile && (
            // <USDZLoaderComp
            //   changeIsModelReady={changeIsModelReady}
            //   model={models[Object.keys(models)[modelNo]]}
            //   usdzFile={usdzFile}
            //   getSizeOfModel={getSizeOfModel}
            //   displayMode={displayMode}
            // />
            <GltfLoaderComp
              changeIsModelReady={changeIsModelReady}
              model={models}
              gltfFile={gltfFile}
              getSizeOfModel={getSizeOfModel}
              displayMode={displayMode}
            />
          )
        : null}
    </>
  );
}

export default Scene;
