import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

const initialState = {
  modelList: [],
  regenerateAttemptNo: 0,
  activeJob: {},
  isRegenerateRequested: false,
  selectedModel: null,
  checkBoxedModel: null,
  checkBoxedModelsVariation: {},
  firstRegeneration: null,
  secondRegeneration: null,
  thirdRegeneration: null,
  variation: {},
  downloadReadyModal: null,
  allowCheckStatus: false,
  modelVersions: {},
  chatInitialModel: null,
  chatSecondModel: null,
  chatFinalModel: null,
  selectedModelType: null,
};

const modelSlice = createSlice({
  name: "model",
  initialState,
  reducers: {
    saveActiveJob: (state, action) => {
      state.activeJob = action.payload.job;
    },
    saveFirstJob: (state, action) => {
      state.firstRegeneration = action.payload.job;
    },
    saveSecondJob: (state, action) => {
      state.secondRegeneration = action.payload.job;
    },
    saveThirdJob: (state, action) => {
      state.thirdRegeneration = action.payload.job;
    },
    saveVariation: (state, action) => {
      state.variation = { ...state.variation, ...action.payload.job };
    },
    addSelectedJobToVariates: (state, action) => {
      state.variation.results = {
        ...state.variation?.results,
        3: action.payload.job,
      };
    },
    saveModelNames: (state, action) => {
      state.modelList = [...state.regenerate.modelList, action.payload.model];
    },
    setRegenerateAttemptNo: (state, action) => {
      console.log("increaseRegenerateAttemptNo : ", state.regenerateAttemptNo);
      state.regenerateAttemptNo = action.payload.attemptNo;
    },
    setIsRegenerateRequested: (state, action) => {
      state.isRegenerateRequested = action.payload.request;
    },
    selectModel: (state, action) => {
      state.selectedModel = action.payload.model;
    },
    setCheckBoxedModel: (state, action) => {
      state.checkBoxedModel = action.payload.model;
    },
    setModelVersions: (state, action) => {
      state.modelVersions = {
        ...state.modelVersions,
        ...action.payload.modelVersions,
      };
    },
    saveDownloadReadyModel: (state, action) => {
      state.downloadReadyModal = action.payload.job;
    },
    setChatInitialModel: (state, action) => {
      state.chatInitialModel = action.payload.state;
    },
    setChatSecondModel: (state, action) => {
      state.chatSecondModel = action.payload.state;
    },
    setChatFinalModel: (state, action) => {
      state.chatFinalModel = action.payload.state;
    },
    setSelectedModelType: (state, action) => {
      state.selectedModelType = action.payload.state;
    },
    setCheckBoxedModelsVariation: (state, action) => {
      state.checkBoxedModelsVariation = action.payload.state;
    },
    addCheckBoxedModelsVariation: (state, action) => {
      state.checkBoxedModelsVariation = {
        ...state.checkBoxedModelsVariation,
        ...action.payload.state,
      };
    },
    removeFromCheckBoxedModelsVariation: (state, action) => {
      delete state.checkBoxedModelsVariation[action.payload.state];
    },
    clearModels: (state, action) => {
      state = initialState;
    },
  },
});

export const {
  saveActiveJob,
  saveFirstJob,
  saveSecondJob,
  saveThirdJob,
  setRegenerateAttemptNo,
  saveRegenerate,
  setIsRegenerateRequested,
  selectModel,
  setCheckBoxedModel,
  saveVariation,
  clearModels,
  setModelVersions,
  saveDownloadReadyModel,
  setChatFinalModel,
  setChatInitialModel,
  setChatSecondModel,
  setSelectedModelType,
  setCheckBoxedModelsVariation,
  removeFromCheckBoxedModelsVariation,
  addSelectedJobToVariates,
  addCheckBoxedModelsVariation,
} = modelSlice.actions;

export default modelSlice.reducer;
