import styles from "./Login.module.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  loginSuccess,
  loginFailure,
  setPreferences,
  clearStore,
} from "../../store/features/userSlice";
import { login } from "../../utils/login/login";
import { checkJWT, sessionLogId } from "../../utils/session/Session";
import { useNavigate, Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import GoogleSign from "../register/GoogleSign";
import ShowAlert from "../../alert/showAlert";
import Navbar from "../../components/navbar/Navbar";
import localforage from "localforage";
import axios from "axios";
import { setDownloadAllowanceViewer } from "../../store/features/downloadSlice";

function Login() {
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const [queryParameters] = useSearchParams();
  const [modelId, setModelId] = useState();
  const [hasQueryParams, setHasQueryParams] = useState(false);
  const navigate = useNavigate();
  const [verified, setverified] = useState(false);
  const [inputsMissingEmail, setInputsMissingEmail] = useState(false);
  const [inputsMissingPassword, setInputsMissingPassword] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showPasswordIcon, setShowPasswordIcon] = useState(false);
  const [invalidInput, setInvalidInput] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);
  const [capsLock, setCapsLock] = useState(false);
  const [recap, setRecap] = useState(false);
  const isAuth = useSelector((state) => state.user.login);

  useEffect(() => {
    if (queryParameters.size > 0) {
      setModelId(queryParameters.get("model"));
      setHasQueryParams(true);
    } else {
      return;
    }
  }, [hasQueryParams, modelId, queryParameters]);

  function onChange(value) {
    console.log("Captcha value:", value);
    setverified(true);
  }

  const handleInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    setInvalidInput(false);
    setInputsMissingEmail(false);
    setInputsMissingPassword(false);
  };
  const handleKeyPress = (e) => {
    if (e.getModifierState("CapsLock")) {
      setCapsLock(true);
    } else {
      setCapsLock(false);
    }
  };

  const getNewSessionToken = async (data) => {
    let response1;
    let data1;
    let sessionId;
    response1 = await sessionLogId(user);
    data1 = await response1.json();
    sessionId = data1?.user?.session_id;
    dispatch(clearStore());
    localStorage.removeItem("firstRegeneration");
    localStorage.removeItem("downloadStatus");
    await localforage.clear();
    localStorage.setItem("lastLogin", data.email);
    return sessionId;
  };

  const handleLogin = async () => {
    try {
      if (user.email && user.password) {
        const response = await login(user);
        const data = await response.json();
        let sessionId;
        if (response.status === 200) {
          if (
            localStorage.getItem("lastLogin") !== data.email ||
            !localStorage.getItem("sessionKey")
          ) {
            sessionId = await getNewSessionToken(data);
          } else {
            if (checkJWT() === "valid") {
              sessionId = localStorage.getItem("sessionKey");
            } else {
              sessionId = await getNewSessionToken(data);
            }
          }

          let userImg;

          try {
            const resp = await axios.post(
              `${process.env.REACT_APP_SERVER}/api/users/getImage`,
              {
                user_id: data.id,
              },
              { responseType: "arraybuffer" }
            );
            console.log("DATAresp : ", resp);
            if (resp.status === 200) {
              userImg = Buffer.from(resp.data, "binary").toString("base64");
            } else {
              console.log("no_image");
            }
          } catch (e) {
            console.log(e);
          }

          const loggedInUser = {
            id: data.id,
            name: data.name,
            surname: data.surname,
            email: data.email,
            image: userImg,
            company: data.company,
            occupation: data.occupation,
            website: data.website,
            country: data.country,
            city: data.city,
            credits: data.credits,
            session: sessionId,
          };

          if (verified) {
            setRecap(true);
            localStorage.setItem("sessionKey", sessionId);
            dispatch(loginSuccess(loggedInUser));
            dispatch(
              setPreferences({
                preferences: {
                  fileExtension: data.preferred_file_format,
                  length: data.preferred_length_units,
                  meshType: data.preferred_mesh_type,
                  textureResolution: data.preferred_texture_resolution,
                },
              })
            );
            navigate("/home");
          } else {
            setRecap(true);
            ShowAlert(3, "Check recaptcha");
          }
        } else if (response.status === 401) {
          if (data.message === "user_not_found") {
            ShowAlert(3, "User not found!");
            setInvalidInput(true);
          } else if (data.message === "invalid_password") {
            ShowAlert(3, "Incorrect username or password");
            setInvalidInput(true);
          } else if (data.message === "not_verified") {
            ShowAlert(
              3,
              "Account is not verified yet. Please check your inbox."
            );
          } else {
            ShowAlert(3, "Something went wrong!");
          }

          dispatch(loginFailure(response));
        } else {
          console.log("Unknown error");
        }
      } else {
        ShowAlert(3, "Missing Information!");
        if (!user.email) {
          setInputsMissingEmail(true);
        } else {
          setInputsMissingEmail(false);
        }
        if (!user.password) {
          setInputsMissingPassword(true);
        } else {
          setInputsMissingPassword(false);
        }
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const isAuthorized = () => {
    try {
      let isAuthorizedRes = checkJWT();
      if (isAuthorizedRes !== "valid") {
        setIsLoggedIn(false);
      } else {
        setIsLoggedIn(true);
      }
    } catch (e) {}
  };

  useEffect(() => {
    isAuthorized();
    setIsPageLoaded(true);
  }, []);

  if (!isPageLoaded) {
    return null;
  }

  if (hasQueryParams && isAuth === true) {
    dispatch(setDownloadAllowanceViewer({ state: true }));
    navigate(`/file-downloader?model=${modelId}`);
  } else if (isAuth === true) {
    navigate("/home");
    console.log("HOME B");
  } else
    return (
      <div className={styles.container}>
        <Navbar modelId={modelId} />
        <div className={styles.loginContainer}>
          <div className={styles.headOfP}>
            <img
              className={styles.imageProcedura}
              src={require("../../images/logo.png")}
              alt=""
            />
            <h2 className={styles.title}>
              <span className={styles.brand}>procedura</span>
            </h2>
          </div>
          <div className={styles.inputContainer}>
            <div
              className={
                invalidInput
                  ? styles.invalidInputContainer
                  : `${styles.inputRow} ${styles.first}`
              }
            >
              <input
                name="email"
                onChange={(e) => {
                  handleInput(e);
                }}
                placeholder="EMAIL ADDRESS"
                type="email"
                className={
                  invalidInput
                    ? styles.invalidInput
                    : `${styles.basicInput}  ${
                        inputsMissingEmail ? styles.missing : ""
                      }`
                }
              ></input>
              {invalidInput && (
                <p className={styles.invalidInputAlert}>
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: "5px" }}
                  >
                    <path
                      d="M9.18359 0C7.36725 0 5.59169 0.527841 4.08146 1.51677C2.57123 2.50571 1.39415 3.91131 0.699063 5.55585C0.00397909 7.20038 -0.177886 9.00998 0.176464 10.7558C0.530815 12.5016 1.40547 14.1053 2.68981 15.364C3.97416 16.6226 5.61052 17.4798 7.39196 17.8271C9.1734 18.1743 11.0199 17.9961 12.698 17.3149C14.3761 16.6337 15.8104 15.4802 16.8195 14.0001C17.8286 12.5201 18.3672 10.78 18.3672 9C18.3672 6.61305 17.3996 4.32387 15.6774 2.63604C13.9551 0.948211 11.6192 0 9.18359 0ZM9.18359 16.7143C7.62672 16.7143 6.10482 16.2619 4.81034 15.4142C3.51585 14.5665 2.50692 13.3617 1.91114 11.9521C1.31535 10.5425 1.15947 8.99144 1.4632 7.49502C1.76692 5.99859 2.51663 4.62404 3.61749 3.54518C4.71836 2.46631 6.12096 1.7316 7.64791 1.43394C9.17486 1.13628 10.7576 1.28905 12.1959 1.87293C13.6343 2.45681 14.8637 3.44557 15.7286 4.71417C16.5936 5.98278 17.0552 7.47426 17.0552 9C17.0552 11.046 16.2259 13.0081 14.7497 14.4548C13.2735 15.9015 11.2713 16.7143 9.18359 16.7143Z"
                      fill="#FE2020"
                    />
                    <path
                      d="M8.52818 3.85645H9.84012V10.9279H8.52818V3.85645ZM9.18415 12.8564C8.98954 12.8564 8.79931 12.913 8.6375 13.019C8.47568 13.1249 8.34957 13.2755 8.27509 13.4517C8.20062 13.6279 8.18114 13.8218 8.2191 14.0089C8.25707 14.1959 8.35078 14.3677 8.48839 14.5026C8.626 14.6374 8.80132 14.7293 8.99219 14.7665C9.18306 14.8037 9.3809 14.7846 9.56069 14.7116C9.74049 14.6386 9.89416 14.515 10.0023 14.3565C10.1104 14.1979 10.1681 14.0114 10.1681 13.8207C10.1681 13.565 10.0644 13.3197 9.87991 13.1389C9.69538 12.958 9.44511 12.8564 9.18415 12.8564Z"
                      fill="#FE2020"
                    />
                  </svg>
                  Invalid email or password
                </p>
              )}
            </div>
            <div className={`${styles.inputRow} ${styles.second}`}>
              <input
                onChange={(e) => {
                  handleInput(e);
                  setShowPasswordIcon(true);
                }}
                name="password"
                type={hidePassword ? "password" : "text"}
                onKeyPress={handleKeyPress}
                placeholder="PASSWORD"
                className={`${styles.basicInput}  ${
                  inputsMissingPassword ? styles.missing : ""
                }`}
                style={{ position: "relative" }}
              />
              {capsLock && (
                <svg
                  className={styles.capsLock}
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 3.63578L13.2375 9.55359L15 7.7357L7.5 0L0 7.7357L1.7625 9.55359L7.5 3.63578ZM0 16H15V13.4214H0V16Z"
                    fill="#FAF947"
                    fill-opacity="0.8"
                  />
                </svg>
              )}
              {showPasswordIcon &&
                (hidePassword ? (
                  <svg
                    onClick={() => setHidePassword(false)}
                    className={styles.showPassword}
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 8.5C0 8.5 4.188 2.5 11 2.5C11.947 2.5 12.839 2.621 13.678 2.822L7.36 9.14C7.12415 8.62535 7.00139 8.06611 7 7.5C7 6.563 7.335 5.713 7.875 5.031C5.483 5.843 3.661 7.416 2.621 8.5C3.50694 9.42431 4.50856 10.2303 5.601 10.898L4.148 12.351C1.497 10.63 0 8.5 0 8.5ZM22 8.5C22 8.5 17.812 14.5 11 14.5C10.054 14.5 9.164 14.376 8.324 14.177L4 18.5L2.5 17L19.5 0L21 1.5L17.853 4.647C20.501 6.369 22 8.5 22 8.5ZM19.385 8.506C18.4975 7.57914 17.4934 6.77139 16.398 6.103L15 7.5C15 8.56087 14.5786 9.57828 13.8284 10.3284C13.0783 11.0786 12.0609 11.5 11 11.5L10.053 12.447C10.363 12.478 10.677 12.5 11 12.5C14.978 12.5 17.943 10.022 19.385 8.506Z"
                      fill="#F3F4F8"
                      fill-opacity="0.5608"
                    />
                  </svg>
                ) : (
                  <svg
                    onClick={() => setHidePassword(true)}
                    className={styles.showPassword}
                    width="22"
                    height="12"
                    viewBox="0 0 22 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 0C4.188 0 0 6 0 6C0 6 4.188 12 11 12C17.812 12 22 6 22 6C22 6 17.812 0 11 0ZM11 10C7.057 10 4.074 7.516 2.621 6C3.661 4.915 5.483 3.343 7.875 2.531C7.3117 3.23091 7.00314 4.10157 7 5C7 6.06087 7.42143 7.07828 8.17157 7.82843C8.92172 8.57857 9.93913 9 11 9C12.0609 9 13.0783 8.57857 13.8284 7.82843C14.5786 7.07828 15 6.06087 15 5C14.9969 4.10157 14.6883 3.23091 14.125 2.531C16.518 3.343 18.341 4.916 19.379 6C17.924 7.518 14.942 10 11 10Z"
                      fill="#F3F4F8"
                      fill-opacity="0.8"
                    />
                  </svg>
                ))}
            </div>
            <div className={styles.linkContainer}>
              <Link to="/forgot-password" className={styles.forgot}>
                Forgot my password
              </Link>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.loginButton} onClick={handleLogin}>
              LOG IN
            </button>
          </div>
          <div className={styles.signContainer}>
            <p className={styles.accountQuestion}>
              Don't have an account?{" "}
              <Link
                to={hasQueryParams ? `/register?model=${modelId}` : "/register"}
                className={styles.buttonSign}
              >
                Sing Up
              </Link>
            </p>

            <script src="https://www.google.com/recaptcha/enterprise.js?render=6LcZwHspAAAAAI-0bn4AXA9tU_ZE5DAcU8PdJq6n"></script>

            <ReCAPTCHA
              sitekey={process.env.REACT_APP_CAPTCHA_CLIENT_ID_DEV}
              onChange={onChange}
              className={styles.captcha}
            />

            {!hasQueryParams && (
              <div className={styles.line}>
                <hr className={styles.hrLine} />
                <p className={styles.or}>or</p>
                <hr className={styles.hrLine} />
              </div>
            )}

              <div className={styles.icons}>
                <GoogleSign></GoogleSign>
              </div>

          </div>
        </div>
      </div>
    );
}

export default Login;
