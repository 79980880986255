import Navbar from "../../components/settings/navbar/Navbar";
import style from "./settings.module.css";
import Profile from "../../components/settings/profile/Profile";
import Preferences from "../../components/settings/preferences/Preferences";
import Security from "../../components/settings/security/Security";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AssetLibrary from "../../components/assetLibrary/assetLibrary";
import { checkJWT } from "../../utils/session/Session";
import { useNavigate } from "react-router";
import { logout } from "../../store/features/userSlice";
const Settings = () => {
  const navigate = useNavigate();
  const [clicked, setClicked] = useState("");
  const user = useSelector((state) => state.user);
  const settingsPage = useSelector((state) => state.settingsPage.page);
  const dispatch = useDispatch();

  useEffect(() => {
    let isAuth = checkJWT();
    if (isAuth !== "valid") {
      dispatch(logout());
      navigate("/");
      localStorage.removeItem("sessionKey");
    }
    return () => {};
  }, []);

  return (
    <div className={style.settingContainer}>
      <div className={style.settingModal}>
        {settingsPage === "library" && <AssetLibrary isModal={false} />}
        {settingsPage === "settings" && (
          <>
            <label className={style.myLabel}>SETTINGS</label>
            <div className={style.settingOptions}>
              <div className={style.optionButtons}>
                <button
                  onClick={() => {
                    setClicked("profile");
                  }}
                  className={
                    clicked === "profile" ? style.clickedButton : style.buttons
                  }
                >
                  Profile
                </button>
                <button
                  onClick={() => {
                    setClicked("preferences");
                  }}
                  className={
                    clicked === "preferences"
                      ? style.clickedButton
                      : style.buttons
                  }
                >
                  Preferences
                </button>
                <button
                  onClick={() => {
                    setClicked("security");
                  }}
                  className={
                    clicked === "security" ? style.clickedButton : style.buttons
                  }
                >
                  Security
                </button>
              </div>

              <div className={style.optionsResult} key={clicked}>
                {clicked === "profile" && <Profile />}
                {clicked === "preferences" && <Preferences />}
                {clicked === "security" && <Security />}
              </div>
            </div>
          </>
        )}
      </div>
      <div className={style.navbar}>
        <Navbar />
      </div>
    </div>
  );
};

export default Settings;
