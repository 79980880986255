import React, { useEffect, useState } from "react";
import Medium from "../../../../ui/chatButtons/infoButtons/Medium";
import Mini from "../../../../ui/chatButtons/infoButtons/Mini";
import Large from "../../../../ui/chatButtons/infoButtons/Large";

const PickButton = ({
  onOptionClick,
  meta,
  onClick,
  click,
  handleShowClicked,
  isActive,
  index,
  clickedOption,
}) => {
  const [clickedData, setClickedData] = useState(null);

  console.log("PickButton value : ", meta);

  const handleMetaClick = (value) => {
    console.log("HANDLE META CLICK1");
    if (isActive) {
      onClick(value);
      onOptionClick(value.payload, index);
      setClickedData(value);
      handleShowClicked(true);
      console.log("cli1", value);
    }
  };

  useEffect(() => {
    if (clickedOption) {
      meta?.map((val) => {
        console.log("META MAP : ", val, clickedOption);
        if (val.payload === clickedOption) {
          // handleMetaClick(val);
          setClickedData(val);
        }
      });
    }
    return () => {};
  }, [clickedOption]);

  return (
    <>
      {!clickedData &&
        !click &&
        meta?.map((val) => {
          if (val.title.length >= 13) {
            return (
              <Large
                data={val}
                onClick={() => handleMetaClick(val)}
                name={val.title}
                key={val.id}
              />
            );
          } else if (val.title.length >= 7 && val.title.length < 13) {
            return (
              <Medium
                data={val}
                onClick={() => handleMetaClick(val)}
                name={val.title}
                key={val.id}
              />
            );
          } else {
            return (
              <Large
                data={val}
                onClick={() => handleMetaClick(val)}
                name={val.title}
                key={val.id}
              />
            );
          }
        })}
    </>
  );
};

export default PickButton;
