import styles from "./assetLibrary.module.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { deleteSave } from "../../utils/deleteSaveHistory";
import CustomAlert from "../alert/Alert";
import ShowAlert from "../../alert/showAlert";
import { setSavedModels } from "../../store/features/historySlice";
import {
  checkJobStatus,
  resizeAndDownloadModel,
} from "../../utils/opusAPI/Opus";
import {
  saveActiveJob,
  saveDownloadReadyModel,
} from "../../store/features/modelSlice";
import {
  setGeneralStatus,
  setRegenerateStatus,
} from "../../store/features/statusSlice";
import {
  setAssetLibraryDownloadingJob,
  setAssetLibraryDownloadingModel,
  setAssetLibraryDownloadingRequest,
} from "../../store/features/downloadSlice";
import { CoinSVG } from "../navbar/SVG";


const textureOptions = [
  { id: 1, title: " 1K", value: "1024" },
  { id: 2, title: " 2K ", value: "2048" },
  { id: 3, title: " 4K ", value: "4096" },
];

const Card = ({ getSavedModels, model, handleDownload }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector((state) => state.user);

  const open = Boolean(anchorEl);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const dispatch = useDispatch();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const shortenedString = (originalString) => {
    const maxLength = 16;

    const shortString =
      originalString?.length > maxLength
        ? originalString.substring(0, maxLength - 3) + ".."
        : originalString;
    return shortString;
  };
  const dateFormat = (date) => {
    const isoDate = new Date(date);
    const year = isoDate.getFullYear();
    const month = isoDate.getMonth() + 1;
    const day = isoDate.getDate();
    const hours = isoDate.getHours();
    const minutes = isoDate.getMinutes();
    const seconds = isoDate.getSeconds();

 /*    const clearDateSyntax = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")} ${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`; */
    
      const clearDateSyntax = `${day
        .toString()
        .padStart(2, "0")}/${month.toString().padStart(2, "0")}/${year} `;

    return clearDateSyntax;
  };

  const handleDelete = async () => {
    console.log(model);
    try {
      const res = await deleteSave(model, user);
      if (res.ok) {
        ShowAlert(1, "Model is deleted");
        getSavedModels();
        console.log(res);
      } else {
        ShowAlert(3, "An error is occured");
      }
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };
  const [textToCopy, setTextToCopy] = useState(model.job_id);

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy).then((text) => {
      ShowAlert(1, "Copied the text: " + textToCopy);
    });
    setAnchorEl(null);
  };

  return (
    <>
      <div className={styles.card}>
        <div className={styles.top}>
          <img className={styles.img} src={model.image} alt="Place Holder" />
          { <input
          type="checkbox"
          className={styles.checkbox}
        /> }
        </div>
        <div className={styles.bottom}>
          <div>
            <h3>{shortenedString(model.job_id)}</h3>
            <h4>{dateFormat(model.date)}</h4>
          </div>
          <div className={styles.pointer} onClick={handleClick}>
            <MoreVertIcon />
          </div>
        </div>
        <div>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleCopy}>Copy Asset ID</MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                handleDownload(model.job_id);
              }}
            >
              Download
            </MenuItem>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
          </Menu>
        </div>
      </div>
    </>
  );
};

const AssetLibrary = ({ open, onClose, isModal }) => {

  /* Add dynamic scrollbar */
  const [scrollPosition, setScrollPosition] = useState(0);  

  const onScroll = (e) => {
    const element = e.target;
    const totalScrollHeight = element.scrollHeight - element.clientHeight;
    const currentScroll = element.scrollTop;

    // Calculate scroll percentage
    const scrollPercentage = (currentScroll / totalScrollHeight);
    element.style.setProperty('--scroll-gradient-size', `${scrollPercentage * 100}%`);
  };
  /* Dynamic scroll bar ends here */

  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.user.id);
  const user = useSelector((state) => state.user);
  const [savedModelList, setSavedModelList] = useState([]);
  const assetLibraryDownloadRequest = useSelector(
    (state) => state.downloads.assetLibraryDownloadRequest
  );
  const assetLibraryDownloadingModel = useSelector(
    (state) => state.downloads.assetLibraryDownloadingModel
  );
  const assetLibraryDownloadingJob = useSelector(
    (state) => state.downloads.assetLibraryDownloadingJob
  );

  useEffect(() => {
    getSavedModels();
    return () => {};
  }, []);

  const getSavedModels = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER}/getSavedModels`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user?.session}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: user_id }),
      }
    );
    const result = await response.json();

    console.log("GET SAVED MODELS : ", result);

    if (result.state === "success") {
      dispatch(setSavedModels({ savedModels: result.list }));
      setSavedModelList(result.list);
    } else {
      console.log("NO SAVED MODELS");
    }
  };

  const downloadModelToLocal = (job_id, file_extension, downloadLink) => {
    console.log("NEW URL1 : ", downloadLink);
    fetch(downloadLink, {
      method: "GET",
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.rel = "noopener";
        a.download = `${job_id}_${file_extension}.zip`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
  };

  const prepareModelToDownload = async (jobId, state) => {
    console.log(
      "USER PREFERENCES : ",
      user?.preferences?.fileExtension,
      user?.preferences?.textureResolution
    );

    let resolution;

    switch (user?.preferences?.textureResolution) {
      case "1K":
        resolution = "1024";
        break;
      case "2K":
        resolution = "2048";
        break;
      case "4K":
        resolution = "4096";
        break;
      default:
        break;
    }

    if (state === "download_started") {
      const result = await resizeAndDownloadModel(
        jobId,
        user?.preferences?.fileExtension,
        resolution,
        user?.session
      );

      if (result?.state === "success") {
        dispatch(
          setAssetLibraryDownloadingJob({ state: result?.job?.result_id })
        );
        if (
          result?.job?.job_status === "COMPLETED" &&
          result?.job?.urls[user?.preferences?.fileExtension]
        ) {
          dispatch(setAssetLibraryDownloadingRequest({ state: "completed" }));
          dispatch();
          downloadModelToLocal(
            jobId,
            user?.preferences?.fileExtension,
            result?.job?.urls[user?.preferences?.fileExtension]
          );
          fetch(`${process.env.REACT_APP_SERVER}/addDownload`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user?.session}`,
            },
            body: JSON.stringify({
              user_id: user.id,
              job_id: jobId,
              extension: user?.preferences?.fileExtension,
              resolution: user?.preferences?.textureResolution,
            }),
          });
        } else {
          ShowAlert(1, "Preparing model to download.");
          dispatch(setAssetLibraryDownloadingRequest({ state: "downloading" }));
        }
      } else {
        dispatch(setAssetLibraryDownloadingRequest({ state: "failed" }));
        ShowAlert(3, "An error occurred during preparing model to download.");
      }
    } else if (state === "downloading") {
      const jobResult = await checkJobStatus(
        { result_id: jobId },
        user?.session
      );
      if (
        jobResult?.data?.job_status === "COMPLETED" &&
        Object.keys(jobResult?.data?.urls).length > 0
      ) {
        dispatch(setAssetLibraryDownloadingRequest({ state: "completed" }));

        console.log(
          "PREPARING_DOWNLOAD2 : ",
          jobResult?.data?.urls[user?.preferences?.fileExtension]
        );
        if (jobResult?.data?.urls[user?.preferences?.fileExtension]) {
          downloadModelToLocal(
            jobId,
            user?.preferences?.fileExtension,
            jobResult?.data?.urls[user?.preferences?.fileExtension]
          );
        } else {
          ShowAlert(3, "Failed to download model!");
          return;
        }
      }
    }
  };

  const handleDownload = async (jobId) => {
    console.log(
      user?.preferences?.fileExtension,
      user?.preferences?.textureResolution
    );
    if (!user?.preferences?.fileExtension) {
      ShowAlert(2, "Please select a file extension from settings page!");
      return;
    } else {
      if (!user?.preferences?.textureResolution) {
        ShowAlert(2, "Please select a resolution from settings page!");
        return;
      } else {
        dispatch(setAssetLibraryDownloadingModel({ state: jobId }));
        dispatch(
          setAssetLibraryDownloadingRequest({ state: "download_started" })
        );
      }
    }
  };

  useEffect(() => {
    console.log("assetLibraryDownloadRequest : ", assetLibraryDownloadRequest);
    const interval = setInterval(() => {
      if (assetLibraryDownloadRequest === "download_started") {
        prepareModelToDownload(
          assetLibraryDownloadingModel,
          "download_started"
        );
      } else if (assetLibraryDownloadRequest === "downloading") {
        prepareModelToDownload(assetLibraryDownloadingJob, "downloading");
      } else if (assetLibraryDownloadRequest === "completed") {
        clearInterval(interval);
        dispatch(setAssetLibraryDownloadingModel({ state: null }));
        dispatch(setAssetLibraryDownloadingRequest({ state: "idle" }));
        dispatch(setAssetLibraryDownloadingJob({ state: null }));
        ShowAlert(1, "The models were downloaded successfully.");
      } else if (assetLibraryDownloadRequest === "failed") {
        clearInterval(interval);
        dispatch(setAssetLibraryDownloadingJob({ state: null }));
        dispatch(setAssetLibraryDownloadingModel({ state: null }));
        dispatch(setAssetLibraryDownloadingRequest({ state: "idle" }));
        // ShowAlert(1, "The models were downloaded successfully.");
      }
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [assetLibraryDownloadRequest]);
  // const savedModels = useSelector((state) => state.history.savedModels);

  return (
    <div className={styles.assetContainer}>
      <div className={styles.title}>
        <label className={styles.myLabel}>ASSET LIBRARY</label>
        <div className={styles.procedura}>
              <CoinSVG />
              <div className={styles.circle}>{user.credits}</div>
            </div>
        {isModal && (
          <div onClick={onClose} className={styles.pointer}>
            <CloseIcon />
          </div>
        )}
      </div>
      <div className={styles.cardContainer} onScroll={onScroll}>
        {savedModelList?.map((model) => {
          console.log("SAVED MODEL : ", model);
          return (
            <Card
              handleDownload={handleDownload}
              getSavedModels={getSavedModels}
              model={model}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AssetLibrary;
