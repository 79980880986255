import persistReducer from "redux-persist/es/persistReducer";
import rootReducer from "./features";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";

const persistConfig = {
  key: "buildfy_store",
  storage,
  whitelist: [
    "user",
    "model",
    "spinner",
    "downloads",
    // "message",
    "status",
    "variationDownloads",
    "allowStatus",
    "images",
    "history",
    "settingsPage",
    "buttons"
  ],
  timeout: 100,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export const persistor = persistStore(store);

export default store;
