import HeroSection from "../../components/heroSection/HeroSection";
import Button from "../../components/heroSection/button/Button";
import { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUpdateSlice } from "../../store/features/historySlice";
import { useThree, Canvas } from "@react-three/fiber";
import { setImage } from "../../store/features/imagesSlice";

const FileDownloaderPage = () => {
  const dispatch = useDispatch();
  const downloadAllowanceViewer = useSelector(
    (state) => state.downloads.downloadAllowanceViewer
  );
  const [duration, setDuration] = useState(5);
  const [gltfUrl, setGltfUrl] = useState(null);
  const [queryParameters] = useSearchParams();
  const [modelId, setModelId] = useState();
  const [hasQueryParams, setHasQueryParams] = useState(false);
  const user = useSelector((state) => state.user);
  const modelVersions = useSelector((state) => state.model.modelVersions);
  const finalSelection = useSelector((state) => state.model.chatFinalModel);
  const [isModelReady, setIsModelReady] = useState(false);
  const image = useSelector((state) => state.images[modelId]);

  useEffect(() => {
    if (queryParameters.size > 0) {
      setModelId(queryParameters.get("model"));
      setHasQueryParams(true);
    } else {
      return;
    }
  }, [hasQueryParams, modelId, queryParameters, user]);

  const initiateDownload = useCallback(() => {
    if ((gltfUrl && downloadAllowanceViewer) ||
     user.login) {
      const downloadUrl = gltfUrl;
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "downloaded_file.zip");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [gltfUrl, downloadAllowanceViewer]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDuration((prevDuration) => {
        if (prevDuration <= 0) {
          clearInterval(interval);
          initiateDownload();
          return 0;
        } else {
          return prevDuration - 1;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [initiateDownload]);

  useEffect(() => {
    const endpoint = "https://opus.procedura.ai/get_opus_job_result";

    const poll = async () => {
      try {
        const res = await fetch(`${endpoint}?result_uid=${modelId}`);
        if (!res.ok) throw new Error(`HTTP error! status: ${res.status}`);
        const data = await res.json();
        if (data.job_status === "COMPLETED") setGltfUrl(data.urls.gltf);
        else setTimeout(poll, 3000);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    poll();
  }, [modelId]);

  /*   function ScreenShot({ modelId }) {
    const dispatch = useDispatch();
    const { gl } = useThree();
  
    const image = useSelector((state) => state.images[modelId]);
    
  
    console.log(
      "SCREENSHOT : ",
      // gl.domElement.toDataURL("image/png"),
      // typeof gl.domElement.toDataURL("image/png"),
      image,
      isModelReady
    );

    setIsModelReady(true)
  
    useEffect(() => {
      if (isModelReady && !image) {
        console.log(
          "SCREENSHOT1 : "
          // gl.domElement.toDataURL("image/png"),
          // typeof gl.domElement.toDataURL("image/png")
        );
  
        window.setTimeout(function () {
          dispatch(
            setImage({ uid: modelId, image: gl.domElement.toDataURL("image/png") })
          );
        }, 100);
      }
      return () => {};
    }, [modelId, image]);
  
    return <></>;
  } 

  useEffect(() => {
    if (queryParameters.size > 0) {
      setModelId(queryParameters.get("model"));
      setHasQueryParams(true);
      console.log("model id:", modelId, "params:", hasQueryParams);
      console.log("queryParameters", queryParameters);
    } else {
      return;
    }
  }, [hasQueryParams, modelId, queryParameters]);

  const initiateDownload = useCallback(() => {
    if (gltfUrl) {
      const downloadUrl = gltfUrl;
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "downloaded_file.zip");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [gltfUrl]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDuration((prevDuration) => {
        if (prevDuration <= 0) {
          clearInterval(interval);
          initiateDownload();
          return 0;
        } else {
          return prevDuration - 1;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [initiateDownload]);

   const saveModel = useCallback(async () => {
    
    console.log("image?", image);
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER}/addSave`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.session}`,
        },
        body: JSON.stringify({
          user_id: user.id,
          job_id: modelId,
          image: image,
        }),
      });
      const result = await response.json();

      console.log("GET SAVED MODELS : ", result);

      if (result.state === "success") {
        dispatch(setUpdateSlice({ updateSlice: true }));
      } else {
        if (result?.error === "model_already_saved") {
          console.log("Model is already saved."); // Just logging the message
        }
      }
    } catch (error) {
      console.error("Error saving model:", error);
    }
  }, [dispatch, user.id, user.session]);

 
  useEffect(() => {
    const endpoint = "https://opus.procedura.ai/get_opus_job_result";

    const poll = async () => {
      try {
        const res = await fetch(`${endpoint}?result_uid=${modelId}`);
        if (!res.ok) throw new Error(`HTTP error! status: ${res.status}`);
        const data = await res.json();
        if (data.job_status === "COMPLETED") setGltfUrl(data.urls.gltf);
        else setTimeout(poll, 3000);
        saveModel();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    poll();
  }, [modelId, saveModel]);
  */

  if (!downloadAllowanceViewer && !user.login) {
    return (
      <HeroSection
        title={`You are not authorizated to donwload`}
        paragraph={[
          "You can login or register for downloading the model",
          <br />,
        ]}
        content={<Button to={`/?model=${modelId}`} text="LOGIN TO PROCEDURA" />}
      />
    );
  }

  return (
    <HeroSection
      title={`Download starting in ${duration}...`}
      paragraph={[
        "You can log in to explore Procedura Model Generator",
        <br />,
        "and the 3D model library you have created",
        <br />,
        <a target="_blank" rel="noopener noreferrer" href={gltfUrl}>
          Click if the download has not started
        </a>,
      ]}
      content={<Button to={"/"} text="LOGIN TO PROCEDURA" />}
    />
  );
};

export default FileDownloaderPage;
