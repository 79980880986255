import style from "./modal.module.css";
import { SlArrowRight as Arrow } from "react-icons/sl";
export const ModalButton = ({ toggleModal }) => {
  const handleClick = () => {
    toggleModal();
  };
  return (
    <div
      onClick={handleClick}
      className={style.modalButtonContainer}
    >
      <Arrow color="black" />
    </div>
  );
};
