import HeroSection from "../../components/heroSection/HeroSection";
import Button from "../../components/heroSection/button/Button";
const RegisterSuccessfullPage = () => {
  return (
    <HeroSection
      title="Registration completed successfully!"
      paragraph="Please check your inbox for email verification."
      content={<Button to="/" text="Go Back" />}
    />
  );
};

export default RegisterSuccessfullPage;
