import React, { useEffect, useRef, useState } from "react";
import styles from "./OrderSummary.module.css";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import Download from "../ui/chatButtons/forDownload/Download";
import Feedback from "../feedback/Feedback";
import { useDispatch, useSelector } from "react-redux";
import {
  saveActiveJob,
  saveDownloadReadyModel,
  selectModel,
} from "../../store/features/modelSlice";
import { setUpdateSlice } from "../../store/features/historySlice";
import {
  checkJobStatus,
  resizeAndDownloadModel,
} from "../../utils/opusAPI/Opus";
import {
  setGeneralStatus,
  setIsModelDownloaded,
  setRegenerateStatus,
} from "../../store/features/statusSlice";
import CustomAlert from "../alert/Alert";
import ShowAlert from "../../alert/showAlert";
import {
  addDownloadingModels,
  clearDownloadingModels,
  setDownloadMultipleFileStatus,
} from "../../store/features/downloadSlice";
import {
  MODELS_DOWNLOADED,
  PREPARING_DOWNLOAD,
  VARIATION_GENERATION,
} from "../../utils/statusList/statusList";
import { setSpinnerText } from "../../store/features/spinnerSlice";
import { CoinSVG } from "../navbar/SVG";

function OrderSummary({ clickDownload, onBackToVideoSection }) {
  /* Add dynamic scrollbar */
  const [scrollPosition, setScrollPosition] = useState(0);

  const onScroll = (e) => {
    const element = e.target;
    const totalScrollHeight = element.scrollHeight - element.clientHeight;
    const currentScroll = element.scrollTop;

    // Calculate scroll percentage
    const scrollPercentage = currentScroll / totalScrollHeight;
    element.style.setProperty(
      "--scroll-gradient-size",
      `${scrollPercentage * 100}%`
    );
  };
  /* Dynamic scroll bar ends here */

  const [downloadClick, setDownloadClick] = useState(false);
  const [selectedFileFormat, setSelectedFileFormat] = useState(null);
  const [selectedResolution, setSelectedResolution] = useState(null);
  const [inputBasedNumber, setInputBasedNumber] = useState(0);
  const currentDate = new Date();
  const currentDateTimeString = currentDate.toLocaleString();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const [feedback, setFeedBack] = useState({});

  const getFeedBack = (val) => {
    setFeedBack(val);
  };

  const user = useSelector((state) => state.user);
  const checkBoxedModel = useSelector((state) => state.model.checkBoxedModel);
  const checkBoxedModelsVariation = useSelector(
    (state) => state.model.checkBoxedModelsVariation
  );
  const images = useSelector((state) => state.images);
  const firstModelList = useSelector((state) => state.model.firstRegeneration);
  const secondModelList = useSelector(
    (state) => state.model.secondRegeneration
  );
  const thirdModelList = useSelector((state) => state.model.thirdRegeneration);
  const variationList = useSelector((state) => state.model.variation);
  const modelVersions = useSelector((state) => state.model.modelVersions);
  const chatFinalModel = useSelector((state) => state.model.chatFinalModel);
  const selectedModelType = useSelector(
    (state) => state.model.selectedModelType
  );
  const isModelDownloaded = useSelector(
    (state) => state.status.isModelDownloaded
  );
  const regenerateStatus = useSelector(
    (state) => state.status.regenerateStatus
  );
  const downloadMultipleFileStatus = useSelector(
    (state) => state.downloads.downloadMultipleFileStatus
  );
  const downloadingModels = useSelector(
    (state) => state.downloads.downloadingModels
  );

  const [selectedModels, setSelectedModels] = useState([]);
  const downloadingModelsRef = useRef(null);

  const openModal = () => {
    setIsModalOpen(true);
    setDownloadClick(true);
  };

  useEffect(() => {
    setInputBasedNumber(0);
    if (firstModelList) {
      setInputBasedNumber((curr) => curr + 1);
    }
    if (secondModelList) {
      setInputBasedNumber((curr) => curr + 1);
    }
    if (thirdModelList) {
      setInputBasedNumber((curr) => curr + 1);
    }
    return () => {};
  }, [firstModelList, secondModelList, thirdModelList, variationList]);

  const requestResizedModel = async () => {
    let resolution;
    let fileExtension;

    console.log("USER PREFERENCES : ", user?.preferences);

    if (selectedFileFormat === null) {
      if (!user?.preferences?.fileExtension) {
        ShowAlert(2, "Please select a file extension!");
        return;
      } else {
        fileExtension = user?.preferences?.fileExtension;
      }
    } else {
      fileExtension = selectedFileFormat;
    }

    if (selectedResolution === null) {
      if (!user?.preferences?.textureResolution) {
        ShowAlert(2, "Please select a resolution!");
        return;
      } else {
        switch (user?.preferences?.textureResolution) {
          case "1K":
            resolution = "1024";
            break;
          case "2K":
            resolution = "2048";
            break;
          case "4K":
            resolution = "4096";
            break;
          default:
            break;
        }
      }
    } else {
      resolution = selectedResolution;
    }

    if (
      !checkBoxedModel?.model &&
      Object.keys(checkBoxedModelsVariation)?.length === 0
    ) {
      ShowAlert(2, "Please select a model first");
      return;
    }

    console.log("resizeAndDownloadModel : ", fileExtension, resolution);

    if (checkBoxedModel?.model) {
      const result = await resizeAndDownloadModel(
        checkBoxedModel?.model,
        fileExtension,
        resolution,
        user?.session
      );

      console.log("RESIZE RESULT : ", result);
      if (result?.state === "success") {
        fetch(`${process.env.REACT_APP_SERVER}/addDownload`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.session}`,
          },
          body: JSON.stringify({
            user_id: user.id,
            job_id: checkBoxedModel?.model,
            extension: fileExtension,
            resolution: resolution,
          }),
        });

        dispatch(saveActiveJob({ job: result?.job }));
        dispatch(saveDownloadReadyModel({ job: result?.job }));
        dispatch(setGeneralStatus({ status: PREPARING_DOWNLOAD }));
        dispatch(setRegenerateStatus({ status: PREPARING_DOWNLOAD }));
      } else {
        ShowAlert(3, "Failed to download model");
      }
    } else if (Object.keys(checkBoxedModelsVariation)?.length > 0) {
      dispatch(clearDownloadingModels());
      downloadMultipleModels(fileExtension, resolution);
    }
  };

  const getSelectedFileExtension = async (value) => {
    setSelectedFileFormat(value);
  };

  const getSelectedTexture = async (value) => {
    setSelectedResolution(value);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    console.log("Close modal");
    dispatch(selectModel({ model: null }));
  };

  useEffect(() => {
    console.log("IMAGES : ", images);

    return () => {};
  }, [images]);

  const saveModel = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER}/addSave`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.session}`,
      },
      body: JSON.stringify({
        user_id: user.id,
        job_id: checkBoxedModel?.model,
        image: images[checkBoxedModel?.model],
      }),
    });
    const result = await response.json();

    console.log("GET SAVED MODELS : ", result);

    if (result.state === "success") {
      dispatch(setUpdateSlice({ updateSlice: true }));
    } else {
      if (result?.error === "model_already_saved") {
        ShowAlert(3, "Model Already Saved");
      }
    }
  };

  const saveMultipleModels = async () => {
    if (Object.keys(checkBoxedModelsVariation).length > 0) {
      let failNo = 0;
      Object.keys(checkBoxedModelsVariation).forEach(async (version) => {
        console.log("SAVING MODEL : ", {
          user_id: user.id,
          job_id: checkBoxedModelsVariation[version]?.model?.model,
          image: images[checkBoxedModelsVariation[version]?.model?.model],
        });
        const response = await fetch(
          `${process.env.REACT_APP_SERVER}/addSave`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user?.session}`,
            },
            body: JSON.stringify({
              user_id: user.id,
              job_id: checkBoxedModelsVariation[version]?.model?.model,
              image: images[checkBoxedModelsVariation[version]?.model?.model],
            }),
          }
        );
        const result = await response.json();

        console.log("GET SAVED MODELS : ", result);

        if (result.state === "success") {
          dispatch(setUpdateSlice({ updateSlice: true }));
        } else {
          failNo++;
          if (result?.error === "model_already_saved") {
            // ShowAlert(3, "Model Already Saved");
          }
        }
      });

      if (failNo > 0) {
        ShowAlert(3, "Some of the models could not be saved successfully.");
      } else {
        ShowAlert(1, "The models were saved successfully.");
      }
    }
  };

  const downloadMultipleModels = (fileExtension, resolution) => {
    if (Object.keys(checkBoxedModelsVariation).length > 0) {
      let failNo = 0;
      Object.keys(checkBoxedModelsVariation).forEach(async (version, index) => {
        console.log("DOWNLOADING MODEL : ", {
          job_id: checkBoxedModelsVariation[version]?.model?.model,
        });
        const result = await resizeAndDownloadModel(
          checkBoxedModelsVariation[version]?.model?.model,
          fileExtension,
          resolution,
          user?.session
        );

        fetch(`${process.env.REACT_APP_SERVER}/addDownload`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.session}`,
          },
          body: JSON.stringify({
            user_id: user.id,
            job_id: checkBoxedModelsVariation[version]?.model?.model,
            extension: fileExtension,
            resolution: resolution,
          }),
        });

        console.log("RESIZE RESULT : ", result);
        if (result?.state === "success") {
          dispatch(addDownloadingModels({ state: result?.job }));
          dispatch(setDownloadMultipleFileStatus({ state: "downloading" }));
          dispatch(setUpdateSlice({ updateSlice: true }));
        } else {
          failNo++;
          dispatch(setDownloadMultipleFileStatus({ state: "failed" }));
        }
        console.log(
          "FAILNO : ",
          Object.keys(checkBoxedModelsVariation).length - 1,
          index
        );
        if (Object.keys(checkBoxedModelsVariation).length - 1 === index) {
          console.log("FAILNO : ", failNo);
          if (failNo === Object.keys(checkBoxedModelsVariation).length) {
            ShowAlert(
              3,
              "An error occurred during downloading. Please try again."
            );
          } else if (failNo > 0) {
            ShowAlert(3, "Some of the models could not download successfully.");
          } else if (failNo === 0) {
            dispatch(setGeneralStatus({ status: PREPARING_DOWNLOAD }));
            dispatch(setRegenerateStatus({ status: PREPARING_DOWNLOAD }));
            dispatch(
              setSpinnerText({
                spinnerText: `Preparing models to download.`,
              })
            );
            ShowAlert(1, "Models are preparing to download.");
          }
        }
      });
    }
  };

  const getResultOfMultipleDownloads = async () => {
    Object.keys(downloadingModelsRef.current).forEach(async (job_id) => {
      let result = await checkJobStatus(
        downloadingModelsRef.current[job_id],
        user?.session
      );
      console.log("W1O1W1", result, result?.data);
      if (downloadMultipleFileStatus !== "completed") {
        if (result?.state === "success") {
          dispatch(addDownloadingModels({ state: result?.data }));
        }
      }
    });
  };

  const checkModels = async (allModels) => {
    let completedJobNo = 0;
    Object.keys(allModels).forEach((job_id, index) => {
      if (allModels[job_id]?.job_status === "COMPLETED") {
        completedJobNo++;
      }
    });

    console.log(
      "COMPLETED JOB NO : ",
      completedJobNo,
      Object.keys(allModels).length,
      downloadMultipleFileStatus
    );

    if (
      completedJobNo === Object.keys(allModels).length &&
      Object.keys(allModels).length !== 0 &&
      downloadMultipleFileStatus !== "completed"
    ) {
      Object.keys(allModels).forEach((job_id, index) => {
        Object.keys(allModels[job_id]?.urls).forEach((url) => {
          downloadModelToLocal(job_id, url, allModels[job_id]?.urls[url]);
        });
      });
      dispatch(setDownloadMultipleFileStatus({ state: "completed" }));
      dispatch(clearDownloadingModels());
    }
  };

  useEffect(() => {
    console.log("CHATFINALMODEL : ", chatFinalModel);
    if (typeof chatFinalModel === "string") {
      setSelectedModels([chatFinalModel]);
    } else if (Array.isArray(chatFinalModel)) {
      setSelectedModels(chatFinalModel);
    } else {
    }
    return () => {};
  }, [chatFinalModel]);

  const downloadModelToLocal = (job_id, file_extension, downloadLink) => {
    console.log("NEW URL1 : ", downloadLink);
    fetch(downloadLink, {
      method: "GET",
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.rel = "noopener";
        a.download = `${job_id}_${file_extension}.zip`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
  };

  const extensionOptions = [
    { id: 1, title: "FBX", value: "fbx" },
    { id: 2, title: "GLTF", value: "gltf" },
    { id: 3, title: "USD", value: "usd" },
  ];

  const textureOptions = [
    { id: 1, title: " 1K", value: "1024" },
    { id: 2, title: " 2K ", value: "2048" },
    { id: 3, title: " 4K ", value: "4096" },
  ];

  useEffect(() => {
    console.log("FEEDBACK : ", feedback);
    return () => {};
  }, [feedback]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (downloadMultipleFileStatus === "downloading") {
        getResultOfMultipleDownloads();
      } else if (downloadMultipleFileStatus === "completed") {
        clearInterval(interval);
        dispatch(setGeneralStatus({ status: MODELS_DOWNLOADED }));
        dispatch(setRegenerateStatus({ status: MODELS_DOWNLOADED }));
        ShowAlert(1, "The models were downloaded successfully.");
      }
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [downloadMultipleFileStatus]);

  useEffect(() => {
    downloadingModelsRef.current = downloadingModels;
    checkModels(downloadingModels);
    return () => {};
  }, [downloadingModels]);

  return (
    <div>
      {downloadClick && isModalOpen && (
        <div className={styles.modalBackground}>
          <Feedback
            getFeedBack={getFeedBack}
            feedback={feedback}
            onClose={closeModal}
          />
          <button
            className={styles.proceed}
            onClick={() => {
              if (feedback?.rating !== null) {
                closeModal();
                requestResizedModel();
                dispatch(setIsModelDownloaded({ status: true }));

                fetch(`${process.env.REACT_APP_SERVER}/saveFeedback`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.session}`,
                  },
                  body: JSON.stringify({
                    user_id: user.id,
                    session: user?.session,
                    rating: feedback?.rating,
                    comment: feedback?.comment,
                  }),
                });

                // downloadModelToLocal();
              } else {
                ShowAlert(2, "Please rate your experience");
              }
            }}
          >
            <span>PROCEED</span>
          </button>
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.head}>
          <div className={styles.header}>
            <p className={styles.text}>ORDER SUMMARY</p>
          </div>
          <div className={styles.rightside}>
            <div className={styles.procedura}>
              <CoinSVG />
              <div className={styles.circle}>{user.credits}</div>
            </div>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.cont}>
            <div className={styles.bodyHeader}>
              <p>PREVIEW</p>
              {/* <div> */}
              <p>CATEGORY</p>
              <p>VARIANT</p>
              <p>ASSET ID</p>
              {/* </div> */}
            </div>
            <div className={styles.scrollableContent} onScroll={onScroll}>
              {checkBoxedModel?.model &&
                regenerateStatus !== VARIATION_GENERATION &&
                Object.keys(checkBoxedModelsVariation)?.length === 0 && (
                  <>
                    <div className={styles.item}>
                      <div className={styles.checkboxContainer}>
                        {/* <input type="checkbox" className={styles.checkbox} /> */}
                        <img
                          src={images[checkBoxedModel?.model]}
                          className={styles.pic}
                          alt={checkBoxedModel?.model}
                        />
                      </div>
                      <div className={styles.descriptionContainer}>
                        <p className={styles.itemDescription}>
                          {selectedModelType}
                        </p>
                        <p className={styles.itemDescription}>
                          {selectedModelType}
                        </p>
                        <p className={styles.itemDescription}>
                          {checkBoxedModel?.model}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              {selectedModels.length > 0 &&
                (regenerateStatus === VARIATION_GENERATION ||
                  regenerateStatus === PREPARING_DOWNLOAD ||
                  regenerateStatus === MODELS_DOWNLOADED) &&
                Object.keys(checkBoxedModelsVariation)?.length > 0 &&
                selectedModels?.map((version) => {
                  console.log(
                    "VERSION OF VARIATION : ",
                    version,
                    modelVersions,
                    modelVersions[version]
                  );
                  return (
                    <div className={styles.item}>
                      <div className={styles.checkboxContainer}>
                        {/* <input type="checkbox" className={styles.checkbox} /> */}
                        <img
                          src={images[modelVersions[version]]}
                          className={styles.pic}
                          alt={modelVersions[version]}
                        />
                      </div>
                      <div className={styles.descriptionContainer}>
                        <p className={styles.itemDescription}>
                          {selectedModelType}
                        </p>
                        <p className={styles.itemDescription}>
                          {selectedModelType}
                        </p>
                        <p className={styles.itemDescription}>
                          {modelVersions[version]}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.footerInner}>
            <div className={styles.downloadButton}>
              <Download
                getSelectedFileExtension={getSelectedFileExtension}
                getSelectedTexture={getSelectedTexture}
                value={extensionOptions}
                names="FILE FORMAT"
                type="fileFormat"
              />
              <Download
                getSelectedFileExtension={getSelectedFileExtension}
                getSelectedTexture={getSelectedTexture}
                value={textureOptions}
                names="TEXTURE RESOLUTION"
                type="resolution"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              ></div>
            </div>
            <div className={styles.sessionSum}>
              <div className={styles.sumHeader}>
                <strong>SESSION SUMMARY</strong>
              </div>
              <div className={styles.format}>
                <div className={styles.one}>
                  <p>Prompt-to-3D Asset Batch</p>
                  <div className={styles.summaryCoins}>
                    <CoinSVG className={styles.coinImage}></CoinSVG>
                    <div className={styles.circle}>{inputBasedNumber}</div>
                  </div>
                </div>
                {variationList && (
                  <div className={styles.one}>
                    <p>Variation Asset Batch</p>
                    <div className={styles.summaryCoins}>
                      <CoinSVG className={styles.coinImage}></CoinSVG>
                      <div className={styles.circle}>1</div>
                    </div>
                  </div>
                )}
                <div className={styles.one}>
                  <p>Asset Download</p>
                  <div className={styles.circle}>
                    <strong>FREE</strong>
                  </div>
                </div>
              </div>
              <div className={styles.totalCoins}>
                <p>Total:</p>
                <div className={styles.summaryCoins}>
                  <CoinSVG className={styles.coinImage}></CoinSVG>
                  <div className={styles.circle}>{inputBasedNumber + 1}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.saveDownloadButtonsContainer}>
        <div className={styles.btnContainer}>
          <button
            className={styles.regenerateButton}
            onClick={() => {
              if (!isModelDownloaded) {
                regenerateStatus !== VARIATION_GENERATION
                  ? saveModel()
                  : saveMultipleModels();
              } else {
                ShowAlert(3, "Can not save model after download.");
              }
            }}
          >
            <span>SAVE</span>
          </button>
        </div>
        <div className={styles.btnContainer}>
          <button className={styles.regenerateButton} onClick={openModal}>
            <span>DOWNLOAD</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default OrderSummary;
