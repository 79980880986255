import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ListItemIcon } from "@mui/material";
import styles from "./ProfileDropdown.module.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../store/features/userSlice";
import { useNavigate } from "react-router";
import { LibrarySVG, LogoutSVG, SettingsSVG } from "../SVG";
import {
  setIsAssetLibraryButtonClicked,
  setIsSettingsButtonClicked,
} from "../../../store/features/buttonSlice";
const ProfileDropdown = ({ handleClose, open, anchorEl }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={() => {
        handleClose();
      }}
      onClick={() => {
        handleClose();
      }}
      paper
      MenuListProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        },
      }}
      classes={{ paper: styles.dropdownMenu }}
      transformOrigin={{ horizontal: "center", vertical: "top" }}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
    >
      <div className={styles.username}>{user.name + " " + user.surname}</div>
      <MenuItem
        className={styles.menuItem}
        onClick={() => {
          dispatch(setIsAssetLibraryButtonClicked({ status: true }));
          handleClose();
        }}
      >
        <ListItemIcon>
          <LibrarySVG className={styles.icon} />
        </ListItemIcon>
        <span className={styles.menuText}>asset library</span>
      </MenuItem>
      <MenuItem
        className={styles.menuItem}
        onClick={() => {
          dispatch(setIsSettingsButtonClicked({ status: true }));
          // navigate("/settings");
          handleClose();
        }}
      >
        <ListItemIcon>
          <SettingsSVG className={styles.icon} />
        </ListItemIcon>
        <span className={styles.menuText}>settings</span>
      </MenuItem>

      <MenuItem
        className={styles.menuItem}
        onClick={() => {
          dispatch(logout());
          navigate("/");
          // localStorage.removeItem("sessionKey");
        }}
      >
        <ListItemIcon>
          <LogoutSVG className={styles.icon} />
        </ListItemIcon>
        <span className={`${styles.menuText} ${styles.logout}`}>log out</span>
      </MenuItem>
    </Menu>
  );
};

export default ProfileDropdown;
