import React, { useEffect, useState } from "react";
import Modal from "../../../../ui/chatButtons/modal/Modal";
import Medium from "../../../../ui/chatButtons/infoButtons/Medium";
import Mini from "../../../../ui/chatButtons/infoButtons/Mini";
import Picked from "../../../../ui/chatButtons/infoButtons/Picked";
import Large from "../../../../ui/chatButtons/infoButtons/Large";

const PickModal = ({
  onOptionClick,
  meta,
  onClick,
  click,
  handleShowClicked,
  data,
  chatBoxSize,
  isActive,
  index,
  clickedOption
}) => {
  const [clickedData, setClickedData] = useState(null);
  const [isInScreen, setIsInScreen] = useState(true);
  const handleMetaClick = (value) => {
    if (isActive) {
      onClick(value);
      onOptionClick(value.payload, index);
      setClickedData(value);
      handleShowClicked(true);
    }
  };

  useEffect(() => {
    if (clickedOption) {
      meta?.map((val) => {
        console.log("META MAP3 : ", val, clickedOption);
        if (val.payload === clickedOption) {
          setClickedData(val);
        }
      });
    }
    return () => {};
  }, [clickedOption]);

  const changeIsInScreen = (value) => {
    setIsInScreen(value);
  };

  return (
    <div>
      {!clickedData && !click && isInScreen && (
        <Modal changeIsInScreen={changeIsInScreen} chatBoxSize={chatBoxSize}>
          {meta?.map((val) =>
            val.title.length >= 11 ? (
              <Large
                data={val}
                onClick={() => handleMetaClick(val)}
                name={val.title}
                key={val.id}
              />
            ) : val.title.length >= 7 && val.title.length < 10 ? (
              <Medium
                data={val}
                onClick={() => handleMetaClick(val)}
                name={val.title}
                key={val.id}
              />
            ) : (
              <Mini
                data={val}
                onClick={() => handleMetaClick(val)}
                name={val.title}
                key={val.id}
              />
            )
          )}
        </Modal>
      )}
      {click && <Picked name={data.title} />}
    </div>
  );
};

export default PickModal;
