const Credit = async (user) => {
  const url = `${process.env.REACT_APP_SERVER}/editCreditUser`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${user?.session}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  });
  return response;
};
export { Credit };

const getCredits = async (user) => {
  const url = `${process.env.REACT_APP_SERVER}/getUserCredit`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${user?.session}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: user?.id }),
  });
  return response;
};

const decreaseCredits = async (user, value) => {
  const url = `${process.env.REACT_APP_SERVER}/decreaseUserCredit`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${user?.session}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: user?.id, value: value }),
  });

  return response;
};

export { getCredits, decreaseCredits };
