import { useState } from "react";
import styles from "./Register.module.css";

const RoleDropdown = () => {
  const [clicked, setClicked] = useState(false);
  const [selectedValue, setSelectedValue] = useState("ROLE (SELECT ONE)");
  const options = [
    "SOFTWARE DEVELOPER",
    "SIMULATION SPECIALIST",
    "ARTIST/CREATIVE",
    "GAME DEVELOPER",
    "PRODUCT DESIGNER",
    "ARCHITECT",
    "ENGINEER",
    "ANIMATOR/FILMMAKER",
    "EDUCATOR",
  ];

  const handleOptionClick = (option) => {
    setSelectedValue(option);
    setClicked(false);
  };

  return (
    <div className={styles.dropdownMainContainer}>
      <div className={styles.dropdownButtonContainer}>
        <button
          onClick={() => setClicked(!clicked)}
          className={styles.dropdownButton}
        >
          {selectedValue}
          <svg
            width="10"
            height="5"
            viewBox="0 0 10 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0L5 5L10 0H0Z" fill="#F3F4F8" fillOpacity="0.5608" />
          </svg>
        </button>
      </div>
      {clicked && (
        <div className={styles.optionsContainer}>
          {options.map((option, index) => (
            <div
              key={index}
              className={styles.option}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RoleDropdown;
