import "./styles/reset.css";
import Login from "../src/pages/login/Login";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Register from "./pages/register/Register";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import PasswordChanged from "./pages/passwordChanged/PasswordChanged";
import ProfilePage from "./pages/profile/ProfilePage";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store from "./store/index";
import persistStore from "redux-persist/es/persistStore";
import Settings from "./pages/settings/Settings";
import History from "./pages/history/History";
import BillingPage from "./pages/billing/BillingPage";
import AssetLibraryPage from "./pages/assetLibrary/AssetLibrary";
import NotFound from "./pages/notFound/NotFound";
import LinkExpirationPage from "./pages/linkExpirationPage/LinkExpirationPage";
import VerifyEmailPage from "./pages/verifyEmailPage/VerifyEmailPage";
import VerificationSentPage from "./pages/verificationSentPage/VerificationSentPage";
import VerificationRequestPage from "./pages/verificationRequestPage/VerificationRequestPage";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import RegisterSuccessfullPage from "./pages/registerSuccessfullPage/RegisterSuccessfullPage";
import VerifyAccountPage from "./pages/verifyAccountPage/VerifyAccountPage";
import FileDownloaderPage from "./pages/fileDownloaderPage/fileDownloaderPage";
let persistor = persistStore(store);

function App() {
  return (
    <>
      <ToastContainer style={{ zIndex: "928273690" }}></ToastContainer>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/home" element={<Home />} />
              <Route path="/register" element={<Register />} />
              <Route
                path="/register-successfull"
                element={<RegisterSuccessfullPage />}
              />
              <Route path="/settings" element={<Settings />} />
              <Route path="/billing" element={<BillingPage />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/resetPassword" element={<ResetPassword />} />
              <Route path="/password-changed" element={<PasswordChanged />} />
              <Route path="/asset-library" element={<AssetLibraryPage />} />
              <Route path="/not-found" element={<NotFound />} />
              <Route path="/link-expiration" element={<LinkExpirationPage />} />
              <Route path="/verify-email" element={<VerifyEmailPage />} />
              <Route
                path="/verification-request"
                element={<VerificationRequestPage />}
              />
              <Route
                path="/verification-sent"
                element={<VerificationSentPage />}
              />
              <Route path="/verification" element={<VerifyAccountPage />} />
              <Route path="/file-downloader" element={<FileDownloaderPage />} >
                <Route path=":model" element={<FileDownloaderPage />}/>
              </Route>
            </Routes>
          </Router>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
