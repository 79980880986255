import React, { useEffect, useState } from "react";
import style from "./AutoText.module.css";
import PickMeta from "./pickTheMeta/PickMeta";
import Container from "../../../ui/containers/container/Container";
import PickButton from "./pickButton/PickButton";
import PickModal from "./pickModal/PickModal";
const AutoText = ({
  text,
  metas,
  onOptionClick,
  key,
  chatBoxSize,
  onMessageSending,
  isActive,
  index,
  clickedOption,
  showIcon
}) => {
  const [clickedData, setClickedData] = useState(null);
  const [showClicked, setShowClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showButton, setShowButton] = useState(true);

  const clickHandler = (value) => {
    console.log("IS ACTIVE : ", isActive);
    if (isActive) {
      setClickedData(value);
      setShowButton(false);
      setShowModal(false);
      setShowClicked(true);
    }
  };

  const clickedOptionHandler = (value) => {
    setClickedData(value);
    setShowButton(false);
    setShowModal(false);
    setShowClicked(true);
  };

  useEffect(() => {
    if (onMessageSending) {
      setShowModal(false);
      setShowButton(false);
    }
  }, [onMessageSending]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleShowClicked = (val) => {
    setShowClicked(val);
  };

  useEffect(() => {
    if (clickedOption) {
      metas?.map((val) => {
        console.log("META MAP2 : ", val, clickedOption);
        if (val.payload === clickedOption) {
          clickedOptionHandler(val);
        }
      });
    }
    return () => {};
  }, [clickedOption]);


  const shouldShowModal = metas?.length > 3;
  const visibleButtons = shouldShowModal ? metas.slice(0, 3) : metas;
  return (
    <div className={style.autoTextCont}>
      {text === undefined && metas[0].image_s ? (
        <div className={style.metaCont}>
          <PickMeta
            key={key}
            onOptionClick={isActive ? onOptionClick : {}}
            onClick={clickHandler}
            meta={metas}
            isActive={isActive}
            index={index}
            clickedOption={clickedOption}
          />
        </div>
      ) : text === undefined ? (
        <Container
          metas={metas}
          showButton={showButton}
          toggleModal={toggleModal}
        >
          {!clickedData && !showClicked && (
            <PickButton
              key={key}
              onOptionClick={onOptionClick}
              onClick={clickHandler}
              meta={visibleButtons}
              handleShowClicked={handleShowClicked}
              isActive={isActive}
              index={index}
              clickedOption={clickedOption}
            />
          )}
          {shouldShowModal && showModal && !showClicked ? (
            <PickModal
              key={key}
              onOptionClick={onOptionClick}
              onClick={clickHandler}
              meta={metas.slice(2)}
              handleShowClicked={handleShowClicked}
              chatBoxSize={chatBoxSize}
              isActive={isActive}
              index={index}
              clickedOption={clickedOption}
            />
          ) : (
            !showModal &&
            showClicked && (
              <PickModal
                key={key}
                click
                data={clickedData}
                isActive={isActive}
                index={index}
                clickedOption={clickedOption}
              />
            )
          )}
        </Container>
      ) : (
        <div className={style.textCont}>
          <p className={style.textParagraph}>{text.replace(/<br>/g, "\n")}</p>
        </div>
      )}
      {showIcon && (
      <div className={style.IconCont}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.7102 19.998V11.7109H3.42383L11.7102 19.998Z"
            fill="url(#paint0_linear_263_1502)"
          />
          <path
            d="M20.0009 8.28708L11.7109 16.5744V0L20.0009 8.28708Z"
            fill="url(#paint1_linear_263_1502)"
          />
          <path
            d="M0 11.7101L11.7101 0V11.7101H0Z"
            fill="url(#paint2_linear_263_1502)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_263_1502"
              x1="7.52258"
              y1="15.8589"
              x2="11.6754"
              y2="11.7064"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FAF947" />
              <stop offset="1" stop-color="#24FB41" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_263_1502"
              x1="11.7325"
              y1="8.29914"
              x2="19.9901"
              y2="8.29914"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#007990" />
              <stop offset="1" stop-color="#78FFD5" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_263_1502"
              x1="0"
              y1="5.87305"
              x2="11.7101"
              y2="5.87305"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#007990" />
              <stop offset="1" stop-color="#78FFD5" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      )}
    </div>
  );
};
export default AutoText;
