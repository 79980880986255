import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  page: "settings",
  showCreditModal: false,
};

const settingsPageSlice = createSlice({
  name: "settingsPage",
  initialState,
  reducers: {
    setSettingsPage: (state, action) => {
      state.page = action.payload.page;
    },
    setShowCreditModal: (state, action) => {
      state.showCreditModal = action.payload.showCreditModal;
    },
  },
});

export const { setSettingsPage, setShowCreditModal } =
  settingsPageSlice.actions;

export default settingsPageSlice.reducer;
