import axios from "axios";
const uploadImage = async (formdata) => {
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_SERVER}/api/users/upload`,
      formdata
    );
    if (resp.data.message === "success") {
      return resp;
    } else {
      console.error("Error");
    }
  } catch (err) {
    console.error("Something went wrong:", err);

    if (err.response) {
      console.error("Error response:", err.response.data);
      console.error("Response status:", err.response.status);
    }
  }
};

export default uploadImage;
