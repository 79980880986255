const deleteSave = async (model, user) => {
  const url = `${process.env.REACT_APP_SERVER}/deleteSave`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user?.session}`,
    },
    body: JSON.stringify(model),
  });
  return response;
};
export { deleteSave };
