import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  checkStatus: false,
};

const allowStatusSlice = createSlice({
  name: "allowStatus",
  initialState,
  reducers: {
    setCheckStatus: (state, action) => {
      state.checkStatus = action.payload.status;
    },
  },
});

export const { setCheckStatus } = allowStatusSlice.actions;

export default allowStatusSlice.reducer;
