import { useDispatch, useSelector } from "react-redux";
import Container from "./container/Container";
import style from "./profile.module.css";
import ContainerSave from "../save/ContainerSave";
import { useState, useEffect } from "react";
import { edit } from "../../../store/features/userSlice";
import { postEdit } from "../../../utils/edit";
import PictureContainer from "../picture/PictureContainer";

const Profile = ({ modal }) => {
  const userCredentials = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    name: "",
    surname: "",
    email: "",
    id: userCredentials.id,
    company: "",
    occupation: "",
    website: "",
    country: "",
    city: "",
  });

  useEffect(() => {
    setUser(userCredentials);
  }, [userCredentials]);

  const handleSave = async (e) => {
    e.preventDefault();
    if (!user.name && !user.surname && !user.email) {
      console.log("name ve email bos birakilamaz");
    } else {
      dispatch(edit(user));
      const response = await postEdit(user, userCredentials?.session);
      console.log("RESPONSE : ", response);
      const result = await response.json();
      if (response.ok) {
        console.log(result);
        // window.location.reload();
      } else {
        console.error("fail");
      }
    }
  };

  const handleCancel = (e) => {
    setUser(userCredentials);
  };

  return (
    <div className={style.profileContainer}>
      <div className={style.allContainers}>
        <div className={style.inputContainer}>
          <Container
            value={userCredentials.name + " " + userCredentials.surname}
            text="Full Name"
            setUser={(newName) => {
              const [name, surname] = newName.split(" ");
              if (newName.split(" ").length < 2) {
                console.log("you must enter name and surname");
              } else {
                setUser({ ...user, name: name, surname: surname });
              }
            }}
          />
          <Container
            value={userCredentials.email}
            text={"E-Mail"}
            setUser={(newEmail) => setUser({ ...user, email: newEmail })}
          />
          <Container
            value={userCredentials.company}
            text="Company"
            setUser={(newEmail) => setUser({ ...user, company: newEmail })}
          />
          <Container
            value={userCredentials.occupation}
            text="Occupation"
            setUser={(newEmail) => setUser({ ...user, occupation: newEmail })}
          />
          <Container
            value={userCredentials.website}
            text={"Website"}
            setUser={(newEmail) => setUser({ ...user, website: newEmail })}
          />
          <Container
            value={userCredentials.country}
            text={"Country"}
            setUser={(newEmail) => setUser({ ...user, country: newEmail })}
          />
          <Container
            value={userCredentials.city}
            text={"City"}
            setUser={(newEmail) => setUser({ ...user, city: newEmail })}
          />
        </div>
        <PictureContainer />
      </div>
      {modal ? (
        <div className={style.modalContainer}>
          <ContainerSave
            onCancelClick={handleCancel}
            onSaveClick={handleSave}
            modal
          />
        </div>
      ) : (
        <div className={style.saveContainer}>
          <ContainerSave
            onCancelClick={handleCancel}
            onSaveClick={handleSave}
          />
        </div>
      )}
    </div>
  );
};

export default Profile;
