import style from "./ChatButtons.module.css";
const Medium = ({ name, onClick, data }) => {
  const handleClick = () => {
    onClick(data);
  };
  return (
    <button
      onClick={handleClick}
      className={style.Medium}
    >
      {name}
    </button>
  );
};

export default Medium;
