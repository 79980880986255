import React, { useEffect } from "react";
import AssetLibrary from "../../components/assetLibrary/assetLibrary";
import Navbar from "../../components/navbar/Navbar";
import { checkJWT } from "../../utils/session/Session";
import { useNavigate } from "react-router";
import { logout } from "../../store/features/userSlice";
import { useDispatch } from "react-redux";
const AssetLibraryPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let isAuth = checkJWT();
    if (isAuth !== "valid") {
      dispatch(logout());
      navigate("/");
      localStorage.removeItem("sessionKey");
    }
    return () => {};
  }, []);

  return (
    <>
      <Navbar />
      <section
        style={{ maxWidth: 1200, margin: "0 auto", padding: "1rem 2rem" }}
      >
        <AssetLibrary />
      </section>
    </>
  );
};

export default AssetLibraryPage;
