import style from "./picture.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import uploadImage from "../../../utils/uploadImage";
import { editPicture } from "../../../store/features/userSlice";
import ShowAlert from "../../../alert/showAlert";
import ClipLoader from "react-spinners/ClipLoader";
import { imgTypes } from "../../../utils/imageTypes";
import axios from "axios";

const PictureContainer = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [inputKey, setInputKey] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const userCredentials = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleImageUpload = async (file) => {
    setIsUploading(true);
    try {
      const formdata = new FormData();
      formdata.append("file", file);
      formdata.append("id", userCredentials.id);
      const result = await uploadImage(formdata);

      if (result) {
        if (
          result.data.image !== "" &&
          result.data.image !== " " &&
          result.data.image !== null
        ) {
          try {
            const resp = await axios.post(
              `${process.env.REACT_APP_SERVER}/api/users/getImage`,
              {
                user_id: userCredentials.id,
              },
              { responseType: "arraybuffer" }
            );
            console.log("DATAresp : ", resp);
            if (resp.status === 200) {
              let userImg = Buffer.from(resp.data, "binary").toString("base64");
              dispatch(
                editPicture({
                  image: userImg,
                })
              );
            } else {
              console.log("no_image");
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          ShowAlert(3, "Couldn't get the picture. Please try again later.");
        }
      }
    } catch (error) {
      console.error("Error uploading picture:", error);
    } finally {
      setIsUploading(false);
      setInputValue("");
    }
  };
  console.log(userCredentials.image);

  return (
    <>
      <div className={style.profilePic}>
        <img
          src={
            userCredentials?.image
              ? userCredentials?.image.includes("googleusercontent")
                ? userCredentials?.image
                : `data:image/${
                    imgTypes[userCredentials?.image.slice(0, 1)]
                  };base64,` + userCredentials?.image
              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
          }
          id="profilePic"
          alt="Profile"
        />
        {isUploading && (
          <ClipLoader color="green" className={style.profilePicLoader} />
        )}
        <label htmlFor="inputFile" className={style.profileLabel}>
          Change your picture
        </label>

        <input
          className={style.inputFile}
          type="file"
          accept="image/jpeg, image/png, image/jpg, image/jfif"
          id="inputFile"
          key={inputKey}
          value={inputValue}
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              handleImageUpload(file);
              setInputKey((prevKey) => prevKey + 1);
              setInputValue("");
            }
          }}
        />
      </div>
    </>
  );
};

export default PictureContainer;
