const verifyAccount = async (id) => {
  const url = `${process.env.REACT_APP_SERVER}/verifyAccount`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: id }),
  });
  return response;
};

export { verifyAccount };
