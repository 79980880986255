import React, { useEffect } from "react";
import my from "./PickMeta.module.css";
import MetaMini from "../../../../ui/chatButtons/meta/MetaMini";
import MetaLarge from "../../../../ui/chatButtons/meta/MetaLarge";
import { useState } from "react";
const PickMeta = ({
  onOptionClick,
  meta,
  onClick,
  key,
  isActive,
  index,
  clickedOption,
}) => {
  const [clickedData, setClickedData] = useState(null);

  const handleMetaClick = (value) => {
    console.log("HANDLE META CLICK");
    if (isActive) {
      onClick(value);
      onOptionClick(
        value.payload !== undefined ? value.payload : value.title,
        index
      );
      setClickedData(value);
    }
  };

  useEffect(() => {
    if (clickedOption) {
      meta?.map((val) => {
        console.log("META MAP4 : ", val);
        if (val.payload === clickedOption) {
          setClickedData(val);
        }
      });
    }
    return () => {};
  }, [clickedOption]);

  return (
    <div className={my.MetaCont}>
      {!clickedData &&
        meta?.map((val) => {
          console.log("VAL : ", val);
          return (
            <MetaMini
              data={val}
              onClick={handleMetaClick}
              name={val.title.toString()}
              src={val.image_l}
              key={key}
              clickedOption={clickedOption}
            />
          );
        })}
      {clickedData && (
        <MetaLarge
          name={clickedData.title.toString()}
          src={clickedData.image_l}
          clickedOption={clickedOption}
        />
      )}
    </div>
  );
};

export default PickMeta;
