import style from "./Meta.module.css";
const MetaLarge = ({ src, name }) => {
  return (
    <div className={style.metaButtonsLarge}>
      <img
        className={style.metaImgLarge}
        src={src}
        alt=""
      />
      <p>{name} Selected</p>
    </div>
  );
};

export default MetaLarge;
