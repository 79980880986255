import HeroSection from "../../components/heroSection/HeroSection";
import Button from "../../components/heroSection/button/Button";
const PasswordChanged = () => {
  return (
    <HeroSection
      title="Your password has been changed."
      paragraph={[
        "You can log in to your account with your new",
        <br />,
        "password now.",
      ]}
      content={<Button to="/" text="Go Back" />}
    />
  );
};

export default PasswordChanged;
