import React, { useEffect, useRef, useState } from "react";
import styles from "./VideoSection.module.css";
import { Alert, AlertTitle } from "@mui/material";
import CustomAlert from "../alert/Alert";
import { MdReplay as ReplayIcon } from "react-icons/md";
import CarouselSlider from "./carouselSlider/CarouselSlider";
import {
  setRegenerateAttemptNo,
  saveActiveJob,
  saveFirstJob,
  saveSecondJob,
  saveThirdJob,
  saveVariation,
  selectModel,
  setModelVersions,
  setCheckBoxedModel,
  addSelectedJobToVariates,
} from "../../store/features/modelSlice";
import { useDispatch, useSelector } from "react-redux";
import { checkJobStatus, createVariations } from "../../utils/opusAPI/Opus";
import axios from "axios";
import jszip from "jszip";
import {
  setGenerateProgress,
  setSpinnerText,
  setSpinnerVisibleState,
} from "../../store/features/spinnerSlice";
import {
  addCurrentDownload,
  addNewDownload,
  clearDownloads,
  increaseDownloadedFileNumber,
  setDownloadRequest,
} from "../../store/features/downloadSlice";
import OrderSummary from "../orderSummary/OrderSummary";
import ImageSpinner from "../imageSpinner/ImageSpinner";
import {
  setGeneralStatus,
  setIsFirstModelReady,
  setIsOrderSummaryOpen,
  setIsSecondModelReady,
  setIsStartedOver,
  setIsThirdModelReady,
  setIsVariationsReady,
  setRegenerateStatus,
} from "../../store/features/statusSlice";
import {
  addNewVariationDownload,
  increaseDownloadedVariationFileNumber,
} from "../../store/features/variationDownloadSlice";
import { setCheckStatus } from "../../store/features/allowStatusSlice";
import {
  editCredit,
  increaseSpendedCredits,
} from "../../store/features/userSlice";
import CreditFetchers from "../fetcher/CreditFetchers";
import { trusted } from "mongoose";
import {
  setSavedModels,
  setUpdateSlice,
} from "../../store/features/historySlice";
import localforage from "localforage";
import ShowAlert from "../../alert/showAlert";
import {
  getUserSessionProgress,
  saveSessionProgress,
} from "../../utils/session/Session";
import { ClipLoader } from "react-spinners";
import {
  setIsCheckBoxesShown,
  setIsProceedButtonShown,
  setProceedButtonFlag,
} from "../../store/features/buttonSlice";
import { setIsInputBlocked, user } from "../../store/features/messageSlice";
import loadingLogo from "../../images/Procedura_App_Waiting_Anim.gif";
import {
  FIRST_GENERATION,
  FIRST_DOWNLOAD,
  FIRST_COMPLETE,
  SECOND_GENERATION,
  SECOND_DOWNLOAD,
  SECOND_COMPLETE,
  THIRD_GENERATION,
  THIRD_DOWNLOAD,
  THIRD_COMPLETE,
  VARIATION_GENERATION,
  VARIATION_DOWNLOAD,
  VARIATION_COMPLETE,
  PREPARING_DOWNLOAD,
  MODELS_DOWNLOADED,
} from "../../utils/statusList/statusList";
import { decreaseCredits } from "../../utils/credit/Credit";

function Spinner() {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#292E34");

  const spinnerText = useSelector((state) => state.spinner.spinnerText);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <p className={styles.spinner}>{spinnerText}</p>
      {/* <div className="sweet-loading">
        <ClipLoader
          color={color}
          loading={loading}
          size={30}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div> */}
    </div>
  );
}

const CheckStatusManager = ({}) => {
  const dispatch = useDispatch();
  const [numberOfTry, setNumberOfTry] = useState(0);
  const checkBoxedModel = useSelector((state) => state.model.checkBoxedModel);
  const regenerateStatus = useSelector(
    (state) => state.status.regenerateStatus
  );

  const user = useSelector((state) => state.user);
  const job = useSelector((state) => state.model.activeJob);

  useEffect(() => {
    console.log("CHECK STATUS JOB : ", job);

    return () => {};
  }, [job]);

  const increaseNumberOfTry = () => {
    //TESTING
    setNumberOfTry((curr) => curr + 1);
  };

  useEffect(() => {
    checkStatus(job);

    return () => {};
  }, [numberOfTry]);

  const downloadModelToLocal = (job_id, file_extension, downloadLink) => {
    console.log("NEW URL1 : ", downloadLink);
    fetch(downloadLink, {
      method: "GET",
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.rel = "noopener";
        a.download = `${job_id}_${file_extension}.zip`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
  };

  const checkStatus = async (job) => {
    console.log("JOB : ", job);

    let result = await checkJobStatus(job, user?.session);
    console.log("resultDATA : ", result.data);
    dispatch(saveActiveJob({ job: result.data }));
    console.log("RES : ", result);
    console.log(
      "PREPARING_DOWNLOAD : ",
      regenerateStatus,
      result?.data?.job_status
    );
    if (
      regenerateStatus === PREPARING_DOWNLOAD &&
      result?.data?.job_status === "COMPLETED"
    ) {
      console.log("PREPARING_DOWNLOAD1 : ", result?.data?.urls);
      Object.keys(result?.data?.urls).forEach((url) => {
        console.log("PREPARING_DOWNLOAD2 : ", result?.data?.urls[url]);
        downloadModelToLocal(job?.result_id, url, result?.data?.urls[url]);
      });
      dispatch(setGeneralStatus({ status: MODELS_DOWNLOADED }));
      dispatch(setRegenerateStatus({ status: MODELS_DOWNLOADED }));
    }

    if (
      result.state === "success" &&
      result?.data?.overall_status === "COMPLETED"
    ) {
      dispatch(setIsInputBlocked({ status: false }));
      console.log("NUMBER OF TRIES : ", numberOfTry);

      switch (regenerateStatus) {
        case FIRST_GENERATION:
          dispatch(
            saveFirstJob({
              job: result.data,
            })
          );
          dispatch(setDownloadRequest({ status: "first" }));
          break;
        case SECOND_GENERATION:
          dispatch(
            saveSecondJob({
              job: result.data,
            })
          );
          dispatch(setDownloadRequest({ status: "second" }));
          break;
        case THIRD_GENERATION:
          dispatch(
            saveThirdJob({
              job: result.data,
            })
          );
          dispatch(setDownloadRequest({ status: "third" }));
          break;
        case VARIATION_GENERATION:
          console.log("VARIATION DOWNLOADING");
          dispatch(
            saveVariation({
              job: result.data,
            })
          );
          dispatch(setDownloadRequest({ status: "variation" }));
          break;
        default:
          break;
      }
    }

    let progressNo = 0;

    if (job?.results) {
      console.log("JOB_RESULTS : ", job.results);
      job.results.forEach((result) => {
        if (result.job_status === "COMPLETED") {
          console.log("JOB_STATUS : ", result.job_status);
          progressNo = progressNo + 1;
        }
      });

      dispatch(
        setGenerateProgress({
          generateProgress: `${progressNo}/${job?.results?.length}`,
        })
      );
    }

    return;
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      console.log("INTERVAL JOB : ", job);
      if (job?.overall_status === "COMPLETED") {
        console.log("LETSSGO");
      } else {
        increaseNumberOfTry();
      }
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return <></>;
};

const DownloadManager = ({ downloads, variationDownloads }) => {
  const dispatch = useDispatch();

  const regenerateStatus = useSelector(
    (state) => state.status.regenerateStatus
  );
  const firstRegeneration = useSelector(
    (state) => state.model.firstRegeneration
  );

  const checkDownloads = (downloads) => {
    // console.log("DOWNLOADS : ", downloads);
    let downloadCompleteNo = 0;
    Object.keys(downloads).forEach((download) => {
      // console.log(downloads[download].downloadStatus);
      if (downloads[download]?.downloadStatus === "complete") {
        downloadCompleteNo += 1;
      }
    });
    console.log("downloadCompleteNo : ", downloadCompleteNo, regenerateStatus);

    if (downloadCompleteNo === 4 && regenerateStatus === FIRST_GENERATION) {
      dispatch(setIsFirstModelReady({ status: true }));
      dispatch(setGeneralStatus({ status: FIRST_COMPLETE }));
      localStorage.setItem(
        "firstRegeneration",
        JSON.stringify(firstRegeneration)
      );
      localStorage.setItem("downloadStatus", JSON.stringify(downloads));

      // console.log("MODEL IS READY", downloadCompleteNo);
    } else if (
      downloadCompleteNo === 8 &&
      regenerateStatus === SECOND_GENERATION
    ) {
      dispatch(setGeneralStatus({ status: SECOND_COMPLETE }));
      dispatch(setIsSecondModelReady({ status: true }));

      localStorage.setItem("downloadStatus", JSON.stringify(downloads));
    } else if (
      downloadCompleteNo === 12 &&
      regenerateStatus === THIRD_GENERATION
    ) {
      dispatch(setIsThirdModelReady({ status: true }));
      dispatch(setGeneralStatus({ status: THIRD_COMPLETE }));
      localStorage.setItem("downloadStatus", JSON.stringify(downloads));
    }
  };

  const checkVariationDownload = (downloads) => {
    // console.log("VAR DOWNLOADS : ", downloads);
    let downloadCompleteNo = 0;
    Object.keys(downloads).forEach((download) => {
      console.log(downloads[download]?.downloadStatus);
      if (downloads[download]?.downloadStatus === "complete") {
        downloadCompleteNo += 1;
      }
    });
    // console.log("downloadCompleteNo : ", downloadCompleteNo);

    if (downloadCompleteNo === 3 && regenerateStatus === VARIATION_GENERATION) {
      dispatch(setGeneralStatus({ status: VARIATION_COMPLETE }));
      dispatch(setIsVariationsReady({ status: true }));
      console.log("setIsProceedButtonShown", true);
      localStorage.setItem("downloadStatus", JSON.stringify(downloads));
      // console.log("MODEL IS READY", downloadCompleteNo);
    }
  };

  checkDownloads(downloads);
  checkVariationDownload(variationDownloads);
  return <></>;
};

const VideoSection = ({ sessionKey }) => {
  const job = useSelector((state) => state.model.activeJob);
  const checkBoxedModel = useSelector((state) => state.model.checkBoxedModel);
  const downloads = useSelector((state) => state.downloads);
  const variationDownloads = useSelector((state) => state.variationDownloads);
  const generalStatus = useSelector((state) => state.status.generalStatus);
  const regenerateStatus = useSelector(
    (state) => state.status.regenerateStatus
  );
  const isFirstModelReady = useSelector(
    (state) => state.status.isFirstModelReady
  );
  const isSecondModelReady = useSelector(
    (state) => state.status.isSecondModelReady
  );
  const isThirdModelReady = useSelector(
    (state) => state.status.isThirdModelReady
  );
  const isVariationsReady = useSelector(
    (state) => state.status.isVariationsReady
  );

  const generateProgress = useSelector(
    (state) => state.spinner.generateProgress
  );
  const user = useSelector((state) => state.user);
  const firstJob = useSelector((state) => state.model.firstRegeneration);
  const secondJob = useSelector((state) => state.model.secondRegeneration);
  const thirdJob = useSelector((state) => state.model.thirdRegeneration);
  const variationJob = useSelector((state) => state.model.variation);
  const user_id = useSelector((state) => state.user.id);
  const historyUpdate = useSelector((state) => state.history.updateSlice);
  const modelVersions = useSelector((state) => state.model.modelVersions);
  const chatInitialModel = useSelector((state) => state.model.chatInitialModel);
  const chatFinalModel = useSelector((state) => state.model.chatFinalModel);
  const showSpinner = useSelector((state) => state.spinner.isVisible);
  const isOrderSummaryOpen = useSelector(
    (state) => state.status.isOrderSummaryOpen
  );
  const session = useSelector((state) => state.user.session);
  const proceedButtonFlag = useSelector(
    (state) => state.buttons.proceedButtonFlag
  );
  const isStartedOver = useSelector((state) => state.status.isStartedOver);

  const dispatch = useDispatch();

  const [model1, setModel1] = useState({});
  const [model2, setModel2] = useState({});
  const [model3, setModel3] = useState({});
  const [variations, setVariations] = useState({});
  const [downloadList, setDownloadList] = useState([]);
  const [isVariationRequested, setIsVariationRequested] = useState(false);
  const [isVariationCompleted, setIsVariationCompleted] = useState(false);
  const [allowCheckState, setAllowCheckStatus] = useState(false);
  const [isReadyModelsDownloaded, setIsReadyModelsDownloaded] = useState(false);

  const changeRegenerateStatus = (status) => {
    dispatch(setRegenerateStatus({ status: status }));
  };

  const changeGeneralStatus = (status) => {
    dispatch(setGeneralStatus({ status: status }));
  };

  useEffect(() => {
    console.log("VARIATION1 MODELS : ", variations);
    return () => {};
  }, [variations]);

  const saveSession = async () => {
    let token = localStorage.getItem("sessionKey");
    let stateCode = "";
    stateCode = isFirstModelReady ? stateCode + "f" : stateCode;
    stateCode = isSecondModelReady ? stateCode + "s" : stateCode;
    stateCode = isThirdModelReady ? stateCode + "t" : stateCode;
    stateCode = isVariationsReady ? stateCode + "v" : stateCode;

    const result = await saveSessionProgress(token, modelVersions, stateCode);
    const payload = await result.json();
    if (payload.state === "success") {
      console.log("CURRENT SESSION STATE : ", payload.session, stateCode);
    }
  };

  useEffect(() => {
    localforage.clear();
    setModel1({});
    setModel2({});
    setModel3({});
    setVariations({});
    dispatch(setIsStartedOver({ status: false }));
    return () => {};
  }, [session, isStartedOver]);

  const checkGetReadyModels = useRef(false);

  useEffect(() => {
    console.log(
      "RESTARTED isReadyModelsDownloaded : ",
      isReadyModelsDownloaded
    );
    if (!isReadyModelsDownloaded) {
      console.log("RESTARTED");
      getReadyModels(downloadList);
    }
    return () => {};
  }, [downloadList, isReadyModelsDownloaded]);

  useEffect(() => {
    console.log("SPINNER TEXT : ", regenerateStatus);
    if (
      regenerateStatus !== PREPARING_DOWNLOAD &&
      regenerateStatus !== MODELS_DOWNLOADED
    ) {
      dispatch(
        setSpinnerText({ spinnerText: `Generating... ${generateProgress}` })
      );
    }
    return () => {};
  }, [generateProgress, regenerateStatus]);

  useEffect(() => {
    console.log("USE EFFECT : ", job, downloadList);
    if (job?.overall_status === "COMPLETED") {
      downloadModels(job, downloadList, regenerateStatus);
    }
    return () => {};
  }, [job?.overall_status, downloadList]);

  useEffect(() => {
    console.log("AMODEL1 : ", model1);
    let batchIds = Object.keys(model1);
    if (batchIds.length === 0) return;

    if (
      !modelVersions["V1.0"] ||
      !modelVersions["V2.0"] ||
      !modelVersions["V3.0"] ||
      !modelVersions["V4.0"]
    ) {
      dispatch(
        setModelVersions({
          modelVersions: {
            "V1.0": batchIds[0],
            "V2.0": batchIds[1],
            "V3.0": batchIds[2],
            "V4.0": batchIds[3],
          },
        })
      );
    }
    addSelectedModelToVariations();
    return () => {};
  }, [model1]);

  useEffect(() => {
    console.log("MODEL2 : ", model2);
    let batchIds = Object.keys(model2);
    if (batchIds.length === 0) return;
    if (
      !modelVersions["V5.0"] ||
      !modelVersions["V6.0"] ||
      !modelVersions["V7.0"] ||
      !modelVersions["V8.0"]
    ) {
      dispatch(
        setModelVersions({
          modelVersions: {
            "V5.0": batchIds[0],
            "V6.0": batchIds[1],
            "V7.0": batchIds[2],
            "V8.0": batchIds[3],
          },
        })
      );
    }
    addSelectedModelToVariations();
    return () => {};
  }, [model2]);

  useEffect(() => {
    console.log("MODEL3 : ", model3);
    let batchIds = Object.keys(model3);

    if (batchIds.length === 0) return;

    if (
      !modelVersions["V9.0"] ||
      !modelVersions["V10.0"] ||
      !modelVersions["V11.0"] ||
      !modelVersions["V12.0"]
    ) {
      dispatch(
        setModelVersions({
          modelVersions: {
            "V9.0": batchIds[0],
            "V10.0": batchIds[1],
            "V11.0": batchIds[2],
            "V12.0": batchIds[3],
          },
        })
      );
    }
    addSelectedModelToVariations();
    return () => {};
  }, [model3]);

  useEffect(() => {
    console.log("VARIATION MODELSS : ", variations);
    let batchIds = Object.keys(variations);
    if (batchIds.length === 0) return;

    let selectedModel;

    if (chatInitialModel) {
      selectedModel = chatInitialModel;
      console.log("SELECTED MODEL VAR: ", selectedModel);
    } else if (checkBoxedModel?.model) {
      let selectedModelIndex = Object.values(modelVersions).indexOf(
        checkBoxedModel?.model
      );
      selectedModel = Object.keys(modelVersions)[selectedModelIndex];
    } else {
      // ShowAlert(3, "Please select a model");
      return;
    }

    if (
      !modelVersions[`V${selectedModel.split(".")[0].replace("V", "")}.1`] ||
      !modelVersions[`V${selectedModel.split(".")[0].replace("V", "")}.2`] ||
      !modelVersions[`V${selectedModel.split(".")[0].replace("V", "")}.3`]
    ) {
      dispatch(
        setModelVersions({
          modelVersions: {
            [`V${selectedModel.split(".")[0].replace("V", "")}.1`]: batchIds[1],
            [`V${selectedModel.split(".")[0].replace("V", "")}.2`]: batchIds[2],
            [`V${selectedModel.split(".")[0].replace("V", "")}.3`]: batchIds[3],
          },
        })
      );
    }
    return () => {};
  }, [variations]);

  useEffect(() => {
    if (
      isFirstModelReady ||
      isSecondModelReady ||
      isThirdModelReady ||
      isVariationCompleted
    ) {
      saveSession();
    }
    return () => {};
  }, [
    isFirstModelReady,
    isSecondModelReady,
    isThirdModelReady,
    isVariationsReady,
  ]);

  useEffect(() => {
    console.log("GENERAL STATUS : ", generalStatus);

    switch (generalStatus) {
      case "not_started":
        break;
      case FIRST_GENERATION:
        dispatch(setSpinnerVisibleState({ isVisible: true }));
        console.log("START JOB : FIRST");
        // dispatch(setCheckStatus({ status: true }));
        setAllowCheckStatus(true);
        startJob(generalStatus);

        break;
      case FIRST_DOWNLOAD:
        dispatch(setSpinnerVisibleState({ isVisible: true }));
        dispatch(setSpinnerText({ spinnerText: `Downloading...` }));
        // setAllowCheckStatus(true);
        break;
      case FIRST_COMPLETE:
        dispatch(setSpinnerVisibleState({ isVisible: false }));
        setAllowCheckStatus(false);
        if (chatInitialModel === null) {
          dispatch(setIsProceedButtonShown({ status: true }));
        }
        break;
      case SECOND_GENERATION:
        dispatch(setSpinnerVisibleState({ isVisible: true }));
        // dispatch(setCheckStatus({ status: true }));
        setAllowCheckStatus(true);
        break;
      case SECOND_DOWNLOAD:
        dispatch(setSpinnerVisibleState({ isVisible: true }));
        dispatch(setSpinnerText({ spinnerText: `Downloading...` }));
        // setAllowCheckStatus(true);
        break;
      case SECOND_COMPLETE:
        dispatch(setSpinnerVisibleState({ isVisible: false }));
        setAllowCheckStatus(false);
        if (chatInitialModel === null) {
          dispatch(setIsProceedButtonShown({ status: true }));
        }
        break;
      case THIRD_GENERATION:
        dispatch(setSpinnerVisibleState({ isVisible: true }));
        // dispatch(setCheckStatus({ status: true }));
        setAllowCheckStatus(true);
        break;
      case THIRD_DOWNLOAD:
        dispatch(setSpinnerVisibleState({ isVisible: true }));
        dispatch(setSpinnerText({ spinnerText: `Downloading...` }));
        // setAllowCheckStatus(true);
        break;
      case THIRD_COMPLETE:
        dispatch(setSpinnerVisibleState({ isVisible: false }));
        setAllowCheckStatus(false);
        if (chatInitialModel === null) {
          dispatch(setIsProceedButtonShown({ status: true }));
        }
        break;
      case VARIATION_GENERATION:
        dispatch(setSpinnerVisibleState({ isVisible: true }));
        dispatch(setSpinnerText({ spinnerText: `Generating...` }));
        setIsVariationRequested(true);
        setAllowCheckStatus(true);
        dispatch(setIsCheckBoxesShown({ status: false }));

        addSelectedModelToVariations();
        break;
      case VARIATION_DOWNLOAD:
        dispatch(setSpinnerVisibleState({ isVisible: true }));
        dispatch(setSpinnerText({ spinnerText: `Downloading...` }));
        break;
      case VARIATION_COMPLETE:
        dispatch(setSpinnerVisibleState({ isVisible: false }));
        setAllowCheckStatus(false);
        dispatch(setCheckBoxedModel({ model: null }));
        dispatch(selectModel({ model: null }));
        dispatch(setIsProceedButtonShown({ status: true }));
        if (!chatFinalModel) {
          dispatch(setIsCheckBoxesShown({ status: true }));
        }
        break;
      case PREPARING_DOWNLOAD:
        dispatch(setSpinnerVisibleState({ isVisible: true }));
        dispatch(
          setSpinnerText({ spinnerText: `Preparing model to download.` })
        );
        dispatch(setIsCheckBoxesShown({ status: false }));

        dispatch(setIsProceedButtonShown({ status: false }));
        setAllowCheckStatus(true);
        break;
      case MODELS_DOWNLOADED:
        dispatch(setSpinnerVisibleState({ isVisible: false }));
        dispatch(setIsCheckBoxesShown({ status: false }));

        setAllowCheckStatus(false);
        break;
      default:
        break;
    }

    return () => {};
  }, [generalStatus]);

  useEffect(() => {
    getSavedModels();
    dispatch(setUpdateSlice({ updateSlice: false }));
    return () => {};
  }, [historyUpdate]);

  useEffect(() => {
    if (proceedButtonFlag) {
      if (
        regenerateStatus !== VARIATION_GENERATION &&
        regenerateStatus !== VARIATION_COMPLETE &&
        regenerateStatus !== THIRD_GENERATION &&
        regenerateStatus !== THIRD_COMPLETE &&
        !checkBoxedModel?.version
      ) {
        generateActiveRegenerate();
      }
    }

    dispatch(setProceedButtonFlag({ status: false }));

    return () => {};
  }, [proceedButtonFlag]);

  const getReadyModels = async (downloadList) => {
    console.log("getReadyModels1 : ", downloadList);
    setIsReadyModelsDownloaded(true);
    // dispatch(clearDownloads());

    if (firstJob?.overall_status === "COMPLETED") {
      firstJob?.results?.forEach(async (job) => {
        if (downloadList.includes(job?.result_id)) {
          console.log("Already downloaded123 : ", downloadList[job?.result_id]);
          return;
        } else {
          console.log("getReadyModels1 FIRST");
          console.log("DOWNLOAD JOB ELEMENT : ", job);
          setDownloadList((curr) => [...curr, job?.result_id]);
          await downloadReadyModels(
            job?.urls?.gltf,
            job?.result_id,
            "firstJob"
          );
        }
      });
    }

    if (secondJob?.overall_status === "COMPLETED") {
      secondJob?.results?.forEach(async (job) => {
        if (downloadList.includes(job?.result_id)) {
          console.log("Already downloaded123 : ", downloadList[job?.result_id]);
          return;
        } else {
          console.log("getReadyModels1 SECOND");
          console.log("DOWNLOAD JOB ELEMENT : ", job);
          setDownloadList((curr) => [...curr, job?.result_id]);
          await downloadReadyModels(
            job?.urls?.gltf,
            job?.result_id,
            "secondJob"
          );
        }
      });
    }

    if (thirdJob?.overall_status === "COMPLETED") {
      thirdJob?.results?.forEach(async (job) => {
        if (downloadList.includes(job?.result_id)) {
          console.log("Already downloaded123 : ", downloadList[job?.result_id]);
          return;
        } else {
          console.log("getReadyModels1 THIRD");
          console.log("DOWNLOAD JOB ELEMENT : ", job);
          setDownloadList((curr) => [...curr, job?.result_id]);
          await downloadReadyModels(
            job?.urls?.gltf,
            job?.result_id,
            "thirdJob"
          );
        }
      });
    }

    if (variationJob?.overall_status === "COMPLETED") {
      Object.keys(variationJob?.results).forEach(async (key) => {
        if (downloadList.includes(variationJob?.results[key]?.result_id)) {
          console.log(
            "Already downloaded : ",
            downloadList[variationJob?.results[key]?.result_id]
          );
          return;
        } else {
          console.log("getReadyModels1 VAR");
          console.log("DOWNLOAD JOB ELEMENT : ", variationJob?.results[key]);
          setDownloadList((curr) => [
            ...curr,
            variationJob?.results[key]?.result_id,
          ]);
          await downloadReadyModels(
            variationJob?.results[key]?.urls?.gltf,
            variationJob?.results[key]?.result_id,
            "variations"
          );
        }
      });
      Object.keys(variationJob?.results).forEach(async (key) => {});
    }
  };

  const addSelectedModelToVariations = async () => {
    if (chatInitialModel === null) {
      // ShowAlert(
      //   3,
      //   "Something went wrong during model selection. Please try again."
      // );
      console.log(
        "Something went wrong during model selection. Please try again."
      );
      return;
    }
    if (chatInitialModel.split(".")[0].replace("V", "")) {
      if (
        parseInt(chatInitialModel.split(".")[0].replace("V", "")) >= 1 &&
        parseInt(chatInitialModel.split(".")[0].replace("V", "")) <= 4
      ) {
        console.log(
          "ADDSELECTED : ",
          firstJob?.results[
            (parseInt(chatInitialModel.split(".")[0].replace("V", "")) - 1) % 4
          ],
          (parseInt(chatInitialModel.split(".")[0].replace("V", "")) - 1) % 4
        );

        dispatch(
          addSelectedJobToVariates({
            job: firstJob?.results[
              (parseInt(chatInitialModel.split(".")[0].replace("V", "")) - 1) %
                4
            ],
          })
        );
        console.log("PARSE INT1 : ", model1, modelVersions[chatInitialModel]);
        if (
          !modelVersions[chatInitialModel] ||
          !model1[modelVersions[chatInitialModel]]
        ) {
          return;
        } else {
          setVariations((curr) => {
            return {
              ...curr,
              [modelVersions[chatInitialModel]]:
                model1[modelVersions[chatInitialModel]],
            };
          });
        }
      } else if (
        parseInt(chatInitialModel.split(".")[0].replace("V", "")) >= 5 &&
        parseInt(chatInitialModel.split(".")[0].replace("V", "")) <= 8
      ) {
        console.log("PARSE INT2 : ", model2, modelVersions[chatInitialModel]);
        if (
          !modelVersions[chatInitialModel] ||
          !model2[modelVersions[chatInitialModel]]
        ) {
          return;
        }
        dispatch(
          addSelectedJobToVariates({
            job: {
              job: secondJob?.results[
                (parseInt(chatInitialModel.split(".")[0].replace("V", "")) -
                  1) %
                  4
              ],
            },
          })
        );
        setVariations((curr) => {
          return {
            ...curr,
            [modelVersions[chatInitialModel]]:
              model2[modelVersions[chatInitialModel]],
          };
        });
      } else if (
        parseInt(chatInitialModel.split(".")[0].replace("V", "")) >= 9 &&
        parseInt(chatInitialModel.split(".")[0].replace("V", "")) <= 12
      ) {
        console.log("PARSE INT3 : ", model3, modelVersions[chatInitialModel]);
        if (
          !modelVersions[chatInitialModel] ||
          !model3[modelVersions[chatInitialModel]]
        ) {
          return;
        }
        dispatch(
          addSelectedJobToVariates({
            job: {
              job: thirdJob?.results[
                (parseInt(chatInitialModel.split(".")[0].replace("V", "")) -
                  1) %
                  4
              ],
            },
          })
        );
        setVariations((curr) => {
          return {
            ...curr,
            [modelVersions[chatInitialModel]]:
              model3[modelVersions[chatInitialModel]],
          };
        });
      }
    }
  };

  const downloadReadyModels = async (url, uid, job) => {
    const config = {
      method: "get",
      responseType: "blob",
      crossdomain: true,
      params: { uid: uid },
    };

    console.log("DOWNLOAD UID : ", uid, url);
    setAllowCheckStatus(false);
    axios
      .get(`${url}`, config)

      .then(function (response) {
        console.log("download started");
        console.log("RESPONSE : ", response.status);

        if (response.status === 200) {
          return Promise.resolve(response.data);
        } else {
          console.log("ERROR : ", response.status);
          ShowAlert(3, "Download Error!");
          // alert("DOWNLOAD ERROR!");
          return Promise.reject(new Error(response.statusText));
        }
      })
      .then(jszip.loadAsync) // 3) chain with the zip promise
      .then(function (zip) {
        console.log("ZIP : ", zip, regenerateStatus, "wow");
        console.log("DOWNLOADREGENERATESTATUS : ", regenerateStatus);

        if (job === "variations") {
          dispatch(
            addNewVariationDownload({
              uid: uid,
              numberOfFiles: Object.keys(zip.files).length,
              jobType: regenerateStatus,
            })
          );
        } else {
          dispatch(
            addNewDownload({
              uid: uid,
              numberOfFiles: Object.keys(zip.files).length,
              jobType: regenerateStatus,
            })
          );
        }

        let newModelFile = {};
        Object.keys(zip.files).forEach(function (filename) {
          console.log("FILENAME : ", filename);
          zip.files[filename].async("blob").then(async function (fileData) {
            if (
              filename.includes("gltf") ||
              filename.includes("png") ||
              filename.includes("bin") ||
              filename.includes("jpg") ||
              filename.includes("usdz")
            ) {
              switch (job) {
                case "firstJob":
                  newModelFile = {
                    [uid]: {
                      ...newModelFile[uid],
                      isVariation: false,

                      [filename.replace("textures/", "")]: fileData,
                    },
                  };
                  console.log(
                    "NEWMODELFILE: ",
                    newModelFile[uid],
                    zip.files,
                    Object.keys(newModelFile[uid]).length,
                    Object.keys(zip.files).length + 1
                  );
                  if (
                    Object.keys(newModelFile[uid]).length ===
                    Object.keys(zip.files).length + 1
                  ) {
                    console.log("NEWMODELFILE1: ", newModelFile);
                    setModel1((curr) => {
                      return { ...curr, ...newModelFile };
                    });
                  }
                  break;
                case "secondJob":
                  newModelFile = {
                    [uid]: {
                      ...newModelFile[uid],
                      isVariation: false,

                      [filename.replace("textures/", "")]: fileData,
                    },
                  };
                  console.log(
                    "NEWMODELFILE: ",
                    newModelFile[uid],
                    zip.files,
                    Object.keys(newModelFile[uid]).length,
                    Object.keys(zip.files).length + 1
                  );
                  if (
                    Object.keys(newModelFile[uid]).length ===
                    Object.keys(zip.files).length + 1
                  ) {
                    console.log("NEWMODELFILE1: ", newModelFile);
                    setModel2((curr) => {
                      return { ...curr, ...newModelFile };
                    });
                  }
                  break;
                case "thirdJob":
                  newModelFile = {
                    [uid]: {
                      ...newModelFile[uid],
                      isVariation: false,

                      [filename.replace("textures/", "")]: fileData,
                    },
                  };
                  console.log(
                    "NEWMODELFILE: ",
                    newModelFile[uid],
                    zip.files,
                    Object.keys(newModelFile[uid]).length,
                    Object.keys(zip.files).length + 1
                  );
                  if (
                    Object.keys(newModelFile[uid]).length ===
                    Object.keys(zip.files).length + 1
                  ) {
                    console.log("NEWMODELFILE1: ", newModelFile);
                    setModel3((curr) => {
                      return { ...curr, ...newModelFile };
                    });
                  }
                  break;
                case "variations":
                  newModelFile = {
                    [uid]: {
                      ...newModelFile[uid],
                      isVariation: false,

                      [filename.replace("textures/", "")]: fileData,
                    },
                  };
                  console.log(
                    "NEWMODELFILE3: ",
                    newModelFile[uid],
                    zip.files,
                    Object.keys(newModelFile[uid]).length,
                    Object.keys(zip.files).length + 1
                  );
                  if (
                    Object.keys(newModelFile[uid]).length ===
                    Object.keys(zip.files).length + 1
                  ) {
                    console.log("NEWMODELFILE1: ", newModelFile);
                    setVariations((curr) => {
                      return { ...curr, ...newModelFile };
                    });
                  }
                  break;
                default:
                  break;
              }

              if (job === variations) {
                dispatch(increaseDownloadedVariationFileNumber({ uid: uid }));
              } else {
                dispatch(increaseDownloadedFileNumber({ uid: uid }));
              }
            }
          });
        });
      })
      .catch(function (err) {
        console.error(err);
      });
  };

  const downloadRequest = async (url, uid) => {
    const config = {
      method: "get",
      responseType: "blob",
      crossdomain: true,
      params: { uid: uid },
    };

    console.log("DOWNLOAD UID : ", uid, url);
    setAllowCheckStatus(false);
    axios
      .get(`${url}`, config)

      .then(function (response) {
        console.log("download started");
        console.log("RESPONSE : ", response.status);

        if (response.status === 200) {
          return Promise.resolve(response.data);
        } else {
          console.log("ERROR : ", response.status);
          ShowAlert(3, "Download Error!");
          // alert("DOWNLOAD ERROR!");
          return Promise.reject(new Error(response.statusText));
        }
      })
      .then(jszip.loadAsync) // 3) chain with the zip promise
      .then(function (zip) {
        console.log("ZIP : ", zip, regenerateStatus, "wow");
        console.log("DOWNLOADREGENERATESTATUS : ", regenerateStatus);
        switch (regenerateStatus) {
          case FIRST_GENERATION:
            dispatch(
              addNewDownload({
                uid: uid,
                numberOfFiles: Object.keys(zip.files).length,
                jobType: regenerateStatus,
              })
            );
            break;
          case SECOND_GENERATION:
            dispatch(
              addNewDownload({
                uid: uid,
                numberOfFiles: Object.keys(zip.files).length,
                jobType: regenerateStatus,
              })
            );
            break;
          case THIRD_GENERATION:
            dispatch(
              addNewDownload({
                uid: uid,
                numberOfFiles: Object.keys(zip.files).length,
                jobType: regenerateStatus,
              })
            );
            break;
          case VARIATION_GENERATION:
            dispatch(
              addNewVariationDownload({
                uid: uid,
                numberOfFiles: Object.keys(zip.files).length,
                jobType: regenerateStatus,
              })
            );
            break;
          default:
            console.log("LALALALAND");
            break;
        }

        let newModelFile = {};
        Object.keys(zip.files).forEach(function (filename, index) {
          console.log("FILENAME : ", filename);

          zip.files[filename].async("blob").then(async function (fileData) {
            if (
              filename.includes("gltf") ||
              filename.includes("png") ||
              filename.includes("bin") ||
              filename.includes("jpg") ||
              filename.includes("usdz")
            ) {
              switch (regenerateStatus) {
                case FIRST_GENERATION:
                  newModelFile = {
                    [uid]: {
                      ...newModelFile[uid],
                      isVariation: false,

                      [filename.replace("textures/", "")]: fileData,
                    },
                  };
                  console.log(
                    "NEWMODELFILE: ",
                    newModelFile[uid],
                    zip.files,
                    Object.keys(newModelFile[uid]).length,
                    Object.keys(zip.files).length + 1
                  );
                  if (
                    Object.keys(newModelFile[uid]).length ===
                    Object.keys(zip.files).length + 1
                  ) {
                    console.log("NEWMODELFILE1: ", newModelFile);
                    setModel1((curr) => {
                      return { ...curr, ...newModelFile };
                    });
                  }

                  break;
                case SECOND_GENERATION:
                  newModelFile = {
                    [uid]: {
                      ...newModelFile[uid],
                      isVariation: false,

                      [filename.replace("textures/", "")]: fileData,
                    },
                  };
                  console.log(
                    "NEWMODELFILE: ",
                    newModelFile[uid],
                    zip.files,
                    Object.keys(newModelFile[uid]).length,
                    Object.keys(zip.files).length + 1
                  );
                  if (
                    Object.keys(newModelFile[uid]).length ===
                    Object.keys(zip.files).length + 1
                  ) {
                    console.log("NEWMODELFILE1: ", newModelFile);
                    setModel2((curr) => {
                      return { ...curr, ...newModelFile };
                    });
                  }

                  break;
                case THIRD_GENERATION:
                  newModelFile = {
                    [uid]: {
                      ...newModelFile[uid],
                      isVariation: false,

                      [filename.replace("textures/", "")]: fileData,
                    },
                  };
                  console.log(
                    "NEWMODELFILE: ",
                    newModelFile[uid],
                    zip.files,
                    Object.keys(newModelFile[uid]).length,
                    Object.keys(zip.files).length + 1
                  );
                  if (
                    Object.keys(newModelFile[uid]).length ===
                    Object.keys(zip.files).length + 1
                  ) {
                    console.log("NEWMODELFILE1: ", newModelFile);
                    setModel3((curr) => {
                      return { ...curr, ...newModelFile };
                    });
                  }

                  break;
                case VARIATION_GENERATION:
                  newModelFile = {
                    [uid]: {
                      ...newModelFile[uid],
                      isVariation: true,

                      [filename.replace("textures/", "")]: fileData,
                    },
                  };
                  console.log(
                    "NEWMODELFILEv123: ",
                    newModelFile[uid],
                    zip.files,
                    Object.keys(newModelFile[uid]).length,
                    Object.keys(zip.files).length + 1
                  );
                  if (
                    Object.keys(newModelFile[uid]).length ===
                    Object.keys(zip.files).length + 1
                  ) {
                    console.log("NEWMODELFILE1: ", newModelFile);
                    setVariations((curr) => {
                      return { ...curr, ...newModelFile };
                    });
                  }
                  break;
                default:
                  break;
              }

              if (regenerateStatus === VARIATION_GENERATION) {
                dispatch(increaseDownloadedVariationFileNumber({ uid: uid }));
              } else {
                dispatch(increaseDownloadedFileNumber({ uid: uid }));
              }
            }
          });
        });
      })
      .catch(function (err) {
        console.error(err);
      });
  };

  const downloadModels = async (job, downloadList, regenerateStatus) => {
    console.log("DOWNLOAD JOB : ", job);
    console.log("WOWSSS");
    switch (regenerateStatus) {
      case FIRST_GENERATION:
        changeGeneralStatus(FIRST_DOWNLOAD);
        break;
      case SECOND_GENERATION:
        changeGeneralStatus(SECOND_DOWNLOAD);
        break;
      case THIRD_GENERATION:
        changeGeneralStatus(THIRD_DOWNLOAD);
        break;
      case VARIATION_GENERATION:
        changeGeneralStatus(VARIATION_DOWNLOAD);
        break;
      default:
        break;
    }

    job.results.forEach(async (element) => {
      console.log(
        "DOWNLOAD JOB ELEMENT : ",
        element,
        job,
        downloadList,
        regenerateStatus
      );
      if (downloadList.includes(element.result_id)) {
        console.log("Already downloaded : ", downloadList[element.result_id]);
        return;
      } else {
        console.log("DOWNLOAD JOB ELEMENT : ", element);
        setDownloadList((curr) => [...curr, element.result_id]);
        await downloadRequest(element?.urls?.gltf, element.result_id);
      }
    });
  };

  const generateActiveRegenerate = async () => {
    if (generalStatus === FIRST_COMPLETE) {
      const creditRes = await decreaseCredits(user, 1);
      const creditResult = await creditRes.json();

      if (creditResult.state === "success") {
        dispatch(editCredit({ credits: creditResult.credits }));
        dispatch(increaseSpendedCredits());
        ShowAlert(1, "Batch generated.");
        ShowAlert(1, "One credit decreased.");
        startJobVariation();
      } else {
        if (creditResult.message === "not_enough_credits") {
          ShowAlert(3, "Not enough credits");
        } else {
          ShowAlert(3, "An error occurred! Please try again.");
        }
        return;
      }
    } else if (generalStatus === SECOND_COMPLETE) {
      const creditRes = await decreaseCredits(user, 1);
      const creditResult = await creditRes.json();
      if (creditResult.state === "success") {
        dispatch(editCredit({ credits: creditResult.credits }));
        dispatch(increaseSpendedCredits());
        ShowAlert(1, "Batch generated.");
        ShowAlert(1, "One credit decreased.");
        startJobVariation();
      } else {
        if (creditResult.message === "not_enough_credits") {
          ShowAlert(3, "Not enough credits");
        } else {
          ShowAlert(3, "An error occurred! Please try again.");
        }
        return;
      }
    } else if (generalStatus === THIRD_COMPLETE) {
      ShowAlert(3, "You have no longer the right to regenerate.");
    } else {
      ShowAlert(2, "Please create a job first");
      return;
    }
  };

  const startJob = async (generalStatus) => {
    if (regenerateStatus === "not_started") {
      changeGeneralStatus(FIRST_GENERATION);
      changeRegenerateStatus(FIRST_GENERATION);

      console.log("CHANGE REGENERATE STATUS : FIRST ");
    } else if (
      regenerateStatus === FIRST_GENERATION &&
      generalStatus === FIRST_COMPLETE
    ) {
      changeGeneralStatus(SECOND_GENERATION);
      changeRegenerateStatus(SECOND_GENERATION);
    } else if (
      regenerateStatus === SECOND_GENERATION &&
      generalStatus === SECOND_COMPLETE
    ) {
      changeGeneralStatus(THIRD_GENERATION);
      changeRegenerateStatus(THIRD_GENERATION);
    } else {
      console.log("CHANGE REGENERATE FAIL : ");
    }
  };

  const startJobVariation = async () => {
    let jobObj;

    if (!firstJob?.batch_job_id) {
      let firstJob = localStorage.getItem("firstRegeneration");
      try {
        jobObj = JSON.parse(firstJob);
        console.log("JOBOBJ : ", jobObj);
      } catch (e) {
        ShowAlert(3, "Can't find first job!");
        return false;
      }
      console.log("FIRST JOB : ", firstJob);
      dispatch(saveFirstJob({ job: firstJob }));
    }

    console.log(
      "createVariations : ",
      firstJob?.batch_job_id,
      jobObj?.batch_job_id
    );
    let result = await createVariations(
      firstJob?.batch_job_id ? firstJob?.batch_job_id : jobObj?.batch_job_id,
      session
    );
    console.log("ACTIVE BATCH : ", result, regenerateStatus);

    if (result.state === "success") {
      dispatch(saveActiveJob({ job: result.job }));

      switch (regenerateStatus) {
        case "not_started":
          dispatch(
            saveFirstJob({
              job: result.job,
            })
          );
          changeGeneralStatus(FIRST_GENERATION);
          changeRegenerateStatus(FIRST_GENERATION);
          break;
        case FIRST_GENERATION:
          dispatch(
            saveSecondJob({
              job: result.job,
            })
          );
          changeGeneralStatus(SECOND_GENERATION);
          changeRegenerateStatus(SECOND_GENERATION);
          dispatch(setRegenerateAttemptNo({ attemptNo: 1 }));
          break;
        case SECOND_GENERATION:
          dispatch(
            saveThirdJob({
              job: result.job,
            })
          );
          changeGeneralStatus(THIRD_GENERATION);
          changeRegenerateStatus(THIRD_GENERATION);
          dispatch(setRegenerateAttemptNo({ attemptNo: 2 }));
          break;
        default:
          break;
      }

      console.log("START RESULT : ", result);
    } else {
      ShowAlert(3, "An error occurred during regeneration!");
    }
  };

  const getSavedModels = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER}/getSavedModels`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session}`,
        },
        body: JSON.stringify({ user_id: user_id }),
      }
    );
    const result = await response.json();

    console.log("GET SAVED MODELS : ", result);

    if (result.state === "success") {
      dispatch(setSavedModels({ savedModels: result.list }));
    } else {
      console.log("NO SAVED MODELS");
    }
  };

  useEffect(() => {
    console.log("DENEME123 : ", downloads, variationDownloads);
    return () => {};
  }, [downloads, variationDownloads]);

  return (
    <>
      {allowCheckState && (
        <CheckStatusManager
          key={job?.batch_job_id}
          job={job}
        ></CheckStatusManager>
      )}
      {generalStatus !== VARIATION_COMPLETE && (
        <DownloadManager
          downloads={downloads}
          variationDownloads={variationDownloads}
        ></DownloadManager>
      )}
      {showSpinner ? <Spinner></Spinner> : null}
      {isFirstModelReady &&
      generalStatus !== VARIATION_GENERATION &&
      generalStatus !== VARIATION_DOWNLOAD ? (
        <div className={styles.videoSection}>
          {isOrderSummaryOpen ? (
            <OrderSummary
              onBackToVideoSection={() => {
                dispatch(setIsOrderSummaryOpen({ status: false }));
              }}
            />
          ) : (
            <>
              <CarouselSlider
                model1={model1}
                model2={model2}
                model3={model3}
                variations={variations}
                isVariationRequested={isVariationRequested}
                isVariationCompleted={isVariationCompleted}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  width: "100%",
                  paddingBottom: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    width: "95%",
                  }}
                >
                  <div>
                    <div className={styles.buttonContainer}></div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: "row",
                      width: "25%",
                    }}
                  ></div>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {generalStatus === FIRST_GENERATION ||
          generalStatus === FIRST_DOWNLOAD ||
          generalStatus === VARIATION_GENERATION ||
          generalStatus === VARIATION_DOWNLOAD ? (
            <img
              style={{ height: "15vh" }}
              src={loadingLogo}
              alt="Loading..."
            />
          ) : (
            <ImageSpinner rotating={false}></ImageSpinner>
          )}
          {generalStatus === "not_started" && (
            <p style={{ marginTop: "20px", color: "#b0b0b0" }}>
              Start a conversation with the chatbot to generate models.
            </p>
          )}
        </div>
      )}
      {/* <CreditFetchers /> */}
    </>
  );
};

export default VideoSection;
