const FIRST_GENERATION = "FIRST_GENERATION";
const FIRST_DOWNLOAD = "FIRST_DOWNLOADING";
const FIRST_COMPLETE = "FIRST_COMPLETED";

const SECOND_GENERATION = "SECOND_GENERATION";
const SECOND_DOWNLOAD = "SECOND_DOWNLOADING";
const SECOND_COMPLETE = "SECOND_COMPLETED";

const THIRD_GENERATION = "THIRD_GENERATION";
const THIRD_DOWNLOAD = "THIRD_DOWNLOAD";
const THIRD_COMPLETE = "THIRD_COMPLETE";

const VARIATION_GENERATION = "VARIATION_GENERATION";
const VARIATION_DOWNLOAD = "VARIATION_DOWNLOAD";
const VARIATION_COMPLETE = "VARIATION_COMPLETE";

const PREPARING_DOWNLOAD = "PREPARING_DOWNLOAD";
const MODELS_DOWNLOADED = "MODELS_DOWNLOADED";

export {
  FIRST_COMPLETE,
  FIRST_DOWNLOAD,
  FIRST_GENERATION,
  SECOND_COMPLETE,
  SECOND_DOWNLOAD,
  SECOND_GENERATION,
  THIRD_COMPLETE,
  THIRD_DOWNLOAD,
  THIRD_GENERATION,
  VARIATION_COMPLETE,
  VARIATION_DOWNLOAD,
  VARIATION_GENERATION,
  PREPARING_DOWNLOAD,
  MODELS_DOWNLOADED,
};
