const postEdit = async (user, session) => {
  const url = `${process.env.REACT_APP_SERVER}/api/users/edit`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${session}`,
    },
    body: JSON.stringify(user),
  });
  return response;
};
export { postEdit };
