import { useSelector } from "react-redux";
import my from "./UserMessage.module.css";
import { imgTypes } from "../../../../utils/imageTypes";
const UserMessage = ({ message }) => {
  const userCredentials = useSelector((state) => state.user);
  return (
    <div className={my.All}>
      <div className={my.userMessageContainer}>
        <p>{message}</p>
      </div>
      <div className={my.IconCont}>
        {
          <img
            src={
              userCredentials?.image
                ? userCredentials?.image.includes("googleusercontent")
                  ? userCredentials?.image
                  : `data:image/${
                      imgTypes[userCredentials?.image.slice(0, 1)]
                    };base64,` + userCredentials?.image
                : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
            }
            alt="profile"
            className={my.icon}
          />
        }
      </div>
    </div>
  );
};

export default UserMessage;
