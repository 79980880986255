import HeroSection from "../../components/heroSection/HeroSection";
import Button from "../../components/heroSection/button/Button";
const VerifyEmailPage = () => {
  return (
    <HeroSection
      title="You need to verify your email first."
      paragraph={[
        "If you haven't received the verification email or",
        <br />,
        "if it has expired, you can request a new one.",
      ]}
      content={<Button text="request a new link" />}
    />
  );
};

export default VerifyEmailPage;
