import styles from "./save.module.css";
const ContainerSave = ({ onSaveClick, onCancelClick, modal }) => {
  return (
    <div className={styles.ContainerSave}>
      <button
        onClick={onSaveClick}
        className={modal ? styles.modalSaveButton : styles.saveButton}
      >
        SAVE CHANGES
      </button>
      {/* {!modal && (
        <button
          onClick={onCancelClick}
          className={styles.cancelButton}
        >
          CANCEL
        </button>
      )} */}
    </div>
  );
};

export default ContainerSave;
