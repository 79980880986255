import Container from "../profile/container/Container";
import style from "./security.module.css";
import ContainerSave from "../save/ContainerSave";
import { useState } from "react";
import { postEdit } from "../../../utils/edit";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Alert, AlertTitle } from "@mui/material";
import ShowAlert from "../../../alert/showAlert";
const Security = ({ modal }) => {
  const [showPasswordAlert, setShowPasswordAlert] = useState(false);
  const user = useSelector((state) => state.user);
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const id = useSelector((state) => state.user.id);

  const checkPassword = (pass) => {
    if (
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(pass) &&
      pass.length >= 8
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSave = async () => {
    if (password.newPassword !== password.confirmPassword) {
      // alert("Passwords do not match");
      ShowAlert(3, "Passwords do not match");
      return;
    } else if (!checkPassword(password.newPassword)) {
      ShowAlert(
        3,
        `Invalid password! Your password must be at least 8 characters long
      and include at least one uppercase letter, one lowercase letter, and
      one number for security purposes.`
      );
      return;
    } else {
      const response = await postEdit(
        {
          password: password.oldPassword,
          newPassword: password.newPassword,
          id: id,
        },
        user?.session
      );
      if (response.ok) {
        console.log("success");
        ShowAlert(1, `Passwords updated successfully.`);
        // alert("Passwords updated successfully.");
        // navigate("/");
      } else {
        let result = await response.json();
        if (result?.state === "fail") {
          if (result?.message === "Invalid old password.") {
            ShowAlert(3, `Invalid current password.`);
          } else {
            ShowAlert(3, `An error occurred.`);
          }
        } else {
          ShowAlert(3, `An error occurred.`);
        }
        // console.log("Cannot post");
      }
    }
  };
  const handleCancel = (e) => {
    // window.location.reload();
  };
  return (
    <div>
      <Container
        setPassword={(oldPassword) =>
          setPassword({ ...password, oldPassword: oldPassword })
        }
        type="password"
        text={"Current Password"}
      />
      <Container
        setPassword={(oldPassword) =>
          setPassword({ ...password, newPassword: oldPassword })
        }
        type="password"
        text={"New Password"}
      />
      <Container
        setPassword={(oldPassword) =>
          setPassword({ ...password, confirmPassword: oldPassword })
        }
        type="password"
        text={"Confirm Password"}
      />
      {modal ? (
        <div className={style.modalContainer}>
          <ContainerSave
            onCancelClick={handleCancel}
            onSaveClick={handleSave}
            modal
          />
        </div>
      ) : (
        <div className={style.saveContainer}>
          <ContainerSave
            onCancelClick={handleCancel}
            onSaveClick={handleSave}
          />
        </div>
      )}
    </div>
  );
};

export default Security;
