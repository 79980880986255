import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ClipLoader from "react-spinners/ClipLoader";
import styles from "./Navbar.module.css";

import { RxTriangleDown } from "react-icons/rx";

import ProfileDropdown from "./profileDropdown/ProfileDropdown";
import { CoinSVG, InfoSVG } from "./SVG";
import { imgTypes } from "../../utils/imageTypes";

function Spinner() {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#292E34");

  const spinnerText = useSelector((state) => state.spinner.spinnerText);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <p style={{ marginRight: "10px" }}>{spinnerText}</p>
      <div className="sweet-loading">
        <ClipLoader
          color={color}
          loading={loading}
          size={30}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </div>
  );
}

function Navbar({modelId}) {
  // const showSpinner = useSelector((state) => state.spinner.isVisible);
  // {showSpinner ? <Spinner></Spinner> : null}

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const user = useSelector((state) => state.user);

  const borderColor = open ? "#78FFD5" : "#fff";

  return (
    <header className={styles.header}>
      <nav className={styles.navbar}>
        
        <Link to={modelId ? `/?model=${modelId}` : '/home'}>
            <img className={styles.logo} src="/logo.svg" alt="logo" />
        </Link>

        {user.login ? (
          <div className={styles.container}>
            <InfoSVG className={styles.infoIcon} />

            <div className={styles.substract}>
              <CoinSVG className={styles.coinIcon} />
              <span className={styles.coin}>{user.credits}</span>
              <div onClick={handleClick} className={styles.profileAvatar}>
                <img
                  alt="Profile"
                  src={
                    user?.image
                      ? user?.image.includes("googleusercontent")
                        ? user?.image
                        : `data:image/${
                            imgTypes[user?.image.slice(0, 1)]
                          };base64,` + user?.image
                      : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                  }
                  style={{ borderColor }}
                />
              </div>
            </div>

            <RxTriangleDown
              size={20}
              color={open ? "#78FFD5" : "#F3F4F8"}
              onClick={handleClick}
              className={styles.triangleIcon}
            />
            <ProfileDropdown
              handleClose={handleClose}
              open={open}
              anchorEl={anchorEl}
            />
          </div>
        ) : (
          <Link to={modelId ? `/?model=${modelId}` : '/'} className={styles.loginButton}>
            Log In
          </Link>
        )}
      </nav>
    </header>
  );
}

export default Navbar;
