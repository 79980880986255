import styles from "./imageSpinnerStyles.module.css";

const ImageSpinner = ({ rotating }) => {
  return (
    <div className={rotating ? styles.containerRotate : styles.container}>
      <img
        className={styles.image}
        src={require("../../images/logo.png")}
        alt=""
      />
    </div>
  );
};

export default ImageSpinner;
