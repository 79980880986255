import React, { useState, useEffect, useRef } from "react";
import style from "./ChatBox.module.css";
import SendMessage from "./chat/sendMessage/SendMessage";
import { SlOptions as Options } from "react-icons/sl";
import UserMessage from "./chat/userMessages/UserMessage";
import AutoText from "./chat/chatBotAutoText/AutoText";
import { socket } from "../../socket/clientConfig";
import { useDispatch, useSelector } from "react-redux";
import {
  addCheckBoxedModelsVariation,
  saveActiveJob,
  saveFirstJob,
  saveVariation,
  selectModel,
  setChatFinalModel,
  setChatInitialModel,
  setChatSecondModel,
  setCheckBoxedModelsVariation,
  setSelectedModelType,
  setselectedModelType,
} from "../../store/features/modelSlice";
import {
  setSpinnerText,
  setSpinnerVisibleState,
} from "../../store/features/spinnerSlice";
import {
  allMessage,
  setBatchId,
  setIsFirstModelSelectedFromChat,
  setIsHeyMessageSent,
  setIsInputBlocked,
  user,
} from "../../store/features/messageSlice";
import {
  setGeneralStatus,
  setIsOrderSummaryOpen,
  setIsStartedOver,
  setRegenerateStatus,
} from "../../store/features/statusSlice";
import localforage from "localforage";
import { setUpdateSlice } from "../../store/features/historySlice";
import ShowAlert from "../../alert/showAlert";
import {
  setProceedButtonFlag,
  setNewSessionButtonFlag,
  setIsFirstProceedClicked,
  setIsVariationProceedClicked,
  setIsCheckBoxesShown,
  setIsProceedButtonShown,
  setClickedButtons,
} from "../../store/features/buttonSlice";
import {
  clearStoreExceptSession,
  editCredit,
  increaseSpendedCredits,
} from "../../store/features/userSlice";
import {
  VARIATION_GENERATION,
  VARIATION_COMPLETE,
  FIRST_GENERATION,
} from "../../utils/statusList/statusList";
import { decreaseCredits } from "../../utils/credit/Credit";

const ChatBox = () => {
  const [isBotTyping, setIsBotTyping] = useState(false);
  const isFirstModelSelectedFromChatRef = useRef(false); //TODO: temporary solution
  const dispatch = useDispatch();
  const [receivedMessages, setReceivedMessages] = useState([]);
  const [userMessages, setUserMessages] = useState([]);
  const messagesEndRef = useRef(null);
  const chatBoxRef = useRef(null);

  const allMessages = useSelector((state) => state.message.allMessage);
  const isFirstModelSelectedFromChat = useSelector(
    (state) => state.message.isFirstModelSelectedFromChat
  );
  const [messageSending, setMessageSending] = useState(false);
  const [sendedMessage, setSendedMessage] = useState("");
  const session = useSelector((state) => state.user.session);
  const userId = useSelector((state) => state.user.id);
  const userObj = useSelector((state) => state.user);
  const modelVersions = useSelector((state) => state.model.modelVersions);
  const selectedPage = useSelector((state) => state.status.selectedPage);
  const generalStatus = useSelector((state) => state.status.generalStatus);
  const finalSelection = useSelector((state) => state.model.chatFinalModel);
  const firstSelection = useSelector((state) => state.model.chatInitialModel);
  const images = useSelector((state) => state.images);
  const regenerateStatus = useSelector(
    (state) => state.status.regenerateStatus
  );
  const chatFinalModel = useSelector((state) => state.model.chatFinalModel);

  const batchId = useSelector((state) => state.message.batchId);

  const proceedButtonFlag = useSelector(
    (state) => state.buttons.proceedButtonFlag
  );
  const isVariationsReady = useSelector(
    (state) => state.status.isVariationsReady
  );
  const isFirstProceedClicked = useSelector(
    (state) => state.buttons.isFirstProceedClicked
  );
  const isVariationProceedClicked = useSelector(
    (state) => state.buttons.isVariationProceedClicked
  );

  const isHeyMessageSent = useSelector(
    (state) => state.message.isHeyMessageSent
  );

  const checkBoxedModel = useSelector((state) => state.model.checkBoxedModel);
  const checkBoxedModelsVariation = useSelector(
    (state) => state.model.checkBoxedModelsVariation
  );
  const clickedButtons = useSelector((state) => state.buttons.clickedButtons);

  const modelVersionsRef = useRef({});
  const isVariationsReadyRef = useRef(isVariationsReady);
  const clickedButtonsRef = useRef(clickedButtons);
  const isHeyMessageSentRef = useRef(isHeyMessageSent);

  useEffect(() => {
    clickedButtonsRef.current = clickedButtons;

    return () => {};
  }, [clickedButtons]);

  const last10Characters = useRef(session?.slice(-8).toUpperCase());

  useEffect(() => {
    console.log("UWUFUF : ", isVariationsReady);
    isVariationsReadyRef.current = isVariationsReady;
    return () => {};
  }, [isVariationsReady]);

  useEffect(() => {
    console.log("PROCEED BUTTON FLAG : " + proceedButtonFlag, checkBoxedModel);

    if (proceedButtonFlag) {
      if (checkBoxedModel?.version && !isFirstProceedClicked) {
        dispatch(setIsFirstProceedClicked({ status: true }));
        sendMessageToRasa(checkBoxedModel?.version);
        dispatch(setIsInputBlocked({ status: true }));
        dispatch(setIsCheckBoxesShown({ status: false }));
        dispatch(setIsProceedButtonShown({ status: false }));
      } else if (
        Object.keys(checkBoxedModelsVariation).length > 0 &&
        !isVariationProceedClicked
      ) {
        let text = "";
        let data = {};
        Object.keys(checkBoxedModelsVariation).forEach((version) => {
          if (text === "") {
            text = version;
          } else {
            text = text + " , " + version;
          }

          data = {
            ...data,
            [version]: checkBoxedModelsVariation[version]?.model?.model,
          };
        });

        if (text.trim() !== "") {
          dispatch(setIsProceedButtonShown({ status: false }));
          dispatch(setIsInputBlocked({ status: true }));
          dispatch(setIsVariationProceedClicked({ status: true }));
          sendMessageToRasa({ text: text, data: data }, "multiple_selections");
        }
        // dispatch(setIsOrderSummaryOpen({ status: true }));
      }
    }

    dispatch(setProceedButtonFlag({ status: false }));

    return () => {};
  }, [
    proceedButtonFlag,
    checkBoxedModel,
    checkBoxedModelsVariation,
    isFirstProceedClicked,
    isVariationProceedClicked,
  ]);

  useEffect(() => {
    modelVersionsRef.current = modelVersions;
    return () => {};
  }, [modelVersions]);

  useEffect(() => {
    isHeyMessageSentRef.current = isHeyMessageSent;
    return () => {};
  }, [isHeyMessageSent]);

  useEffect(() => {
    console.log(
      "WEWEWEEE: ",
      last10Characters.current !== session?.slice(-8).toUpperCase(),
      last10Characters.current,
      session?.slice(-8).toUpperCase(),
      socket
    );
    if (last10Characters.current !== session?.slice(-8).toUpperCase()) {
      last10Characters.current = session?.slice(-8).toUpperCase();
      getSessionConversation();
    }

    if (socket.connected) {
      socket.disconnect();
    } else {
      console.log("SOCKET CONNECTED");
      socket.connect();
    }

    return () => {};
  }, [session]);

  useEffect(() => {
    console.log("CHATFINALMODEL : ", chatFinalModel);
    if (typeof chatFinalModel === "string") {
    } else if (Array.isArray(chatFinalModel)) {
      chatFinalModel.forEach((model) => {
        console.log("SETCHATFINALMODE : ", {
          ...checkBoxedModelsVariation,
          [model]: {
            model: {
              version: model,
              model: modelVersions[model],
            },
          },
        });
        dispatch(
          addCheckBoxedModelsVariation({
            state: {
              [model]: {
                model: {
                  version: model,
                  model: modelVersions[model],
                },
              },
            },
          })
        );
      });
    } else {
      // ShowAlert(3, "An error occurred. Please try again.");
    }
    return () => {};
  }, [chatFinalModel]);

  useEffect(() => {
    console.log("SOCKET CONNECT: ");
    socket.connect();
    socket.on("connect", () => {
      console.log(
        "SOCKET CONNECTED : ",
        `${userId}$${last10Characters.current}`
      );
      socket.emit("session_request", {
        session_id: `${userId}$${last10Characters.current}`,
      });
    });

    socket.on("disconnect", () => {
      console.log("SOCKET RECONNECTED : ", last10Characters.current);
      socket.connect();
    });

    socket.on("packet", ({ type, data }) => {
      // called for each packet received
      console.log("SENDING DATA : ", type, data);
    });

    socket.on("packetCreate", ({ type, data }) => {
      // called for each packet sent
      console.log("COMING DATA : ", type, data);
    });

    socket.on("connect_error", (error) => {
      // Write any connection errors to the console
      if (socket.connected) {
        socket.disconnect();
      } else {
        socket.connect();
      }
      console.log("SOCKET ERROR : ", error);
    });

    return () => {
      socket.on("connect", () => {
        console.log(
          "SOCKET CONNECTED : ",
          `${userId}$${last10Characters.current}`
        );
      });

      socket.on("disconnect", () => {
        console.log("SOCKET RECONNECTED : ");
        socket.connect();
      });
      socket.on("packet", ({ type, data }) => {
        // called for each packet received
        console.log("SENDING DATA : ", type, data);
      });

      socket.on("packetCreate", ({ type, data }) => {
        // called for each packet sent
        console.log("COMING DATA : ", type, data);
      });
    };
  }, [socket]);

  useEffect(() => {
    getSessionConversation();

    return () => {};
  }, []);

  const handleSendMessage = (message) => {
    setIsBotTyping(true);
    sendMessageToRasa(message);
    setMessageSending(true);
    setSendedMessage({ title: message });
  };

  const saveModel = async () => {
    console.log("SAVING MODEL1 : ", {
      user_id: userId,
      job_id: modelVersions[finalSelection],
      image: images[modelVersions[finalSelection]],
    });
    const response = await fetch(`${process.env.REACT_APP_SERVER}/addSave`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session}`,
      },
      body: JSON.stringify({
        user_id: userId,
        job_id: modelVersions[finalSelection],
        image: images[modelVersions[finalSelection]],
      }),
    });
    const result = await response.json();

    console.log("GET SAVED MODELS : ", result);

    if (result.state === "success") {
      dispatch(setUpdateSlice({ updateSlice: true }));
    } else {
      if (result?.error === "model_already_saved") {
        ShowAlert(3, "Model Already Saved");
      }
    }
  };

  const saveMultipleModels = async () => {
    if (Object.keys(checkBoxedModelsVariation).length > 0) {
      let failNo = 0;
      Object.keys(checkBoxedModelsVariation).forEach(async (version) => {
        console.log("SAVING MODEL : ", {
          user_id: userId,
          job_id: checkBoxedModelsVariation[version]?.model?.model,
          image: images[checkBoxedModelsVariation[version]?.model?.model],
        });
        const response = await fetch(
          `${process.env.REACT_APP_SERVER}/addSave`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${session}`,
            },
            body: JSON.stringify({
              user_id: userId,
              job_id: checkBoxedModelsVariation[version]?.model?.model,
              image: images[checkBoxedModelsVariation[version]?.model?.model],
            }),
          }
        );
        const result = await response.json();

        console.log("GET SAVED MODELS : ", result);

        if (result.state === "success") {
          dispatch(setUpdateSlice({ updateSlice: true }));
        } else {
          failNo++;
          if (result?.error === "model_already_saved") {
            // ShowAlert(3, "Model Already Saved");
          }
        }
      });

      if (failNo > 0) {
        ShowAlert(3, "Some of the models could not be saved successfully.");
      } else {
        ShowAlert(1, "Models were saved successfully.");
      }
    }
  };

  const handleOptionClick = (optionData, index) => {
    console.log("OPTIONDATA : ", optionData, index);
    dispatch(setClickedButtons({ state: { [index]: { option: optionData } } }));
    if (optionData === "save") {
      if (regenerateStatus !== VARIATION_GENERATION) {
        saveModel();
      } else {
        saveMultipleModels();
      }
    } else if (optionData === "download") {
      dispatch(setIsOrderSummaryOpen({ status: true }));
    } else if (optionData === "no") {
      sendMessageToRasa(optionData);
      dispatch(setIsInputBlocked({ status: false }));
    } else {
      sendMessageToRasa(optionData);
      setMessageSending(false);
    }
  };

  const sendMessageToRasa = (message, msgType) => {
    console.log("msgType : ", msgType);
    if (!msgType && message) {
      if (message.trim().length === 0) {
        return;
      }
    }

    let response;

    console.log(modelVersions);
    console.log(
      "WEWEWE : ",
      allMessages,
      allMessages[allMessages.length - 2],
      allMessages[allMessages.length - 2]?.text
    );
    if (
      (allMessages[allMessages.length - 2]?.text?.includes(
        "I am generating 4"
      ) ||
        allMessages[allMessages.length - 1]?.text?.includes(
          "I am generating 4"
        ) ||
        allMessages[allMessages.length - 1]?.text?.includes(
          "Once you are ready you can make a selection"
        ) ||
        allMessages[allMessages.length - 1]?.text?.includes(
          "You can select what you want to download and save it to the asset library or download it directly!"
        )) &&
      Object.keys(modelVersions).length > 0 &&
      !msgType &&
      message.trim() !== "start over"
    ) {
      console.log("QWEE : ", modelVersionsRef.current);
      response = JSON.stringify({
        text: message,
        data: modelVersionsRef.current,
        page: selectedPage,
      });
      console.log(
        "SENDING MESSAGE1 : ",
        response,
        `${userId}$${last10Characters.current}`
      );
      socket.emit("user_uttered", {
        message: response,
        session_id: `${userId}$${last10Characters.current}`,
      });
    } else if (msgType === "multiple_selections") {
      console.log("QWEE : ", modelVersionsRef.current);
      response = JSON.stringify(message);
      console.log(
        "SENDING MESSAGE2 : ",
        response,
        `${userId}$${last10Characters.current}`
      );
      socket.emit("user_uttered", {
        message: response,
        session_id: `${userId}$${last10Characters.current}`,
      });
    } else {
      message = message.toString();
      console.log(
        "SENDING MESSAGE3 : ",
        message,
        `${userId}$${last10Characters.current}`
      );
      socket.emit("user_uttered", {
        message,
        session_id: `${userId}$${last10Characters.current}`,
      });

      console.log("OWOWOW : ", message.trim() === "start over", message.trim());

      if (message.trim() === "start over") {
        dispatch(clearStoreExceptSession());
        localforage.clear();
        dispatch(setIsStartedOver({ status: true }));
        dispatch(setIsInputBlocked({ status: false }));
        resetConversation();
      }
    }

    const newMessage = {
      text: typeof message === "object" ? message?.text : message,
      timestamp: new Date().toISOString(),
      sender: "user",
      meta: message,
    };

    setUserMessages((prevMessages) => [...prevMessages, newMessage]);
    dispatch(user(userMessages));
  };

  function isValidJSON(str) {
    try {
      let json = JSON.parse(str);
      return json;
    } catch (e) {
      return false;
    }
  }

  const getSessionConversation = async () => {
    console.log("CONVERSATION GET : ", last10Characters.current);
    const response = await fetch(
      `${process.env.REACT_APP_CHAT}/conversations/${userId}$${last10Characters.current}/tracker?include_events=ALL`
    );

    const result = await response.json();
    let messageCounter = 0;
    console.log("CONVERSATION GET : ", last10Characters.current);
    console.log("MESSAGE COUNTER : ", messageCounter);
    setReceivedMessages([]);
    setUserMessages([]);

    console.log("ALLCONV1 : ", result?.events);

    if (result?.events?.length > 0) {
      result?.events.forEach((message, index) => {
        console.log(
          "CONVERSATION MSG : ",
          message?.event,
          message?.data?.custom?.data?.data,
          message?.text
        );

        if (message.event === "user") {
          if (message?.text.trim() === "start over") {
            console.log("CLEARED MESSAGES");
            messageCounter = 0;
            setReceivedMessages([]);
            setUserMessages([]);
          }
          console.log("MSGCOUNTER1 : ", messageCounter, message);
          let newMessage;
          console.log("ISVALIDJSON :", isValidJSON(message?.text));
          if (isValidJSON(message?.text)) {
            newMessage = {
              text: JSON.parse(message?.text)?.text,
              timestamp: message?.timestamp,
              sender: `${userId}$${last10Characters.current}`,
              meta: message,
              hide: false,
            };
          } else {
            newMessage = {
              text: message?.text,
              timestamp: message?.timestamp,
              sender: `${userId}$${last10Characters.current}`,
              meta: message,
            };
          }
          messageCounter++;

          setUserMessages((prevMessages) => [...prevMessages, newMessage]);
        } else if (message.event === "bot") {
          console.log("MSGCOUNTER2 : ", messageCounter, message);
          if (message?.text) {
            const newMessage = {
              text: message?.text,
              timestamp: message?.timestamp,
              sender: `bot`,
              meta: message,
            };

            messageCounter++;

            setReceivedMessages((prevMessages) => [
              ...prevMessages,
              newMessage,
            ]);

            if (message?.data?.custom?.data?.data) {
              let newDataMessage;
              console.log(
                "ADDCUSTOMDATA1 : ",
                clickedButtonsRef.current,
                messageCounter
              );

              if (clickedButtonsRef.current[messageCounter]) {
                newDataMessage = {
                  text: undefined,
                  sender: "bot",
                  meta: message?.data?.custom?.data?.data,
                  timestamp: message?.timestamp,
                  clickedOption:
                    clickedButtonsRef.current[messageCounter]?.option,
                };
              } else {
                newDataMessage = {
                  text: undefined,
                  sender: "bot",
                  meta: message?.data?.custom?.data?.data,
                  timestamp: message?.timestamp,
                };
              }

              console.log("MSGCOUNTER3 : ", messageCounter, message);

              console.log("ADDCUSTOMDATA : ", newDataMessage);
              messageCounter++;
              setReceivedMessages((prevMessages) => [
                ...prevMessages,
                newDataMessage,
              ]);
            }
          }
        }
        // else if (message?.data?.custom?.data?.data) {
        //   messageCounter++;
        //   let newMessage;
        //   console.log("MSGCOUNTER4 : ", messageCounter, message);
        //   console.log(
        //     "ADDCUSTOMDATA12 : ",
        //     clickedButtonsRef.current,
        //     messageCounter
        //   );
        //   if (clickedButtonsRef.current[messageCounter]) {
        //     newMessage = {
        //       text: undefined,
        //       sender: "bot",
        //       meta: message?.data?.custom?.data?.data,
        //       timestamp: message?.timestamp,
        //       clickedOption: clickedButtonsRef.current[messageCounter]?.option,
        //     };
        //   } else {
        //     newMessage = {
        //       text: undefined,
        //       sender: "bot",
        //       meta: message?.data?.custom?.data?.data,
        //       timestamp: message?.timestamp,
        //     };
        //   }

        //   console.log("ADDCUSTOMDATA : ", newMessage);

        //   setReceivedMessages((prevMessages) => [...prevMessages, newMessage]);
        // }
      });

      if (messageCounter === 0 && !isHeyMessageSentRef.current) {
        dispatch(setIsHeyMessageSent({ status: true }));
        setReceivedMessages([]);
        setUserMessages([]);
        sendMessageToRasa("Hey");
        setMessageSending(false);
      }
    }

    console.log("GET CURRENT CONVERSATION : ", result);
  };

  const resetConversation = () => {
    setReceivedMessages([]);
    setUserMessages([]);
    setMessageSending(false);
    // sendMessageToRasa("Hey");
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };

  const startFirstJob = async () => {
    dispatch(setGeneralStatus({ status: FIRST_GENERATION }));
    await localforage.clear();
    console.log("localforage.clear()");
    setMessageSending(true);
  };

  const startVariateJob = async () => {
    console.log("START_VARIATE_JOB");
    dispatch(setGeneralStatus({ status: VARIATION_GENERATION }));
    dispatch(setRegenerateStatus({ status: VARIATION_GENERATION }));
    setMessageSending(true);
  };

  const handleReceivedMessage = (message) => {
    setIsBotTyping(false);
    setMessageSending(false);
    getRasaModels(isFirstModelSelectedFromChatRef);
    console.log("COMING MESSAGE : ", message);
    if (message !== undefined) {
      if (message?.text) {
        const received = {
          timestamp: new Date().toISOString(),
          text: message.text,
          sender: "bot",
          meta: message,
        };
        setReceivedMessages((prevMessages) => [...prevMessages, received]);
      } else if (message?.payload) {
        if (message?.payload === "quickReplies") {
          dispatch(setIsInputBlocked({ status: true }));
        }

        console.log("MSG1 : ", message?.data);
        const received = {
          timestamp: new Date().toISOString(),
          text: undefined,
          sender: "bot",
          meta: message?.data,
        };

        setReceivedMessages((prevMessages) => [...prevMessages, received]);
      } else if (message?.batch_job_id) {
        console.log("GGENERALSTATUS3 : ", message?.batch_job_id);
      }
    }
  };

  useEffect(() => {
    socket.on("bot_uttered", handleReceivedMessage);
    return () => {
      socket.off("bot_uttered", handleReceivedMessage);
    };
  }, [socket]);

  useEffect(() => {
    const allMessages = [...userMessages, ...receivedMessages];
    console.log("RECEIVED MSGS : ", receivedMessages);
    const sortedMessage = allMessages.sort(
      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
    );
    dispatch(allMessage(sortedMessage));
  }, [userMessages, receivedMessages]);

  useEffect(() => {
    scrollToBottom();
  }, [allMessages]);

  useEffect(() => {
    console.log("GGENERALSTATUS : ", generalStatus);

    socket.on("bot_uttered", handleBatchId);
    return () => {
      socket.off("bot_uttered", handleBatchId);
    };
  }, [socket]);

  useEffect(() => {
    if (!batchId) {
      return;
    } else {
      console.log("GGENERALSTATUS4 : ", batchId);

      handleNewJob(batchId, regenerateStatus, generalStatus);
    }

    return () => {};
  }, [batchId]);

  const handleBatchId = (message) => {
    if (!message?.batch_job_id) {
      return;
    } else {
      dispatch(setBatchId({ status: message }));
    }
  };

  const getRasaModels = async (isFirstModelSelectedFromChatRef) => {
    const response = await fetch(
      `${process.env.REACT_APP_CHAT}/conversations/${userId}$${last10Characters.current}/tracker?include_events=ALL`
    );

    const result = await response.json();

    console.log("getRasaModels : ", result?.slots);
    if (result?.slots?.inital_selection) {
      console.log(
        "getRasaModels initial : ",
        result?.slots?.inital_selection,
        isFirstModelSelectedFromChat,
        isFirstModelSelectedFromChatRef.current,
        generalStatus
      );
      if (
        !isFirstModelSelectedFromChat &&
        !isFirstModelSelectedFromChatRef.current &&
        generalStatus !== VARIATION_GENERATION
      ) {
        dispatch(setIsCheckBoxesShown({ status: false }));
        dispatch(setIsProceedButtonShown({ status: false }));
        dispatch(setIsFirstModelSelectedFromChat({ status: true }));
        isFirstModelSelectedFromChatRef.current = true;
      }
      dispatch(setChatInitialModel({ state: result?.slots?.inital_selection }));
    }
    if (result?.slots?.second_selection) {
      dispatch(setChatSecondModel({ state: result?.slots?.second_selection }));
      dispatch(setIsCheckBoxesShown({ status: false }));
    }
    if (result?.slots?.final_selection) {
      dispatch(setIsCheckBoxesShown({ status: false }));
      dispatch(setIsProceedButtonShown({ status: false }));
      dispatch(setChatFinalModel({ state: result?.slots?.final_selection }));
    }
    if (result?.slots?.object) {
      dispatch(setSelectedModelType({ state: result?.slots?.object }));
    }
  };

  const handleNewJob = async (message, status, generalStatus) => {
    console.log(
      "GGENERALSTATUS2 : ",
      status,
      message,
      isVariationsReady,
      isVariationsReadyRef.current,
      generalStatus
    );

    if (!message?.batch_job_id || generalStatus === VARIATION_COMPLETE) {
      return;
    } else {
      if (status === "not_started") {
        const creditRes = await decreaseCredits(userObj, 1);
        const creditResult = await creditRes.json();
        if (creditResult.state === "success") {
          console.log("CREDITSPEND2");
          dispatch(editCredit({ credits: creditResult.credits }));
          dispatch(increaseSpendedCredits());
          ShowAlert(1, "One credit decreased.");
        } else {
          if (creditResult.message === "not_enough_credits") {
            ShowAlert(3, "Not enough credits");
          } else {
            ShowAlert(3, "An error occurred! Please try again.");
          }
          return;
        }

        startFirstJob();
        dispatch(saveFirstJob({ job: message }));
        dispatch(saveActiveJob({ job: message }));
        dispatch(setBatchId({ status: null }));
      } else {
        console.log("CHECKVARIATEREGENERATE : ", regenerateStatus);
        if (!isVariationsReadyRef.current || !isVariationsReady) {
          if (regenerateStatus !== VARIATION_GENERATION) {
            const creditRes = await decreaseCredits(userObj, 1);
            const creditResult = await creditRes.json();
            if (creditResult.state === "success") {
              console.log("CREDITSPEND1");
              dispatch(editCredit({ credits: creditResult.credits }));
              dispatch(increaseSpendedCredits());
              ShowAlert(1, "One credit decreased.");
            } else {
              if (creditResult.message === "not_enough_credits") {
                ShowAlert(3, "Not enough credits");
              } else {
                ShowAlert(3, "An error occurred! Please try again.");
              }
              return;
            }

            console.log("STARTVARIATEJOB");
            startVariateJob(message);
            dispatch(selectModel({ model: null }));
            dispatch(saveVariation({ job: message }));
            dispatch(saveActiveJob({ job: message }));
          }
        }
      }
    }
  };

  return (
    <div className={style.chatBoxCont}>
      <div ref={chatBoxRef} className={style.AllMessages}>
        {allMessages.map((message, index) => {
          const isBotMessage = message.sender === "bot";
          const isNextMessageFromUser =
            index < allMessages.length - 1 &&
            allMessages[index + 1].sender !== "bot";
          const isOnlyOrLastBotMessage =
            isBotMessage &&
            message.text &&
            (isNextMessageFromUser || index === allMessages.length - 1);
          return (
            <div
              key={index}
              className={
                message.sender === "bot" ? style.autoText : style.userMessage
              }
            >
              {message.sender === "bot" && message.text === undefined ? (
                <>
                  <AutoText
                    onMessageSending={messageSending}
                    sendedMessage={sendedMessage}
                    key={message.timestamp}
                    index={index}
                    onOptionClick={
                      allMessages.length - 1 === index
                        ? handleOptionClick
                        : () => {}
                    }
                    clickedOption={message?.clickedOption}
                    metas={message.meta}
                    isActive={allMessages.length - 1 === index}
                    showIcon={isOnlyOrLastBotMessage}
                  />
                </>
              ) : message.sender === "bot" && message.text !== undefined ? (
                <AutoText
                  other={message}
                  text={message.text}
                  showIcon={isOnlyOrLastBotMessage}
                />
              ) : (
                message.text !== "Hey" && (
                  <UserMessage key={message.messageId} message={message.text} />
                )
              )}
            </div>
          );
        })}
        <div ref={messagesEndRef}></div>
      </div>
      <div className={style.messageBoxContainer}>
        <span className={style.options}>
          <svg
            className={isBotTyping ? "" : style.nonTypingIcon}
            width="24"
            height="5"
            viewBox="0 0 24 5"
            fill="none"
            style={{ marginLeft: "1rem", marginBottom: "1rem" }}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.101 4.31386C23.1491 4.31386 23.9988 3.41109 23.9988 2.29752C23.9988 1.18396 23.1491 0.28125 22.101 0.28125C21.0528 0.28125 20.2031 1.18396 20.2031 2.29752C20.2031 3.41109 21.0528 4.31386 22.101 4.31386Z"
              fill="#F3F4F8"
              className={style.typingDot}
              style={{ animationDelay: "0.8s" }}
            />
            <path
              opacity="0.79"
              d="M15.3705 4.31386C16.4187 4.31386 17.2683 3.41109 17.2683 2.29752C17.2683 1.18396 16.4187 0.28125 15.3705 0.28125C14.3223 0.28125 13.4727 1.18396 13.4727 2.29752C13.4727 3.41109 14.3223 4.31386 15.3705 4.31386Z"
              fill="#F3F4F8"
              className={style.typingDot}
              style={{ animationDelay: "0.6s" }}
            />
            <path
              opacity="0.59"
              d="M8.64002 4.31386C9.68821 4.31386 10.5388 3.41109 10.5388 2.29752C10.5388 1.18396 9.68821 0.28125 8.64002 0.28125C7.59183 0.28125 6.74219 1.18396 6.74219 2.29752C6.74219 3.41109 7.59183 4.31386 8.64002 4.31386Z"
              fill="#F3F4F8"
              className={style.typingDot}
              style={{ animationDelay: "0.4s" }}
            />
            <path
              opacity="0.28"
              d="M1.89784 4.31386C2.94602 4.31386 3.79567 3.41109 3.79567 2.29752C3.79567 1.18396 2.94602 0.28125 1.89784 0.28125C0.849647 0.28125 0 1.18396 0 2.29752C0 3.41109 0.849647 4.31386 1.89784 4.31386Z"
              fill="#F3F4F8"
              className={style.typingDot}
              style={{ animationDelay: "0.2s" }}
            />
          </svg>
        </span>
        <div className={style.sendMessage}>
          <SendMessage onSendMessage={handleSendMessage} />
        </div>
      </div>
    </div>
  );
};

export default ChatBox;
