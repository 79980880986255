import { combineReducers } from "redux";
import userSlice from "./userSlice";
import modelSlice from "./modelSlice";
import spinnerSlice from "./spinnerSlice";
import downloadSlice from "./downloadSlice";
import messageSlice from "./messageSlice";
import statusSlice from "./statusSlice";
import variationDownloadSlice from "./variationDownloadSlice";
import allowStatusSlice from "./allowStatusSlice";
import imagesSlice from "./imagesSlice";
import historySlice from "./historySlice";
import settingsPageSlice from "./settingsPageSlice";
import buttonSlice from "./buttonSlice";

const appReducer = combineReducers({
  user: userSlice,
  model: modelSlice,
  spinner: spinnerSlice,
  downloads: downloadSlice,
  message: messageSlice,
  status: statusSlice,
  variationDownloads: variationDownloadSlice,
  allowStatus: allowStatusSlice,
  images: imagesSlice,
  history: historySlice,
  settingsPage: settingsPageSlice,
  buttons: buttonSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "user/setSession") {
    return appReducer({ user: state.user, history: state.history }, action);
  }

  if (action.type === "user/clearStore") {
    return appReducer(undefined, action);
  }

  if (action.type === "user/clearStoreExceptSession") {
    console.log("user/clearStoreExceptSession : ", state);
    return appReducer({ user: state.user, history: state.history }, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
