import "../../styles/reset.css";
import Navbar from "../../components/navbar/Navbar";
import ChatBox from "../../components/mainChat/ChatBox";
import VideoSection from "../../components/videoSection/VideoSection";
import styles from "./Home.module.css";
import Toolbar from "../../components/toolbar/Toolbar";
import Session from "../../components/session/Session";
import { useSelect } from "@react-three/drei";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { logout } from "../../store/features/userSlice";
import { useEffect, useState } from "react";
import MobilePage from "../mobile/MobilePage";

const Home = () => {
  const sessionKey = useSelect((state) => state.user.session);
  const isAuth = useSelector((state) => state.user.login);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [width, setWidth] = useState();

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (!isAuth) {
      dispatch(logout());
      navigate("/");
      console.log("LOGOUT : !");
      localStorage.removeItem("sessionKey");
    }
    return () => {};
  }, [isAuth]);

  if (!isAuth) {
    return <></>;
  } else if (width <= 600) return <MobilePage />;
  else
    return (
      <>
        <Navbar />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            height: "90vh",
          }}
        >
          <div className={styles.layout}>
            <ChatBox />
            <div className={`${styles.side} `}>
              <VideoSection key={sessionKey} />
              <Toolbar />
            </div>
          </div>
          <Session />
        </div>
      </>
    );
};

export default Home;
