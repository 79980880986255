import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../utils/register/Register";
import styles from "./Register.module.css";
import RoleDropdown from "./RoleDropdown";
import ShowAlert from "../../alert/showAlert";
import Navbar from "../../components/navbar/Navbar";
import { useSearchParams } from "react-router-dom";
import { sendVerificationMail } from "../../utils/mail/verificationMail";
import { setDownloadAllowanceViewer } from "../../store/features/downloadSlice";

function Register() {
  const dispatch = useDispatch();
  const [userReg, setUserReg] = useState({});
  const downloadAllowanceViewer = useSelector(
    (state) => state.downloads.downloadAllowanceViewer
  );
  const [queryParameters] = useSearchParams();
  const [modelId, setModelId] = useState();
  const [hasQueryParams, setHasQueryParams] = useState(false);
  const [inputsMissingName, setInputsMissingName] = useState(false);
  const [inputsMissingSurname, setInputsMissingSurname] = useState(false);
  const [inputsMissingEmail, setInputsMissingEmail] = useState(false);
  const [inputsMissingPassword, setInputsMissingPassword] = useState(false);
  const [readyEmail, setReadyEmail] = useState(true);
  const [readyPassword, setReadyPassword] = useState(true);
  const [hidePassword, setHidePassword] = useState(true);
  const [showPasswordIcon, setShowPasswordIcon] = useState(false);
  const [continues, setContinues] = useState(false);
  const [capsLock, setCapsLock] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (queryParameters.size > 0) {
      setModelId(queryParameters.get("model"));
      setHasQueryParams(true);
    } else {
      return;
    }
  }, [hasQueryParams, modelId, queryParameters]);

  const handleInput = (e) => {
    setUserReg({ ...userReg, [e.target.name]: e.target.value });

    setInputsMissingName(false);
    setInputsMissingSurname(false);
    setInputsMissingEmail(false);
    setInputsMissingPassword(false);
  };

  const goToPage = () => {
    if (!hasQueryParams) {
      navigate("/register-successfull");
    } else {
      navigate(`/file-downloader?model=${modelId}`);
    }
  };

  const checkPassword = (pass) => {
    if (
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(pass) &&
      pass.length >= 8
    ) {
      setReadyPassword(true);
      return true;
    } else {
      setReadyPassword(false);
      return false;
    }
  };

  const handleSignup = async () => {
    try {
      if (
        userReg.name &&
        userReg.surname &&
        userReg.email &&
        userReg.password
      ) {
        const checkMailRE = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!checkMailRE.test(userReg.email)) {
          // invalid email, maybe show an error to the user.
          setReadyEmail(false);
          setInputsMissingEmail(true);
          ShowAlert(3, "Invalid Email!");
          return;
        } else setReadyEmail(true);
        if (!checkPassword(userReg.password)) {
          //invalid password
          setReadyPassword(false);
          setInputsMissingPassword(true);
          ShowAlert(
            3,
            ` Invalid password! Your password must be at least 8 characters long
          and include at least one uppercase letter, one lowercase letter,
          and one number for security purposes.`
          );
          return;
        }

        const response = await register(userReg);

        //user has been created
        if (response.status === 201) {
          dispatch(setDownloadAllowanceViewer({ state: true }));
          console.log("Başarılı");
          ShowAlert(1, `User has been created.`);
          const mailresponse = await sendVerificationMail(userReg.email);
          console.log(" mailresponse : ", mailresponse);
          setContinues(true);
          // navigate("/");
        } else if (response.status === 401) {
          const result = await response.json();
          ShowAlert(3, `User already exist.`);
          setContinues(false);
          console.log(result);
        }
        //user already exists
        else if (response.status === 400) {
          const result = await response.json();
          setContinues(false);
          ShowAlert(3, `An error occurred during sign up.`);
          console.log(result);
        }
      }
      //fill all credentials
      else {
        ShowAlert(3, ` Missing Information!`);
        if (!userReg.name) {
          setInputsMissingName(true);
        } else {
          setInputsMissingName(false);
        }
        if (!userReg.surname) {
          setInputsMissingSurname(true);
        } else {
          setInputsMissingSurname(false);
        }
        if (!userReg.email) {
          setInputsMissingEmail(true);
        } else {
          setInputsMissingEmail(false);
        }
        if (!userReg.password) {
          setInputsMissingPassword(true);
        } else {
          setInputsMissingPassword(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleKeyPress = (e) => {
    if (e.getModifierState("CapsLock")) {
      setCapsLock(true);
    } else {
      setCapsLock(false);
    }
  };

  /* // google sign
  const handleSuccess = (credentialResponse) => {
    console.log("Kimlik doğrulama başarılı!");
    var decoded = jwt_decode(credentialResponse.credential);
    console.log(decoded);
    const user = {
      name: decoded.given_name,
      surname: decoded.family_name,
      email: decoded.email,
      password: decoded.sub,
      image: decoded.picture,
    };
    googleSignup(user);
  };

  const handleError = () => {
    console.log("Giriş başarısız!");
  };

  const googleSignup = async (userR) => {
    try {
      const response = await registers(userR);
      const result = await response.json();
      console.log("GOOGLE RESULT : ", result);
      console.log("GOOGLE RESPONSE : ", response);
      //user has been created
      if (response.status === 201) {
        console.log("Başarılı");
        console.log("goog", result);
        dispatch(
          loginSuccess({
            name: result.name,
            surname: result.surname,
            email: result.email,
            image: result.image,
            credits: 60,
          })
        );
        navigate("/home");
      }
      //user already exists
      else if (response.status === 401) {
        console.log("Kullanıcı zaten kayıtlı.");
        dispatch(loginSuccess(result.user));
        navigate("/home");
      }
      //fill all credentials
      else {
        console.log("Lütfen gösterilen alanları doldurunuz!");
      }
    } catch (error) {
      console.error(error);
    }
  };
  // google sign
  */

  return (
    <>
      <div className={`${styles.container} ${styles.container2}`}>
        <Navbar modelId={modelId} />
        <div className={`${styles.loginContainer} ${styles.registerContainer}`}>
          <div className={styles.headOfP}>
            <img
              className={styles.imageProcedura}
              src={require("../../images/logo.png")}
              alt=""
            />
            <h2 className={styles.title}>
              <span className={styles.brand}> procedura </span>
            </h2>
          </div>
          {!continues ? (
            <div className={styles.inputContainer}>
              <input
                name="name"
                placeholder="NAME"
                onChange={(e) => {
                  handleInput(e);
                }}
                type="name"
                className={`${styles.basicInput}  ${
                  inputsMissingName ? styles.missing : ""
                }`}
              />
              <div className={`${styles.inputRow} ${styles.second}`}>
                <input
                  onChange={(e) => {
                    handleInput(e);
                  }}
                  placeholder="SURNAME"
                  name="surname"
                  type="surname"
                  className={`${styles.basicInput}  ${
                    inputsMissingSurname ? styles.missing : ""
                  }`}
                />
              </div>

              <div className={`${styles.inputRow} ${styles.second}`}>
                <input
                  onChange={(e) => {
                    handleInput(e);
                  }}
                  name="email"
                  type="email"
                  placeholder="EMAIL"
                  className={`${styles.basicInput} ${
                    inputsMissingEmail ? styles.missing : ""
                  }`}
                />
                {!readyEmail && (
                  <p className={styles.emailCheck}>name@example.com</p>
                )}
              </div>

              <div className={`${styles.inputRow} ${styles.second}`}>
                <input
                  onChange={(e) => {
                    handleInput(e);
                    setShowPasswordIcon(true);
                  }}
                  name="password"
                  type={hidePassword ? "password" : "text"}
                  onKeyPress={handleKeyPress}
                  placeholder="PASSWORD"
                  className={`${styles.basicInput}  ${
                    inputsMissingPassword ? styles.missing : ""
                  }`}
                  style={{ position: "relative" }}
                />
                {capsLock && (
                  <svg
                    className={styles.capsLock}
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 3.63578L13.2375 9.55359L15 7.7357L7.5 0L0 7.7357L1.7625 9.55359L7.5 3.63578ZM0 16H15V13.4214H0V16Z"
                      fill="#FAF947"
                      fill-opacity="0.8"
                    />
                  </svg>
                )}

                {showPasswordIcon &&
                  (hidePassword ? (
                    <svg
                      onClick={() => setHidePassword(false)}
                      className={styles.showPassword}
                      width="22"
                      height="19"
                      viewBox="0 0 22 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 8.5C0 8.5 4.188 2.5 11 2.5C11.947 2.5 12.839 2.621 13.678 2.822L7.36 9.14C7.12415 8.62535 7.00139 8.06611 7 7.5C7 6.563 7.335 5.713 7.875 5.031C5.483 5.843 3.661 7.416 2.621 8.5C3.50694 9.42431 4.50856 10.2303 5.601 10.898L4.148 12.351C1.497 10.63 0 8.5 0 8.5ZM22 8.5C22 8.5 17.812 14.5 11 14.5C10.054 14.5 9.164 14.376 8.324 14.177L4 18.5L2.5 17L19.5 0L21 1.5L17.853 4.647C20.501 6.369 22 8.5 22 8.5ZM19.385 8.506C18.4975 7.57914 17.4934 6.77139 16.398 6.103L15 7.5C15 8.56087 14.5786 9.57828 13.8284 10.3284C13.0783 11.0786 12.0609 11.5 11 11.5L10.053 12.447C10.363 12.478 10.677 12.5 11 12.5C14.978 12.5 17.943 10.022 19.385 8.506Z"
                        fill="#F3F4F8"
                        fill-opacity="0.5608"
                      />
                    </svg>
                  ) : (
                    <svg
                      onClick={() => setHidePassword(true)}
                      className={styles.showPassword}
                      width="22"
                      height="12"
                      viewBox="0 0 22 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 0C4.188 0 0 6 0 6C0 6 4.188 12 11 12C17.812 12 22 6 22 6C22 6 17.812 0 11 0ZM11 10C7.057 10 4.074 7.516 2.621 6C3.661 4.915 5.483 3.343 7.875 2.531C7.3117 3.23091 7.00314 4.10157 7 5C7 6.06087 7.42143 7.07828 8.17157 7.82843C8.92172 8.57857 9.93913 9 11 9C12.0609 9 13.0783 8.57857 13.8284 7.82843C14.5786 7.07828 15 6.06087 15 5C14.9969 4.10157 14.6883 3.23091 14.125 2.531C16.518 3.343 18.341 4.916 19.379 6C17.924 7.518 14.942 10 11 10Z"
                        fill="#F3F4F8"
                        fill-opacity="0.8"
                      />
                    </svg>
                  ))}
                {!readyPassword ? (
                  <div className={styles.passwordCheck}>
                    <p className={styles.passwordCont}>
                      Your password must contain
                    </p>
                    <li>At least 8 characters</li>
                    <p className={styles.passwordCont}>
                      At least 2 of the following
                    </p>
                    <li>Lower case letters (a-z)</li>
                    <li>Upper case letters (A-Z)</li>
                    <li>Numbers (0-9)</li>
                    <li>Special characters (e.g. !@#$%^&*)</li>
                  </div>
                ) : null}
              </div>
              <div className={styles.signContainer}>
                <div className={styles.buttonContainer}>
                  <button className={styles.button} onClick={handleSignup}>
                    CONTINUE
                  </button>
                </div>
                <p className={styles.accountQuestion}>
                  Do you have an account?{" "}
                  <Link
                    to={hasQueryParams ? `/?model=${modelId}` : "/"}
                    className={styles.buttonSign}
                  >
                    Login
                  </Link>
                </p>
                <div className={styles.icons}>
                  {/* <GoogleSign></GoogleSign> */}
                  {/* {googleClientId ? (
                    <GoogleOAuthProvider clientId={googleClientId}>
                      <CustomGoogle
                      // onSuccess={handleSuccess}
                      // onError={handleError}
                      ></CustomGoogle>
                    </GoogleOAuthProvider>
                  ) : null} */}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.secondInputContainer}>
              <input
                onChange={(e) => {
                  handleInput(e);
                }}
                placeholder="COMPANY NAME"
                name="companyName"
                type="text"
                className={`${styles.basicInput}  ${
                  inputsMissingSurname ? styles.missing : ""
                }`}
              />
              <RoleDropdown />
              <div className={styles.buttonContainer}>
                <button
                  className={styles.button}
                  onClick={() => {
                    dispatch(setDownloadAllowanceViewer({ status: true }));
                    goToPage();
                  }}
                >
                  SIGN UP
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Register;
