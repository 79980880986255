import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const variationDownloadSlice = createSlice({
  name: "variationDownloads",
  initialState,
  reducers: {
    addNewVariationDownload: (state, action) => {
      state[action.payload.uid] = {
        jobId: action.payload.uid,
        numberOfAllFiles: action.payload.numberOfFiles,
        numberOfDownloadedFiles: 0,
        downloadStatus: "downloading",
      };
    },
    increaseDownloadedVariationFileNumber: (state, action) => {
      state[action.payload.uid].numberOfDownloadedFiles += 1;

      if (
        state[action.payload.uid].numberOfDownloadedFiles ===
        state[action.payload.uid].numberOfAllFiles
      ) {
        state[action.payload.uid].downloadStatus = "complete";
      }
    },
    clearVariationDownloads: (state, action) => {
      state = initialState;
    },
  },
});

export const {
  addNewVariationDownload,
  increaseDownloadedVariationFileNumber,
  clearVariationDownloads,
} = variationDownloadSlice.actions;

export default variationDownloadSlice.reducer;
