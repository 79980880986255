import React, { useEffect, useState } from "react";
import styles from "./Feedback.module.css";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";

function Feedback({ getFeedBack }) {
  const [inputValue, setInputValue] = useState("");
  const [rating, setRating] = useState(null);
  const [comment, setComment] = useState("");

  const handleInputChange = (event) => {
    const value = event.target.value;

    console.log("HANDLE INPUT CHANGE : ", event.target.value);
    setComment(value);
  };

  const handleRatingChange = (event, value) => {
    console.log("HANDLE RATING CHANGE : ", value);
    setRating(value);
  };

  useEffect(() => {
    console.log("FEEDBACKK : ", { rating: rating, comment: comment });
    getFeedBack({ rating: rating, comment: comment });
    return () => {};
  }, [rating, comment]);

  return (
    <div className={styles.container}>
      <div className={styles.head}>
        <Typography component="legend" className={styles.boldText}>
          <strong>
            {" "}
            On a scale of 1 to 7, how would you rate the asset generated by
            Procedura?
          </strong>
        </Typography>
        <Rating
          onChange={(event, newValue) => {
            console.log("NEW VALUESS : ", newValue);
            handleRatingChange(event, newValue);
          }}
          name="customized-10"
          defaultValue={0}
          max={7}
          size="large"
        />
      </div>
      <div className={styles.body}>
        <p>
          Do you have any additional comments or feedback you would like to
          share about your experience?
        </p>
        <textarea
          placeholder="Send a comment"
          onChange={handleInputChange}
          className={styles.input}
        ></textarea>
      </div>
    </div>
  );
}

export default Feedback;
