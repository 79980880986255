import TextField from "@mui/material/TextField";
// import Button from "@mui/material/Button";
import styles from "./resetPassword.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Alert, AlertTitle } from "@mui/material";
import ShowAlert from "../../alert/showAlert";
import HeroSection from "../../components/heroSection/HeroSection";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [mail, setMail] = useState("");

  const navigate = useNavigate();

  const checkPassword = (pass) => {
    if (
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(pass) &&
      pass.length >= 8
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!checkPassword(password)) {
      ShowAlert(
        1,
        `Invalid password! Your password must be at least 8 characters long
      and include at least one uppercase letter, one lowercase letter, and
      one number for security purposes.`
      );
      return;
    }

    try {
      let result = await axios.post(
        `${process.env.REACT_APP_SERVER}/resetPassword`,
        {
          newPassword: password,
          id: window.location.href.split("?")[1],
        }
      );

      console.log("RESET PASSWORD : ", result);

      if (result.data.state === "success") {
        ShowAlert(1, `Password updated successfully.`);
        navigate("/password-changed");
      } else if (result.data.error === "invalid_id") {
        ShowAlert(3, `Password can not updated.`);
        // alert("Error");
      } else if (result.data.error === "invalid_request") {
        ShowAlert(3, `Password request invalid.`);
        // alert("Error");
      } else if (result.data.error === "expired_request") {
        ShowAlert(3, `Current link expired.`);
        // alert("Error");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkIsRequestPageValid = async () => {
    let result = await axios.post(
      `${process.env.REACT_APP_SERVER}/checkResetPasswordPage`,
      {
        id: window.location.href.split("?")[1],
      }
    );

    if (result.data.state === "fail") {
      if (
        result.data.error === "invalid_id" ||
        result.data.error === "invalid_request"
      ) {
        ShowAlert(3, `Invalid request page.`);
      } else if (result.data.error === "expired_request") {
        ShowAlert(3, `Expired request.`);
      }
      navigate("/");
    } else if (result.data.state === "success") {
      setMail(result.data.mail);
    }
  };

  useEffect(() => {
    checkIsRequestPageValid();
    return () => {};
  }, []);

  return (
    <HeroSection
      title="Reset Your Password"
      paragraph={[
        "Enter a new password for ",
        <span className={styles.email}>{mail}</span>,
      ]}
      content={
        <form onSubmit={handleSubmit} className={styles.holder}>
          <input
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            type="password"
            placeholder="New Password"
            className={styles.input}
          />
          <button className={styles.submitButton} type="submit">
            Reset
          </button>
        </form>
      }
    />
  );
};

export default ResetPassword;
