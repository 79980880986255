import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const imagesSlice = createSlice({
  name: "images",
  initialState,
  reducers: {
    setImage: (state, action) => {
      state[action.payload.uid] = action.payload.image;
    },
    clearImages: (state, action) => {
      state = initialState;
    },
  },
});

export const { setImage, clearImages, clearDownloads, setDownloadRequest } =
  imagesSlice.actions;

export default imagesSlice.reducer;
