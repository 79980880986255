import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "../../../styles/customCarousel.css";

import { Pagination, EffectFade } from "swiper";
import VideoGrid from "../videoGrid/VideoGrid";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedPage } from "../../../store/features/statusSlice";
import { selectModel } from "../../../store/features/modelSlice";

const CarouselSlider = ({ variations, model1, model2, model3 }) => {
  const dispatch = useDispatch();

  const isFirstModelReady = useSelector(
    (state) => state.status.isFirstModelReady
  );

  const isSecondModelReady = useSelector(
    (state) => state.status.isSecondModelReady
  );

  const isThirdModelReady = useSelector(
    (state) => state.status.isThirdModelReady
  );

  const isVariationReady = useSelector(
    (state) => state.status.isVariationsReady
  );

  const generalStatus = useSelector((state) => state.status.generalStatus);
  const chatInitialModel = useSelector((state) => state.model.chatInitialModel);
  const selectedModel = useSelector((state) => state.model.selectedModel);

  const [swiper, setSwiper] = useState(null);
  const slideTo = (index) => swiper?.slideTo(index);
  useEffect(() => {
    console.log("ISVARIATIONREADY : ", isVariationReady);
    return () => {};
  }, [isVariationReady]);

  useEffect(() => {
    switch (generalStatus) {
      case "SECOND_COMPLETED":
        console.log("SWIPER1");
        slideTo(1);
        break;
      case "THIRD_COMPLETE":
        console.log("SWIPER2");
        slideTo(2);
        break;
      default:
        break;
    }

    return () => {};
  }, [generalStatus]);

  useEffect(() => {
    if (!chatInitialModel) {
      return;
    }

    let selectedModel = parseInt(
      chatInitialModel.split(".")[0].replace("V", "")
    );

    if (selectedModel >= 1 && selectedModel <= 4) {
      slideTo(0);
    } else if (selectedModel >= 5 && selectedModel <= 8) {
      slideTo(1);
    } else if (selectedModel >= 9 && selectedModel <= 12) {
      slideTo(2);
    } else {
    }

    return () => {};
  }, [chatInitialModel]);

  return (
    <Swiper
      pagination={{
        clickable: true,
        enabled: chatInitialModel || selectedModel ? false : true,
      }}
      onSwiper={setSwiper}
      spaceBetween={70}
      modules={[Pagination, EffectFade]}
      className="mySwiper"
      effect="cube"
      speed={1}
      allowTouchMove={false}
      style={{
        "--swiper-pagination-color": "#0384D8",
        "--swiper-pagination-bullet-inactive-color": "#999999",
        "--swiper-pagination-bullet-inactive-opacity": "1",
        "--swiper-pagination-bullet-size":
          chatInitialModel || selectedModel ? "0" : "12px",
        "--swiper-pagination-bullet-horizontal-gap": "6px",
      }}
      onSlideChange={(swiper) => {
        dispatch(setSelectedPage({ selectedPage: swiper.snapIndex + 1 }));
        console.log("slide change : ", swiper.snapIndex);
      }}
      enabled={chatInitialModel ? false : true}
    >
      {isVariationReady && (
        <SwiperSlide>
          <VideoGrid models={variations} pageNo={3} />
        </SwiperSlide>
      )}
      {isFirstModelReady && !isVariationReady && (
        <SwiperSlide>
          <VideoGrid models={model1} pageNo={0} />
        </SwiperSlide>
      )}
      {isSecondModelReady && !isVariationReady && (
        <SwiperSlide>
          <VideoGrid models={model2} pageNo={1} />
        </SwiperSlide>
      )}
      {isThirdModelReady && !isVariationReady && (
        <SwiperSlide>
          <VideoGrid models={model3} pageNo={2} />
        </SwiperSlide>
      )}
    </Swiper>
  );
};

export default CarouselSlider;
