import { useEffect, useState } from "react";
import HeroSection from "../../components/heroSection/HeroSection";
import Button from "../../components/heroSection/button/Button";
import { verifyAccount } from "../../utils/verifyAccount/verifyAccount";
import { useNavigate } from "react-router";

const VerifyAccountPage = () => {
  const [response, setResponse] = useState(null);
  const navigation = useNavigate();

  useEffect(() => {
    checkVerificationCode();
    return () => {};
  }, []);

  const checkVerificationCode = async () => {
    const response = await verifyAccount(window.location.href.split("?")[1]);
    const result = await response.json();
    if (result?.state === "success") {
      setResponse("success");
    } else {
      setResponse("fail");
    }
  };

  return (
    <>
      {response === "success" ? (
        <HeroSection
          title="Your Account is Verified."
          paragraph={["Now you can login to your account"]}
          content={<Button to={"/"} text="Back to log in" />}
        />
      ) : response === "fail" ? (
        <HeroSection
          title="Invalid request."
          paragraph={[
            "If you haven't received the verification email or",
            <br />,
            "if it has expired, you can request a new one.",
          ]}
          content={
            <Button to={"/verification-request"} text="Request a new link" />
          }
        />
      ) : null}
    </>
  );
};

export default VerifyAccountPage;
