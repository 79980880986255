import React, { useState } from "react";
import my from "./Download.module.css";

const Download = ({
  value,
  names,
  getSelectedFileExtension,
  getSelectedTexture,
  type,
}) => {
  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (index, e) => {
    console.log("handleButtonClick : ", e.target.value);
    if (type === "fileFormat") {
      getSelectedFileExtension(e.target.value);
    } else if (type === "resolution") {
      getSelectedTexture(e.target.value);
    }
    setActiveButton(index);
  };

  return (
    <div className={my.downloadContainer}>
      <p className={my.downloadTitle}>{names}</p>
      <div className={my.downloadButtons}>
      {value.map((opt, index) => (
        <button
          key={opt.id}
          className={`${my.downloadMiniButton} ${
            activeButton === index ? my.activeButton : ""
          }`}
          value={opt?.value}
          onClick={(e) => handleButtonClick(index, e)}
        >
          {opt.title}
        </button>
      ))}
      </div>
    </div>
  );
};

export default Download;