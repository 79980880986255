import { useState } from "react";
import style from "../profile.module.css";
import { BiHide as Hide, BiShow as Show } from "react-icons/bi";
const Container = ({ value, text, setUser, setPassword, type }) => {
  const [name, setName] = useState(value);
  const [itype, setType] = useState(type);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setName(newValue);
    setUser ? setUser(newValue) : setPassword && setPassword(newValue);
  };

  const changeHideStatus = () => {
    itype !== "text" ? setType("text") : setType("password");
  };

  return (
    <div className={style.nameContainer}>
      <label className={style.name}>{text}</label>
      <div className={style.inputContainer}>
        <input
          type={itype ? itype : "text"}
          className={style.nameInput}
          placeholder={name}
          defaultValue={value}
          onChange={handleInputChange}
          autoComplete="new-password"
          disabled={text === "E-Mail" ? true : false}
        />
        {type &&
          (itype === "password" ? (
            <Hide size={20} className={style.hide} onClick={changeHideStatus} />
          ) : (
            <Show size={20} className={style.hide} onClick={changeHideStatus} />
          ))}
      </div>
    </div>
  );
};

export default Container;
