import ShowAlert from "../../alert/showAlert";

const Opus = async (object) => {
  const url = "https://opus5.p.rapidapi.com/create_opus_component";
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      // "X-RapidAPI-Key": KEY,
      // "X-RapidAPI-Host": HOST,
    },
    body: JSON.stringify(object),
  });
  return response;
};

const createBatch = async (component, session) => {
  console.log(component);
  const url = `${process.env.REACT_APP_SERVER}/createOpusBatchComponent`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${session}`,
    },
    body: JSON.stringify(component),
  });
  const result = await response.json();
  console.log("CREATE COMPONENT : ", result);
  return result;
};

const checkJobStatus = async (job, session) => {
  if (typeof job !== "object") {
    return { state: "fail" };
  }
  if (Object.keys(job)?.length === 0) {
    return { state: "fail" };
  }
  const url = `${process.env.REACT_APP_SERVER}/getJobResults`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${session}`,
      // "X-RapidAPI-Key": KEY,
      // "X-RapidAPI-Host": HOST,
    },
    body: JSON.stringify({
      uid: job?.batch_job_id ? job?.batch_job_id : job?.result_id,
    }),
  });
  const result = await response.json();
  console.log("JOB STATUS : ", result);
  return result;
};

const createVariations = async (id, session) => {
  console.log("VARIATION ID : ", id);
  if (id === null) {
    return { state: "fail" };
  }

  const url = `${process.env.REACT_APP_SERVER}/createVariateModels`;
  console.log("Regenerate ID : ", id);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${session}`,
    },
    body: JSON.stringify({ id: id }),
  });
  const result = await response.json();
  console.log("createVariationsDEEE : ", result);
  return result;
};

const resizeAndDownloadModel = async (
  job_id,
  extensions,
  resolution,
  session
) => {
  if (job_id === null) {
    ShowAlert(3, "Can't find job id");
    return { state: "fail" };
  }

  const url = `${process.env.REACT_APP_SERVER}/resizeAndDownloadModel`;
  console.log("Regenerate ID : ", job_id);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${session}`,
    },
    body: JSON.stringify({ id: job_id, extensions, resolution }),
  });
  const result = await response.json();
  console.log("createVariationsDEEE : ", result);
  return result;
};

export {
  Opus,
  checkJobStatus,
  createBatch,
  createVariations,
  resizeAndDownloadModel,
};
