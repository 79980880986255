import { useEffect } from "react";
import style from "./Meta.module.css";
const MetaMini = ({ src, name, onClick, data, clickedOption }) => {
  const handleClick = () => {
    console.log(data);
    onClick(data);
  };

  return (
    <div onClick={handleClick} className={style.metaButtons}>
      <p>{name}</p>
      <img className={style.metaImg} src={src} alt="" />
    </div>
  );
};

export default MetaMini;
