import style from "./navbar.module.css";
import { BiArrowToRight as Arrow } from "react-icons/bi";
import { MdAddCircleOutline as Add } from "react-icons/md";
import { PiFlowerTulipDuotone as Flower } from "react-icons/pi";
import { AiFillSetting as Settings } from "react-icons/ai";
import { BsCreditCard2Front as Billing } from "react-icons/bs";
import { LuLogOut as Logout } from "react-icons/lu";
import UserImage from "../picture/UserImage";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { logout, setSession } from "../../../store/features/userSlice";
import { setSettingsPage } from "../../../store/features/settingsPageSlice";
import { sessionLogId } from "../../../utils/session/Session";
import localforage from "localforage";
import { setIsOrderSummaryOpen } from "../../../store/features/statusSlice";
import { setNewSessionButtonFlag } from "../../../store/features/buttonSlice";
const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const handleSettingsPage = (value) => {
    dispatch(setSettingsPage({ page: value }));
  };

  const handleWithSession = async () => {
    try {
      if (user.email) {
        const response1 = await sessionLogId(user);
        const data1 = await response1.json();
        await localforage.clear();
        console.log("localforage.clear()");
        dispatch(setSession({ session: data1.user.session_id }));
        dispatch(setIsOrderSummaryOpen({ status: false }));
        dispatch(setNewSessionButtonFlag({ status: true }));
        localStorage.setItem("sessionKey", data1.user.session_id);
        localStorage.removeItem("firstRegeneration");
        localStorage.removeItem("downloadStatus");
      }
    } catch (error) {
      console.error("Session error:", error);
    }
  };

  return (
    <div className={style.navContainer}>
      <div className={style.logoContainer}>
        <div className={style.logo}>
          <img src={require("../../../images/logo.png")} alt="" />
        </div>
        <div className={style.companyName}>
          <label>PROCEDURA</label>
          <Arrow
            cursor={"pointer"}
            onClick={() => navigate("/home")}
            size={40}
          />
        </div>
      </div>
      <div className={style.optionContainer}>
        <button
          onClick={() => {
            handleWithSession();
            setTimeout(() => {}, 1500);
            navigate("/home");
          }}
          className={style.options}
        >
          <Add size={40} />
          <p>START BUILD</p>
        </button>
        <button
          className={style.options}
          onClick={() => {
            handleSettingsPage("library");
          }}
        >
          <Flower size={40} />
          <p>LIBRARY</p>
        </button>
        <button
          className={style.options}
          onClick={() => {
            handleSettingsPage("settings");
          }}
        >
          <Settings size={40} />
          <p>SETTINGS</p>
        </button>
        <button className={style.options} onClick={() => navigate("/billing")}>
          <Billing size={40} />
          <p>BILLING & PLANS</p>
        </button>
        <button onClick={handleLogout} className={style.options}>
          <Logout size={40} />
          <p>LOGOUT</p>
        </button>
      </div>
      <button className={style.nameAndSurname}>
        <UserImage />
        <p>{user.name + " " + (user.surname ? user.surname : "")}</p>
      </button>
    </div>
  );
};

export default Navbar;
