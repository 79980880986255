import { createSlice } from "@reduxjs/toolkit";

const initialState = { savedModels: [], updateSlice: false };

const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    setSavedModels: (state, action) => {
      state.savedModels = action.payload.savedModels;
    },
    setUpdateSlice: (state, action) => {
      state.updateSlice = action.payload.updateSlice;
    },
    clearDownloads: (state, action) => {
      state = initialState;
    },
  },
});

export const { setSavedModels, clearDownloads, setUpdateSlice } =
  historySlice.actions;

export default historySlice.reducer;
