import React, { useState } from "react";
import Profile from "./profile/Profile";
import Preferences from "./preferences/Preferences";
import Security from "./security/Security";
import style from "./settingsmodal.module.css";
import { CgCloseR as Close } from "react-icons/cg";
const SettingsModal = ({ onSetSettingsClick }) => {
  const [clicked, setClicked] = useState("profile");
  return (
    <div className={style.settingModal}>
      <label className={style.myLabel}>
        SETTINGS
        <Close
          cursor={"pointer"}
          onClick={() => onSetSettingsClick(false)}
        />
      </label>
      <div className={style.settingOptions}>
        <div className={style.optionButtons}>
          <button
            onClick={() => {
              setClicked("profile");
            }}
            className={
              clicked === "profile" ? style.clickedButton : style.buttons
            }
          >
            Profile
          </button>
          <button
            onClick={() => {
              setClicked("preferences");
            }}
            className={
              clicked === "preferences" ? style.clickedButton : style.buttons
            }
          >
            Preferences
          </button>
          <button
            onClick={() => {
              setClicked("security");
            }}
            className={
              clicked === "security" ? style.clickedButton : style.buttons
            }
          >
            Security
          </button>
        </div>

        <div
          className={style.optionsResult}
          key={clicked}
        >
          {clicked === "profile" && <Profile modal />}
          {clicked === "preferences" && <Preferences modal />}
          {clicked === "security" && <Security modal />}
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
