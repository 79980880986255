import HeroSection from "../../components/heroSection/HeroSection";
import Button from "../../components/heroSection/button/Button";
const LinkExpirationPage = () => {
  return (
    <HeroSection
      title="Sorry, the link has expired. "
      paragraph={[
        "The link you're trying to use may have expired",
        <br key="br" />,
        "or been used before.",
        <br key="br2" />,
        "Please try again with a new link.",
      ]}
      content={<Button text="Request a new link" />}
    />
  );
};

export default LinkExpirationPage;
