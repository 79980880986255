const Logo = () => {
  return (
    <svg
      width="53"
      height="54"
      viewBox="0 0 53 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.0498 54V31.6357H9.09375L31.0498 54Z"
        fill="url(#paint0_linear_1_483)"
      />
      <path
        d="M53.0002 22.3704L31.05 44.6958V0L53.0002 22.3704Z"
        fill="url(#paint1_linear_1_483)"
      />
      <path
        d="M0 31.6359L31.05 0V31.6359H0Z"
        fill="url(#paint2_linear_1_483)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_483"
          x1="20.0669"
          y1="42.8181"
          x2="31.2457"
          y2="31.8463"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FAF947" />
          <stop
            offset="1"
            stop-color="#24FB41"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_483"
          x1="31.0501"
          y1="22.3725"
          x2="53.0057"
          y2="22.3725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#007990" />
          <stop
            offset="1"
            stop-color="#78FFD5"
          />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_483"
          x1="0"
          y1="15.818"
          x2="31.05"
          y2="15.818"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#007990" />
          <stop
            offset="1"
            stop-color="#78FFD5"
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Logo;
