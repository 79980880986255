import HeroSection from "../../components/heroSection/HeroSection";
import Button from "../../components/heroSection/button/Button";
const NotFound = () => {
  return (
    <HeroSection
      title="Oops, something has gone wrong."
      paragraph={[
        "Don’t worry, it can happen to the best of us,",
        <br key="br" />,
        "and it just happened to you.",
      ]}
      content={<Button to="/" text="Return to main page" />}
    />
  );
};

export default NotFound;
