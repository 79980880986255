import React, { useState, useEffect } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { registers } from "../../utils/register/Register";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  clearStore,
  loginSuccess,
  setPreferences,
} from "../../store/features/userSlice";
import { checkJWT, sessionLogId } from "../../utils/session/Session";
import localforage from "localforage";
import ShowAlert from "../../alert/showAlert";
import { login } from "../../utils/login/login";

function GoogleSign() {
  const [queryParameters] = useSearchParams();
  const [modelId, setModelId] = useState();
  const [hasQueryParams, setHasQueryParams] = useState(false);
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const navigate = useNavigate();

  useEffect(() => {
    if (queryParameters.size > 0) {
      setModelId(queryParameters.get("model"));
      setHasQueryParams(true);
      console.log("model id:", modelId, "params:", hasQueryParams);
      console.log("queryParameters", queryParameters);
    } else {
      return;
    }
  }, [hasQueryParams, modelId, queryParameters]);

  const handleSuccess = (credentialResponse) => {
    console.log("Kimlik doğrulama başarılı!", credentialResponse);
    var decoded = jwt_decode(credentialResponse.credential);
    const user = {
      name: decoded.given_name,
      surname: decoded.family_name,
      email: decoded.email,
      password: decoded.sub, //TODO: send as encrypted
      image: decoded.picture,
      credential: credentialResponse,
    };
    googleSignup(user);
  };

  const dispatch = useDispatch();

  const handleError = () => {
    console.log("Giriş başarısız!");
  };

  const getNewSessionToken = async (data) => {
    let response1;
    let data1;
    let sessionId;
    response1 = await sessionLogId(data);
    data1 = await response1.json();
    sessionId = data1?.user?.session_id;
    dispatch(clearStore());
    localStorage.removeItem("firstRegeneration");
    localStorage.removeItem("downloadStatus");
    await localforage.clear();
    localStorage.setItem("lastLogin", data.email);
    console.log("GET NEW SESSION TOKEN  : ", sessionId);
    return sessionId;
  };

  const googleSignup = async (userR) => {
    try {
      const response = await registers(userR);
      const result = await response.json();
      console.log("GOOGLE RESULT : ", result);
      console.log("GOOGLE RESPONSE : ", response);
      let sessionId;
      //user has been created
      if (response.status === 201) {
        console.log(
          "SESSION ID4 : ",
          localStorage.getItem("lastLogin") !== result.email,
          !localStorage.getItem("sessionKey")
        );

        if (
          localStorage.getItem("lastLogin") !== result.email ||
          !localStorage.getItem("sessionKey")
        ) {
          sessionId = await getNewSessionToken(result);
          console.log("SESSION ID1 : ", sessionId);
        } else {
          if (checkJWT() === "valid") {
            sessionId = localStorage.getItem("sessionKey");
            console.log("SESSION ID2 : ", sessionId);
          } else {
            sessionId = await getNewSessionToken(result);
            console.log("SESSION ID3 : ", sessionId);
          }
        }

        console.log("SESSION ID : ", sessionId);

        dispatch(
          loginSuccess({
            name: result?.name,
            surname: result?.surname,
            email: result?.email,
            image: result?.image,
            id: result?.id,
            session: sessionId,
            credits: 500,
          })
        );
        navigate("/home");
      }
      //user already exists
      else if (response.status === 401) {
        if (result?.error === "user_already_exists") {
          const loginResponse = await login(userR);
          const loginResult = await loginResponse.json();

          console.log(loginResult);

          if (loginResponse.status === 200) {
            if (
              localStorage.getItem("lastLogin") !== loginResult.email ||
              !localStorage.getItem("sessionKey")
            ) {
              sessionId = await getNewSessionToken(loginResult);
            } else {
              if (checkJWT() === "valid") {
                sessionId = localStorage.getItem("sessionKey");
              } else {
                sessionId = await getNewSessionToken(loginResult);
              }
            }

            console.log(sessionId);

            const loggedInUser = {
              id: loginResult.id,
              name: loginResult.name,
              surname: loginResult.surname,
              email: loginResult.email,
              company: loginResult.company,
              occupation: loginResult.occupation,
              website: loginResult.website,
              country: loginResult.country,
              city: loginResult.city,
              credits: loginResult.credits,
              image: loginResult.image,
              session: sessionId,
            };

            localStorage.setItem("sessionKey", sessionId);
            dispatch(loginSuccess(loggedInUser));
            dispatch(
              setPreferences({
                preferences: {
                  fileExtension: loginResult.preferred_file_format,
                  length: loginResult.preferred_length_units,
                  meshType: loginResult.preferred_mesh_type,
                  textureResolution: loginResult.preferred_texture_resolution,
                },
              })
            );
            if (hasQueryParams) {
              navigate(`/file-downloader?model=${modelId}`);
            }else {
              navigate("/home");
            }
          }
        } else {
          ShowAlert(3, "Google Error");
        }
      }
      //fill all credentials
      else {
        console.log("Lütfen gösterilen alanları doldurunuz!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  console.log("googleClientId : ", googleClientId);

  return (
    <div>
      <GoogleOAuthProvider clientId={googleClientId}>
        <GoogleLogin
          onSuccess={handleSuccess}
          onError={handleError}
        ></GoogleLogin>
      </GoogleOAuthProvider>
    </div>
  );
}

export default GoogleSign;
