import io from "socket.io-client";

console.log("SOCKET URL : ", process.env.REACT_APP_CHAT);

const socket = io(`${process.env.REACT_APP_CHAT}`, {
  reconnection: false,
  // reconnectionDelay: 3000,
  // reconnectionAttempts: 20,
  forceNew: true,
});

export { socket };