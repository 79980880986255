const sendVerificationMail = async (mail) => {
  const url = `${process.env.REACT_APP_SERVER}/sendVerificationMail`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ mail: mail }),
  });
  return response;
};

export { sendVerificationMail };
