const login = async (user) => {
  const url = `${process.env.REACT_APP_SERVER}/api/users/login`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  });
  return response;
};
export { login };
