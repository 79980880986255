import style from "./ChatButtons.module.css";
const Mini = ({ name, onClick, data }) => {
  const handleClick = () => {
    onClick(data);
  };
  return (
    <button
      onClick={handleClick}
      className={style.Mini}
    >
      {name}
    </button>
  );
};

export default Mini;
