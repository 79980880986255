import { useState } from "react";
import style from "../preferences.module.css";
import { ListInput } from "konsta/react";
import { useDispatch, useSelector } from "react-redux";
import { setPreferences } from "../../../../store/features/userSlice";

const Container = ({ value, text, name, changePreferences }) => {
  const handleChange = (event) => {
    console.log(event.target.value);
    const { value } = event.target;
    changePreferences(name, value);
  };

  const preferences = useSelector((state) => state.user.preferences);

  return (
    <div className={style.nameContainer}>
      <label className={style.name}>{text}</label>
      <ListInput
        type="select"
        dropdown
        defaultValue={preferences[name]}
        // value={preferences[name]}
        placeholder="Please choose..."
        inputStyle={{
          width: "127px",
          backgroundColor: " rgb(223, 221, 221)",
          outline: "none",
          borderRadius: "8px",
          marginBottom: "10px",
          listStyle: "none",
          padding: "0.4rem 1.2rem",
          cursor: "pointer",
          boxShadow: " 0 0 6px 2px rgb(223, 221, 221)",
          border: "none",
        }}
        style={{
          listStyle: "none",
        }}
        onChange={handleChange}
      >
        {value.measure.map((measure, index) => {
          console.log(
            measure,
            preferences[name],
            measure === preferences[name]
          );
          return (
            <option key={index} style={style.optionList} value={measure}>
              {measure}
            </option>
          );
        })}
      </ListInput>
    </div>
  );
};

export default Container;
