import { useState } from "react";
import HeroSection from "../../components/heroSection/HeroSection";
import Button from "../../components/heroSection/button/Button";
import styles from "./VerificartionRequest.module.css";
import ShowAlert from "../../alert/showAlert";
import { sendVerificationMail } from "../../utils/mail/verificationMail";
import { useNavigate } from "react-router";
const VerificationRequestPage = () => {
  const [mail, setMail] = useState("");
  const navigate = useNavigate();

  const checkMailRE = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const checkMail = async () => {
    if (!checkMailRE.test(mail)) {
      ShowAlert(3, "Invalid email address");
    } else {
      const response = await sendVerificationMail(mail);
      const result = await response.json();
      console.log(result);
      if (response.status === 200) {
        if (result.message === "already_verified") {
          ShowAlert(
            3,
            "Your account already verified. You can login using your credentials."
          );
        } else {
          navigate("/verification-sent");
        }
      } else if (response.status === 401) {
        ShowAlert(3, "User not found.");
      } else {
        ShowAlert(3, "Mail could not be sent. Try again.");
      }
    }
  };

  return (
    <HeroSection
      title="Request a new verification link"
      paragraph="Please make sure that this email is correct."
      content={
        <div className={styles.holder}>
          <input
            onChange={(e) => {
              setMail(e.target.value);
            }}
            placeholder="EMAIL ADDRESS"
            className={styles.input}
          />
          <Button
            onClick={() => {
              checkMail();
            }}
            text="Request a New Link"
          />
        </div>
      }
    />
  );
};

export default VerificationRequestPage;
