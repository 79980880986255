import jwt_decode from "jwt-decode";

const sessionLogId = async (user) => {
  const url = `${process.env.REACT_APP_SERVER}/sessionLogId`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user?.session}`,
    },
    body: JSON.stringify(user),
  });
  return response;
};

const getUserSessionProgress = async (sessionID) => {
  const url = `${process.env.REACT_APP_SERVER}/getUserSessionProgress`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionID}`,
    },
    body: JSON.stringify({ sessionID: sessionID }),
  });
  return response;
};

const saveSessionProgress = async (
  sessionID,
  generatedJobs,
  progressStatus
) => {
  const url = `${process.env.REACT_APP_SERVER}/updateUserSessionProgress`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionID}`,
    },
    body: JSON.stringify({
      sessionID: sessionID,
      generatedJobs: generatedJobs,
      progressStatus: progressStatus,
    }),
  });
  return response;
};

const checkJWT = () => {
  try {
    let token = localStorage.getItem("sessionKey");
    let decodedToken = jwt_decode(token);
    console.log("Decoded Token", decodedToken);
    let currentDate = new Date();

    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      return "token_expired";
    } else {
      console.log("Valid token");
      return "valid";
    }
  } catch (e) {
    return "jwt_error";
  }
};
export { checkJWT, sessionLogId, getUserSessionProgress, saveSessionProgress };
