import { useEffect, useRef } from "react";
import style from "./modal.module.css";
const Modal = ({ children, chatBoxSize, changeIsInScreen }) => {
  const ref = useRef(null);

  return (
    <div ref={ref} className={style.modalContainer}>
      {children}
    </div>
  );
};

export default Modal;
