import React, { useEffect, useState } from "react";
import styles from "./VideoGrid.module.css";
import VideoContainer from "../videoContainer/VideoContainer";
import Scene from "../../scene/Scene";
import { useSelector } from "react-redux";
const VideoGrid = ({
  models,
  pageNo,
  holderNo,
  isVariationCompleted,
  variations,
}) => {
  const selectedModel = useSelector((state) => state.model?.selectedModel);

  useEffect(() => {
    console.log(
      "Regenerate ARRAY : ",
      selectedModel,
      models,
      !selectedModel || selectedModel === models.name
    );

    return () => {};
  }, [selectedModel]);

  return (
    <div className={styles.videoGrid}>
      <Scene
        models={models}
        no={1}
        pageNo={pageNo}
        modelNo={0}
        holderNo={holderNo}
        isVariationCompleted={isVariationCompleted}
        variations={variations}
      />

      <Scene
        models={models}
        no={2}
        pageNo={pageNo}
        modelNo={1}
        holderNo={holderNo}
        isVariationCompleted={isVariationCompleted}
        variations={variations}
      />

      <Scene
        models={models}
        no={3}
        pageNo={pageNo}
        modelNo={2}
        holderNo={holderNo}
        isVariationCompleted={isVariationCompleted}
        variations={variations}
      />

      <Scene
        models={models}
        no={4}
        pageNo={pageNo}
        modelNo={3}
        holderNo={holderNo}
        isVariationCompleted={isVariationCompleted}
        variations={variations}
      />
    </div>
  );
};

export default VideoGrid;
