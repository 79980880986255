import styles from "./HeroSection.module.css";
import Navbar from "../navbar/Navbar";
const HeroSection = ({ title, paragraph, content }) => {
  return (
    <section className={styles.bg}>
      <Navbar />
      <div className={styles.container}>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.paragraph}>{paragraph}</p>
        {content}
      </div>

      <img className={styles.leftImg} src="/procedurabg.png" alt="background" />
    </section>
  );
};

export default HeroSection;
