import { Link } from "react-router-dom";
import styles from "./Button.module.css";
const Button = ({ text, to, onClick }) => {
  return (
    <Link onClick={onClick ? onClick : null} to={to} className={styles.button}>
      {text}
    </Link>
  );
};

export default Button;
