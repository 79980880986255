import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import styles from "./ForgotPassword.module.css";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Alert, AlertTitle } from "@mui/material";
import ShowAlert from "../../alert/showAlert";
import Navbar from "../../components/navbar/Navbar";
import HeroSection from "../../components/heroSection/HeroSection";
const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("x");

    const checkMailRE =
      /^(([^<>()\[\]\\.,;:\s@-_!#$%"]+(\.[^<>()\[\]\\.,;:\s+#^&*()¡™£¢∞§¶•ªº@!#$%"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!checkMailRE.test(email)) {
      // invalid email, maybe show an error to the user.

      ShowAlert(3, "Invalid email!");
      return;
    }

    try {
      if (isEmailSent) {
        ShowAlert(2, "Already sent a mail. Please check your inbox.");
        return;
      }

      let result = await axios.post(
        `${process.env.REACT_APP_SERVER}/sendResetPasswordMail`,
        {
          mail: email,
        }
      );

      console.log("RESULT : ", result.data);

      if (result.data.state === "success") {
        // alert();
        setIsEmailSent(true);
        ShowAlert(1, "Mail Sent");
      } else {
        ShowAlert(3, "Can not send mail.");
        // alert("Error");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <HeroSection
      title="Forgot your password?"
      paragraph={[
        "No worries! Enter your email and we will send ",
        <br key="br" />,
        "you a reset link.",
      ]}
      content={
        <form onSubmit={handleSubmit}>
          <div className={styles.holder}>
            <input
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              className={styles.input}
              placeholder="EMAIL ADDRESS"
            />
            <button type="submit" className={styles.button}>
              SEND
            </button>
          </div>
        </form>
      }
    />
  );
};

export default ForgotPassword;
