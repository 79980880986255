import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  botMessage: [],
  userMessage: [],
  allMessage: [],
  isInputBlocked: false,
  isFirstModelSelectedFromChat: false,
  isFinalModelSelectedFromChat: false,
  isHeyMessageSent: false,
  batchId: null,
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    bot: (state, action) => {
      state.botMessage.push(action.payload);
    },
    user(state, action) {
      state.userMessage.push(action.payload);
    },
    allMessage(state, action) {
      state.allMessage = action.payload;
    },
    clearMessages(state, action) {
      state = initialState;
    },
    setIsInputBlocked(state, action) {
      state.isInputBlocked = action.payload.status;
    },
    setIsFirstModelSelectedFromChat(state, action) {
      state.isFirstModelSelectedFromChat = action.payload.status;
    },
    setIsFinalModelSelectedFromChat(state, action) {
      state.isFinalModelSelectedFromChat = action.payload.status;
    },
    setIsHeyMessageSent(state, action) {
      state.isHeyMessageSent = action.payload.status;
    },
    setBatchId(state, action) {
      state.batchId = action.payload.status;
    },
  },
});

export const {
  bot,
  user,
  allMessage,
  clearMessages,
  setIsInputBlocked,
  setIsFirstModelSelectedFromChat,
  setIsFinalModelSelectedFromChat,
  setIsHeyMessageSent,
  setBatchId,
} = messageSlice.actions;

export default messageSlice.reducer;
