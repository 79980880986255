import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  proceedButtonFlag: false,
  newSessionButtonFlag: false,
  isFirstProceedClicked: false,
  isVariationProceedClicked: false,
  isAssetLibraryButtonClicked: false,
  isSettingsButtonClicked: false,
  isCheckBoxesShown: true,
  isProceedButtonShown: false,
  clickedButtons: {},
};

const buttonSlice = createSlice({
  name: "buttons",
  initialState,
  reducers: {
    setProceedButtonFlag: (state, action) => {
      state.proceedButtonFlag = action.payload.status;
    },
    setNewSessionButtonFlag: (state, action) => {
      state.newSessionButtonFlag = action.payload.status;
    },
    setIsFirstProceedClicked: (state, action) => {
      state.isFirstProceedClicked = action.payload.status;
    },
    setIsVariationProceedClicked: (state, action) => {
      state.isVariationProceedClicked = action.payload.status;
    },
    setIsProceedButtonShown: (state, action) => {
      state.isProceedButtonShown = action.payload.status;
    },
    setIsAssetLibraryButtonClicked: (state, action) => {
      state.isAssetLibraryButtonClicked = action.payload.status;
    },
    setIsSettingsButtonClicked: (state, action) => {
      state.isSettingsButtonClicked = action.payload.status;
    },
    setIsCheckBoxesShown: (state, action) => {
      state.isCheckBoxesShown = action.payload.status;
    },
    setClickedButtons: (state, action) => {
      state.clickedButtons = {
        ...state.clickedButtons,
        ...action.payload.state,
      };
    },
  },
});

export const {
  setProceedButtonFlag,
  setNewSessionButtonFlag,
  setIsFirstProceedClicked,
  setIsVariationProceedClicked,
  setIsAssetLibraryButtonClicked,
  setIsSettingsButtonClicked,
  setIsCheckBoxesShown,
  setIsProceedButtonShown,
  setClickedButtons,
} = buttonSlice.actions;

export default buttonSlice.reducer;
