import React from "react";
import styles from "./Session.module.css";
import { useDispatch, useSelector } from "react-redux";

function Session() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const jwtToken = user.session;

  console.log("JWT TOKEN : " ,jwtToken);

  const last10Characters = jwtToken?.slice(-8).toUpperCase();

  return (
    <div className={styles.session}>
      <p className={styles.text}>SESSION ID: {last10Characters}</p>
    </div>
  );
}

export default Session;
