import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  login: false,
  error: null,
  id: null,
  name: "",
  surname: "",
  email: null,
  company: "",
  occupation: "",
  website: "",
  country: "",
  city: "",
  image: "",
  credits: 0,
  session: "",
  spentCreditInCurrentSection: 0,

  preferences: {
    length: "cm",
    fileExtension: "fbx",
    textureResolution: "1K",
    meshType: "Quad",
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.login = true;
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.surname = action.payload.surname;
      state.email = action.payload.email;
      state.image = action.payload.image;
      state.company = action.payload.company;
      state.occupation = action.payload.occupation;
      state.website = action.payload.website;
      state.city = action.payload.city;
      state.country = action.payload.country;
      state.credits = action.payload.credits;
      state.session = action.payload.session;
    },
    edit: (state, action) => {
      state.name = action.payload.name;
      state.surname = action.payload.surname;
      state.email = action.payload.email;
      state.company = action.payload.company;
      state.occupation = action.payload.occupation;
      state.website = action.payload.website;
      state.city = action.payload.city;
      state.country = action.payload.country;
      state.credits = action.payload.credits;
      state.session = action.payload.session;
    },
    editPicture: (state, action) => {
      state.image = action.payload.image;
    },
    setSession: (state, action) => {
      state.session = action.payload.session;
    },
    loginFailure(state, action) {
      state.login = false;
      state.error = action.payload;
    },
    editCredit: (state, action) => {
      state.credits = action.payload.credits;
    },
    increaseSpendedCredits: (state, action) => {
      state.spentCreditInCurrentSection = +1;
    },
    setPreferences: (state, action) => {
      state.preferences = action.payload.preferences;
    },
    logout: () => initialState,
    clearStore: () => initialState,
    clearStoreExceptSession: () => {},
  },
});

export const {
  logout,
  loginSuccess,
  loginFailure,
  edit,
  editPicture,
  editCredit,
  setSession,
  increaseSpendedCredits,
  setPreferences,
  clearStore,
  clearStoreExceptSession,
} = userSlice.actions;

export default userSlice.reducer;
