import HeroSection from "../../components/heroSection/HeroSection";
import Button from "../../components/heroSection/button/Button";
const VerificationSentPage = () => {
  return (
    <HeroSection
      title="A new verification email has been sent."
      paragraph={[
        "Don't forget to check your spam folder.",
        <br />,
        "If you haven't received the verification email, please contact us",
        <br />,
        "at ",
        <u>support@capoom.com</u>,
        " for assistance.",
      ]}
      content={<Button text="Go Back" />}
    />
  );
};

export default VerificationSentPage;
