import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./credits.module.css";
import { useSelector } from "react-redux";
const Credits = ({ onClose }) => {
  const user = useSelector((state) => state.user);

  return (
    <div>
      <div className={styles.title}>
        <h2>Procedura Credits</h2>
        <div onClick={onClose} className={styles.pointer}>
          <CloseIcon />
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.row}>
          <span className={`${styles.label} ${styles.right}`}>
            Credit Spend in Session:
          </span>
          <span className={styles.value}>
            You've spent [{user.spentCreditInCurrentSection}] in this session.
          </span>
        </div>
        <div className={styles.row}>
          <span className={`${styles.label} ${styles.right}`}>
            Credit Balance:
          </span>
          <span className={styles.value}>
            You currently possess [{user.credits}] credits
          </span>
        </div>
      </div>

      <div className={styles.infoContainer}>
        <div>
          <h3>Understanding a Batch</h3>
          <p>
            A 'batch' refers to a collection of assets that Procedura generates
            based on your specific input
          </p>
        </div>
        <div>
          <h3>Credit Cost per Batch</h3>
          <p>Generating each batch will deduct 1 credit from your account</p>
        </div>
        <div>
          <h3>How Batches Are Generated</h3>
          <p>
            Procedura can produce up to 3 batches based on your initial input.
            Additionally, you can generate one more batch that resembles an
            asset you've selected from the earlier batches.
          </p>
        </div>
        <div>
          <h3>Daily Credit Allocation</h3>
          <p>
            Each closed beta tester receives a daily allotment of 60 credits.
          </p>
        </div>
        <div>
          <h3>Earning Extra Credits</h3>
          <p>
            You can earn an additional 20 credits by providing written feedback
            for 15 sessions each day.
          </p>
        </div>
        <div>
          <h3>Credit Expiry Policy</h3>
          <p>
            While daily credits do not roll over to the next day, any extra
            credits you earn from providing feedback will remain in your account
            until you use them.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Credits;
