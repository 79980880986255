import { createSlice } from "@reduxjs/toolkit";

// POSSIBLE STATES
// not_started
// generating_first_model
// downloading_first_model
// completed_first_model
// regenerating_second_model
// downloading_second_model
// completed_second_model
// regenerating_model
// downloading_regenerating_model
// variating_model
// downloading_variating_model
// idle

const initialState = {
  generalStatus: "not_started",
  modelStatus: "not_started",
  regenerateStatus: "not_started",
  isFirstModelReady: false,
  isSecondModelReady: false,
  isThirdModelReady: false,
  isVariationsReady: false,
  selectedPage: 1,
  isOrderSummaryOpen: false,
  isModelDownloaded: false,
  isStartedOver: false,
};

const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    setGeneralStatus: (state, action) => {
      state.generalStatus = action.payload.status;
    },
    setModelStatus: (state, action) => {
      state.modelStatus = action.payload.status;
    },
    setRegenerateStatus: (state, action) => {
      state.regenerateStatus = action.payload.status;
    },
    clearStatus: (state, action) => {
      state = initialState;
    },
    setIsFirstModelReady: (state, action) => {
      state.isFirstModelReady = action.payload.status;
    },
    setIsSecondModelReady: (state, action) => {
      state.isSecondModelReady = action.payload.status;
    },
    setIsThirdModelReady: (state, action) => {
      state.isThirdModelReady = action.payload.status;
    },
    setIsVariationsReady: (state, action) => {
      state.isVariationsReady = action.payload.status;
    },
    setSelectedPage: (state, action) => {
      state.selectedPage = action.payload.selectedPage;
    },
    setIsOrderSummaryOpen: (state, action) => {
      state.isOrderSummaryOpen = action.payload.status;
    },
    setIsModelDownloaded: (state, action) => {
      state.isModelDownloaded = action.payload.status;
    },
    setIsStartedOver: (state, action) => {
      state.isStartedOver = action.payload.status;
    },
  },
});

export const {
  setGeneralStatus,
  setModelStatus,
  setRegenerateStatus,
  clearStatus,
  setIsFirstModelReady,
  setIsSecondModelReady,
  setIsThirdModelReady,
  setIsVariationsReady,
  setSelectedPage,
  setIsOrderSummaryOpen,
  setIsModelDownloaded,
  setIsStartedOver,
} = statusSlice.actions;

export default statusSlice.reducer;
