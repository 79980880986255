import React, { useEffect, useState } from "react";
import style from "./SendMessage.module.css";
import { RiSendPlaneLine as Send } from "react-icons/ri";
import { useSelector } from "react-redux";
import {
  FIRST_DOWNLOAD,
  FIRST_GENERATION,
  MODELS_DOWNLOADED,
  PREPARING_DOWNLOAD,
  SECOND_DOWNLOAD,
  SECOND_GENERATION,
  THIRD_DOWNLOAD,
  THIRD_GENERATION,
  VARIATION_DOWNLOAD,
  VARIATION_GENERATION,
} from "../../../../utils/statusList/statusList";

const SendMessage = ({ onSendMessage }) => {
  const generalStatus = useSelector((state) => state.status.generalStatus);
  const isInputBlocked = useSelector((state) => state.message.isInputBlocked);
  const [inputDisabled, setInputDisabled] = useState(false);
  useEffect(() => {
    if (
      generalStatus === FIRST_DOWNLOAD ||
      generalStatus === FIRST_GENERATION ||
      generalStatus === SECOND_DOWNLOAD ||
      generalStatus === SECOND_GENERATION ||
      generalStatus === THIRD_DOWNLOAD ||
      generalStatus === THIRD_GENERATION ||
      generalStatus === VARIATION_DOWNLOAD ||
      generalStatus === VARIATION_GENERATION ||
      generalStatus === PREPARING_DOWNLOAD ||
      generalStatus === MODELS_DOWNLOADED ||
      isInputBlocked
    ) {
      setInputDisabled(true);
    } else {
      setInputDisabled(false);
    }
    return () => {};
  }, [generalStatus, isInputBlocked]);

  const [message, setMessage] = useState("");
  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendClick = (e) => {
    e.preventDefault();
    if (message.trim() !== "") {
      onSendMessage(message);
      setMessage("");
    } else {
      setMessage("");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendClick(event);
    }
  };

  return (
    <div className={style.inputCont}>
      <input
        type="text"
        autoComplete="off"
        autoCorrect="off"
        style={{ width: "100%" }}
        placeholder="Send a message"
        className={style.miuv}
        value={message}
        disabled={inputDisabled}
        onChange={handleInputChange}
        onKeyPress={!inputDisabled ? handleKeyPress : () => {}}
      />
      <svg
        onClick={!inputDisabled ? handleSendClick : () => {}}
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.7113 9.22708L0.749811 16.7642C0.404397 16.9425 0 16.685 0 16.2987V10.2175C0 9.94018 0.217117 9.71236 0.493449 9.69255L6.36568 9.35584C6.7407 9.33603 7.03646 9.02898 7.03646 8.65262C7.03646 8.27626 6.75057 7.96927 6.36568 7.94946L0.493449 7.61269C0.217117 7.59288 0 7.36511 0 7.08779V1.21454C0 0.828274 0.404397 0.570793 0.749811 0.749069L15.7113 8.28617C16.0962 8.47435 16.0962 9.02899 15.7113 9.21717V9.22708Z"
          fill={!inputDisabled ? "white" : "#5F5F5F"}
        />
      </svg>
    </div>
  );
};

export default SendMessage;
