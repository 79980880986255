const register = async (user) => {
  const url = `${process.env.REACT_APP_SERVER}/api/users/`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  });
  return response;
};

const registers = async (user) => {
  const url = `${process.env.REACT_APP_SERVER}/`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  });
  return response;
};
export { register, registers };
